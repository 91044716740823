import * as React from 'react';

import InputField from '@sympli/ui-framework/components/formik/input-field';
import { Field, FormikProps, getIn, setIn } from 'formik';

import Button from '@mui/material/Button';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { AbnLookupResultModel, DEFAULT_COMPANY_VALUE } from 'src/models';

import { OrganisationDetailsModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface AbnLookupProps {
  formikProps: FormikProps<OrganisationDetailsModel>;
  onAbnLookUp: (abn: string) => Promise<AbnLookupResultModel>;
}

interface State {
  showLoader: boolean;
}

type Props = AbnLookupProps & WithStyles<ClassKeys>;

class AbnLookup extends React.PureComponent<Props, State> {
  private verifyButton: React.ReactNode;

  readonly state: State = {
    showLoader: false
  };

  constructor(props: Props) {
    super(props);

    this.verifyButton = (
      <Button className={props.classes.endButton} color="primary" variant="contained" onClick={this.onClickVerify}>
        Verify
      </Button>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.line}>
          <Field
            label="Enter your ABN"
            name="company.abn"
            component={InputField}
            showLoader={this.state.showLoader}
            onChange={this.handleOnAbnChange}
            endAdornment={this.verifyButton}
            className={classes.abn}
            classes={{ inputAdornmentEnd: classes.verifyInputAdornmentEnd }}
          />
          <Field component={InputField} className={classes.companyName} label="Organisation name" readOnly={true} name="company.organisationName" />
        </div>
        <div className={classes.line}>
          <Field className={classes.abn} label="ACN" name="company.acn" component={InputField} />
          <Field className={classes.companyName} label="Entity type" name="company.entityType" component={InputField} readOnly={true} />
        </div>
      </div>
    );
  }

  private handleOnAbnChange = () => {
    // TODO set a flag in formik (value/error) and block user from submitting form before verify
  };

  private onClickVerify = (e: any) => {
    const {
      onAbnLookUp,
      formikProps: { values, setFieldError, setFieldValue, setValues, setTouched, touched }
    } = this.props;
    const { company } = values;
    const submitAbn = company.abn.replace(/\s+/g, '');
    this.setState({ showLoader: true }, () => {
      onAbnLookUp(submitAbn)
        .then(result => {
          const { status, ...companyInfoResponse } = result;
          let newValues = values;
          newValues = setIn(newValues, 'company', { ...company, ...companyInfoResponse });
          setValues(newValues);
          // TODO set a flag in formik (value/error) and block user from submitting form before verify
        })
        .catch(err => {
          setFieldValue('company', {
            abn: company.abn,
            ...DEFAULT_COMPANY_VALUE
          });
          setFieldError('company.abn' as any, 'Abn does not exist');
        })
        .finally(() => {
          this.setState({ showLoader: false });
          // * deal with touched field
          if (!getIn(touched, 'company.organisationName') || !getIn(touched, 'company.entityType') || !getIn(touched, 'company.acn')) {
            let newTouched = setIn(touched, 'company.organisationName', true);
            newTouched = setIn(touched, 'company.entityType', true);
            newTouched = setIn(touched, 'company.acn', true);
            setTouched(newTouched);
          }
        });
    });
  };
}
export default withStyles(styles)(AbnLookup);
