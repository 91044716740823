import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#383838',
      height: 80,
      zIndex: 10,
      justifyContent: 'center',
      // * refer to ./src/index.css: line: 9
      minWidth: 1280,
      position: 'absolute',
      left: 0,
      top: 0
    },
    logo: {
      width: 186,
      height: 46,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      margin: `${theme.spacing()} 0`
    },
    helpLink: {
      width: 300,
      paddingRight: 40,
      color: 'white',
      flexGrow: 1
    },
    helpIcon: {
      fontSize: 30,
      padding: 9,
      color: theme.palette.primary.main,
      fontWeight: 100
    },
    underline: {
      textDecoration: 'underline'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
