import * as React from 'react';

import ModalDialog from '@sympli/ui-framework/components/dialogs/modal-dialog';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import Divider from '@mui/material/Divider';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { yupPerson } from 'src/containers/onboarding/views/onboarding-detail/shared/validationSchema';
import PersonEditor from 'src/containers/onboarding/views/onboarding-detail/steps/signers/components/person-editor';
import PersonPicker from 'src/containers/onboarding/views/onboarding-detail/steps/signers/components/person-picker';
import { SignerEntityModel } from 'src/containers/onboarding/views/onboarding-detail/steps/signers/models';
import { PersonModel } from 'src/models';

import styles, { ClassKeys } from './styles';

// Coupled with person model / signer model

interface PersonModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: PersonModel, formikActions: FormikHelpers<PersonModel>) => void;

  docSigningOptions: Array<LookupEnumModel | LookupItemModel>;
  trustAccountSignersOptions: Array<LookupEnumModel | LookupItemModel>;
  options: Array<LookupEnumModel | LookupItemModel>;

  initialValue: PersonModel;
  hasPicker?: boolean;
}

type Props = PersonModalProps & WithStyles<ClassKeys>;

class PersonDialog extends React.PureComponent<Props> {
  private personOptions: Array<LookupEnumModel | LookupItemModel>;
  static defaultProps: Partial<Props> = {
    hasPicker: true
  };

  constructor(props: Props) {
    super(props);
    this.cachePersonOptions();
  }

  componentDidUpdate(prevProps: Props) {
    // just open the dialog
    if (!prevProps.open && this.props.open) {
      // update the person options
      this.cachePersonOptions();
    }
  }

  private cachePersonOptions() {
    // cache the options to prevent the case that:
    // - when submitting the form
    // - this.props.options changed by wrapper component first
    // - options(this.props.options) in personpicker changed
    // - this.checkReference check the wrong options
    this.personOptions = this.props.options;
  }

  render() {
    const { classes, onClose, onSubmit, open, title } = this.props;
    return (
      <ModalDialog classes={{ paper: classes.paper }} open={open} title={title} onClose={onClose}>
        <Divider style={{ marginBottom: 20 }} />
        <Formik //
          validationSchema={yupPerson}
          initialValues={this.props.initialValue}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<SignerEntityModel>) => this.renderForm(formikProps)}
        </Formik>
      </ModalDialog>
    );
  }

  private renderForm(formikProps: FormikProps<SignerEntityModel>) {
    const { onClose, classes, hasPicker, docSigningOptions } = this.props;
    const openDetail = formikProps.values.existingOrNew === ADD_NEW;
    return (
      <Form>
        <FormGroup title="Signer details">
          {hasPicker ? (
            <PersonPicker mode="plain" options={this.personOptions} openDetail={openDetail} allowSelection={true} formikProps={formikProps} />
          ) : (
            <PersonEditor />
          )}
        </FormGroup>
        <FormGroup title="Signing permissions">
          {/* TODO // ! hide trust Account for V1 WEB-2984*/}
          {/* <Field
            className={classes.fullWidth}
            name={'canSignTrustAccounts'}
            component={CheckboxGroupField}
            options={trustAccountSignersOptions}
            format="string"
            vertical
          /> */}
          <Field
            className={classes.fullWidth}
            name={'allowedJurisdictionsForDocumentSigning'}
            component={CheckboxGroupField}
            options={docSigningOptions}
            format="string"
            vertical
          />
        </FormGroup>
        <WizardStepper onBack={onClose} nextLabel="Save and continue" disabled={formikProps.isSubmitting} />
      </Form>
    );
  }
}
export default withStyles(styles)(PersonDialog);
