import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    paragraph: {
      margin: 0,
      padding: 0,
      fontSize: 16
    },
    certificateDetails: {
      paddingTop: 8,
      paddingBottom: 16,
      paddingLeft: 16
    },
    certificateBox: {
      display: 'flex',
      alignItems: 'center'
    },
    // Radio overide
    verticalLabelRadioGroup: {
      width: '100%',
      borderBottom: '1px solid #D0D0D0',
      marginRight: 0,
      marginLeft: 0,
      '& + $verticalLabelRadioGroup': {
        marginTop: 0
      }
    },
    radio: {
      marginTop: 2
    },
    formControlOverride: {
      width: '100%'
    },
    formGroupOverride: {
      paddingLeft: 0
    },
    formControlLabelRoot: {
      '& .MuiFormControlLabel-label': {
        lineHeight: 1.5
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
