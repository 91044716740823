import * as React from 'react';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { Form, FormikProps } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Formik from 'src/components/formik';
import Header from 'src/components/layout/header';
import ScrollToFormikError from 'src/components/scroll-to-formik-error';
import UploaderFormik from 'src/components/uploader-field';
import { OrganisationTypeEnum } from 'src/containers/onboarding/views/onboarding-detail/steps/organisation-details/models';
import { DEFAULT_UPLOAD_DOCUMENTS_VALUES, UploadDocumentsModel } from 'src/containers/onboarding/views/onboarding-detail/steps/upload-documents/models';
import { DocumentApiItemModel, DocumentGroupEnum, DocumentGroupModel } from 'src/models';
import Api from 'src/utils/http';

import { OnboardingDetailsModel } from '../../models';
import styles, { ClassKeys } from './styles';
import { validationSchema } from './validationSchema';

interface UploadDocumentsProps {
  step: number;

  onStepChange: (shift: number, values?: object) => void;
  getInitialValues: (step?: number) => any;

  // For documents
  getDocuments: () => Promise<Array<DocumentApiItemModel>>;
  onUploadFile: (data: FormData, onUploadProgress: () => void, callback?: (err?: Error) => void) => Promise<any>;
  onDeleteFile: (fileName: string, fileGroup: DocumentGroupModel) => Promise<any>;
}
interface State {
  isLoadingDocuments: boolean;
  documents: Array<DocumentApiItemModel>;
}

type Props = UploadDocumentsProps & WithStyles<ClassKeys>;

class UploadDocuments extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isLoadingDocuments: true, // do not show anything before documents loads
    documents: []
  };

  private allValues: OnboardingDetailsModel;
  private certificateName: string;

  constructor(props: Props) {
    super(props);

    this.allValues = this.props.getInitialValues() as OnboardingDetailsModel;
    this.certificateName = this.resolveCertificateName();
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  private fetchDocuments() {
    this.props.getDocuments().then(documents => {
      this.setState({ isLoadingDocuments: false, documents });
    });
  }

  // * Initialise documents
  private initialiseDocuments = (): UploadDocumentsModel => {
    const organisationDocuments = _cloneDeep(DEFAULT_UPLOAD_DOCUMENTS_VALUES);
    const { jurisdictions } = this.allValues.organisationDetails;
    const documents = this.state.documents;
    // Load (onboarding) documents to UploadDocumentsModel
    documents.forEach(document => {
      const groupName: string = document.groupName;
      if (groupName === DocumentGroupEnum.PrincipalPracticingCertificate) {
        const subGroupName: string = document.subGroupName || '';
        // Only add certificates for selected jurisdiction
        if (jurisdictions.some(state => state === subGroupName.toUpperCase())) {
          organisationDocuments[groupName].push({
            groupName,
            subGroupName,
            category: document.category,
            fileNames: document.fileName ? [document.fileName] : []
          });
        }
      } else {
        organisationDocuments[groupName] = {
          groupName,
          category: document.category,
          fileNames: document.fileName ? [document.fileName] : []
        };
      }
    });
    return organisationDocuments;
  };

  private getInitialValues = (): UploadDocumentsModel => {
    const initialValues = this.initialiseDocuments();
    return initialValues;
  };

  render() {
    const { classes } = this.props;

    // * Because we need documents from backend to initialise the formik
    if (this.state.isLoadingDocuments) {
      return <BlockLoader />;
    }

    return (
      <React.Fragment>
        <Header>Upload documents</Header>
        <Typography variant="h2" className={classes.subTitle}>
          Please upload the following documents
        </Typography>
        <Formik
          getInitialValues={this.getInitialValues} //
          validationSchema={validationSchema}
          onSubmit={this.handleOnSubmit}
          onPreSubmit={this.handleOnPreSubmit}
        >
          {(formikProps: FormikProps<UploadDocumentsModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<UploadDocumentsModel>) {
    return (
      <Form>
        <ScrollToFormikError formikProps={formikProps} />
        {this.renderUploadFiles(formikProps)}
        <WizardStepper nextLabel="Save and continue" disabled={formikProps.isSubmitting} onBack={this.handleOnBackClick} />
      </Form>
    );
  }

  private renderUploadFiles(formikProps: FormikProps<UploadDocumentsModel>) {
    return (
      <React.Fragment>
        {this.renderPracticingCertificateUploader(formikProps)}
        {this.renderEvidenceOfIdentityUploader(formikProps)}
      </React.Fragment>
    );
  }

  private renderPracticingCertificateUploader(formikProps: FormikProps<UploadDocumentsModel>) {
    const { onUploadFile, onDeleteFile } = this.props;
    const { [DocumentGroupEnum.PrincipalPracticingCertificate]: certificates } = formikProps.values;
    return (
      <React.Fragment>
        {certificates.map((fileGroup, idx) => {
          const fieldName = `${DocumentGroupEnum.PrincipalPracticingCertificate}[${idx}]`;
          const jurisdiction = fileGroup.subGroupName ? fileGroup.subGroupName.toUpperCase() : '';
          const title = `${jurisdiction} ${this.certificateName}`;
          return (
            <React.Fragment key={fieldName}>
              <UploaderFormik
                title={title}
                description={`Your organisation's ${this.certificateName} for ${jurisdiction} is required.`}
                fieldName={fieldName}
                formikProps={formikProps}
                onUploadFile={onUploadFile}
                onDeleteFile={onDeleteFile}
                onFileNameClick={this.handleClickFileName}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  private renderEvidenceOfIdentityUploader(formikProps: FormikProps<UploadDocumentsModel>) {
    const { onUploadFile, onDeleteFile } = this.props;

    const description = (
      <React.Fragment>
        <span>Please provide a certified copy of one of the following documents:</span>
        <br />
        <br />
        <span>1. Certificate of Registration from ASIC; OR </span>
        <br />
        <span>2. Certificate of Registration from your state's Business Registration authority; OR </span>
        <br />
        <span>3. A notice issued to your organisation by the ABR; OR</span>
        <br />
        <span>4. A notice issued to your organisation by the ATO</span>
      </React.Fragment>
    );
    return (
      <UploaderFormik
        title="Evidence of Identity:"
        description={description}
        fieldName={DocumentGroupEnum.EvidenceOfIdentity}
        formikProps={formikProps}
        onUploadFile={onUploadFile}
        onDeleteFile={onDeleteFile}
        onFileNameClick={this.handleClickFileName}
      />
    );
  }

  private handleOnPreSubmit = (values: UploadDocumentsModel, formikProps: FormikProps<UploadDocumentsModel>) => {
    return values;
  };

  private handleOnSubmit = (values: UploadDocumentsModel) => {
    this.props.onStepChange(+1);
  };

  private handleOnBackClick = () => {
    this.props.onStepChange(-1);
  };

  private handleClickFileName = (fileName: string, fileGroup: string) => {
    return Api.downloadFile(fileName, fileGroup);
  };

  // TODO move to back end, ref: WEB-2351
  private resolveCertificateName() {
    const orgType = this.allValues.organisationDetails.organisationType;
    const entityType = this.allValues.organisationDetails.company.entityType;
    if (orgType === OrganisationTypeEnum.LawPractice) {
      return 'Principal practicing certificate';
    } else if (orgType === OrganisationTypeEnum.ConveyancingPractice) {
      if (entityType === 'Australian private company' || entityType === 'Australian public company') {
        return 'Company conveyancing licence';
      } else {
        return 'Individual conveyancing licence';
      }
    } else {
      return 'Proof of right to practice conveyancing transactions';
    }
  }
}
export default withStyles(styles)(UploadDocuments);
