import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  errors?: JSX.Element;
  warnings?: JSX.Element;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class ApplicationNotificationStack extends React.PureComponent<Props> {
  render() {
    const { errors, warnings, classes } = this.props;
    return (
      <FlexLayout flexDirection="column" className={classes.root}>
        <FlexLayout flexDirection="column" className={classes.errorsContainer}>
          {errors}
        </FlexLayout>
        <FlexLayout flexDirection="column" className={classes.warningsContainer}>
          {warnings}
        </FlexLayout>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(ApplicationNotificationStack);
