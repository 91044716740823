import * as React from 'react';

export default (props: React.SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 150 150" width="130" height="130" {...props}>
    <g id="02-//-EXTERNAL-ONBOARDING-v6" stroke="none" fill="none">
      <g id="Onboarding-//-Approved" transform="translate(-415.000000, -209.000000)">
        <g id="illo-congrats" transform="translate(415.000000, 209.000000)">
          <g id="Group" fill="#F2F2F2">
            <g id="SVGID_1_-link">
              <circle id="SVGID_1_" cx="65" cy="65" r="65" />
            </g>
            <circle id="Oval" cx="65" cy="65" r="65" />
            <g id="SVGID_3_-link">
              <circle id="SVGID_3_" cx="65" cy="65" r="65" />
            </g>
          </g>
          <g id="Group" transform="translate(28.000000, 24.000000)">
            <g transform="translate(0.000000, 35.219512)" fill="#1DDDBD" id="Path">
              <polygon points="0.837209302 40.6878049 12 36.795122 3.53488372 32.8097561" />
              <polygon points="14.7906977 0.185365854 12.0930233 8.52682927 47.4418605 24.1902439 49.0232558 23.6341463 33.3953488 8.52682927" />
              <polygon points="6.41860465 24.6536585 23.7209302 32.7170732 35.5348837 28.6390244 9.30232558 16.404878" />
            </g>
            <g transform="translate(2.790698, 27.804878)" fill="#2A917F" id="Path">
              <polygon points="30.6976744 15.9414634 14.7906977 0 12.1860465 7.41463415" />
              <polygon points="6.51162791 23.8195122 32.744186 36.0536585 44.4651163 31.8829268 9.30232558 15.5707317" />
              <polygon points="3.62790698 32.0682927 0.744186047 40.2243902 9.20930233 44.2097561 20.9302326 40.1317073" />
            </g>
            <path
              d="M40,36.9804878 C48.8372093,45.6 53.3953488,55.2390244 50.1395349,58.3902439 C46.9767442,61.5414634 37.2093023,57.0926829 28.372093,48.3804878 C19.5348837,39.7609756 14.9767442,30.1219512 18.2325581,26.9707317 C21.3953488,23.8195122 31.1627907,28.3609756 40,36.9804878 Z"
              id="Path"
              fill="#247767"
            />
            <path
              d="M28.9302326,15.2 C26.7906977,12.2341463 29.1162791,10.9365854 32,11.4926829 C29.3023256,8.15609756 30.9767442,5.83902439 35.255814,6.76585366 C36.5581395,7.04390244 34.6976744,9.26829268 33.5813953,9.17560976 C37.2093023,11.8634146 35.1627907,14.6439024 31.4418605,13.995122 C34.7906977,18.6292683 29.0232558,17.4243902 26.5116279,17.6097561 C25.8604651,21.0390244 29.7674419,24.9317073 28.4651163,24.9317073 C25.3953488,25.0243902 20.744186,14.1804878 28.9302326,15.2 Z"
              id="Path"
              fill="#2A917F"
            />
            <path
              d="M56.5581395,21.502439 C54.6046512,22.3365854 49.1162791,13.7170732 55.9069767,13.6243902 C52,10.102439 52.4651163,8.43414634 57.7674419,8.24878049 C51.8139535,2.22439024 61.2093023,0.0926829268 62.2325581,3.33658537 C62.5116279,4.26341463 59.3488372,2.50243902 58.3255814,4.17073171 C57.1162791,6.11707317 65.6744186,11.2146341 56.8372093,10.8439024 C60.0930233,14.1804878 60.2790698,15.6634146 55.0697674,16.2195122 C55.7209302,17.3317073 57.7674419,20.9463415 56.5581395,21.502439 Z"
              id="Path"
              fill="#1DDDBD"
            />
            <path
              d="M58.7906977,41.8926829 L60.744186,40.2243902 C60.744186,40.2243902 62.6046512,42.9121951 63.9069767,43.9317073 C64.9302326,39.297561 64.6511628,36.4243902 70.0465116,39.6682927 C66.9767442,31.604878 72,34.6634146 76.8372093,36.795122 C76.5581395,34.7560976 76.8372093,34.9414634 78.9767442,34.3853659 C80.8372093,41.2439024 75.9069767,39.297561 71.8139535,36.9804878 C74.1395349,43.1902439 71.7209302,42.9121951 66.6976744,40.7804878 C66.6046512,43.3756098 65.7674419,46.4341463 64.1860465,46.6195122 C62.4186047,46.9902439 58.7906977,41.8926829 58.7906977,41.8926829 Z"
              id="Path"
              fill="#FF811A"
            />
            <path
              d="M44.0930233,22.4292683 C41.6744186,25.5804878 37.9534884,27.3414634 35.1627907,30.0292683 C32.2790698,32.902439 30.5116279,40.7804878 30.5116279,40.7804878 C30.5116279,40.7804878 31.5348837,32.6243902 34.3255814,29.4731707 C36.8372093,26.6 40.4651163,24.5609756 42.4186047,21.2243902 C45.8604651,15.2926829 42.7906977,7.32195122 38.3255814,2.7804878 C39.255814,1.94634146 40.4651163,0.926829268 41.2093023,0.185365854 C45.3953488,5.56097561 49.1162791,15.8487805 44.0930233,22.4292683 Z"
              id="Path"
              fill="#FF811A"
            />
            <path
              d="M48.0930233,29.102439 C44.6511628,31.604878 42.2325581,35.2195122 39.8139535,38.6487805 C37.6744186,41.7073171 31.0697674,45.4146341 31.0697674,45.4146341 C31.0697674,45.4146341 37.3953488,41.1512195 39.255814,38 C41.5813953,34.1073171 43.9069767,30.0292683 47.627907,27.1560976 C54.9767442,21.502439 65.5813953,22.1512195 73.8604651,25.1170732 C73.3953488,26.3219512 72.372093,28.8243902 72.372093,28.8243902 C72.372093,28.8243902 55.0697674,24.097561 48.0930233,29.102439 Z"
              id="Path"
              fill="#2A917F"
            />
            <path
              d="M62.6976744,28.5463415 C60.4651163,31.4195122 59.3488372,34.9414634 57.6744186,38.1853659 C56.0930233,41.1512195 54.0465116,44.0243902 50.9767442,45.6 C47.627907,47.3609756 40.0930233,46.7121951 40.0930233,46.7121951 C40.0930233,46.7121951 47.627907,46.6195122 50.6976744,44.5804878 C53.8604651,42.4487805 55.5348837,38.8341463 56.744186,35.404878 C59.0697674,28.9170732 61.9534884,21.8731707 68.744186,19 C69.2093023,20.204878 70.1395349,22.7073171 70.1395349,22.7073171 C70.1395349,22.7073171 66.4186047,23.7268293 62.6976744,28.5463415 Z"
              id="Path"
              fill="#1DDDBD"
            />
            <polygon
              id="Rectangle"
              fill="#18CDB6"
              transform="translate(6.087256, 14.828694) rotate(-45.008103) translate(-6.087256, -14.828694) "
              points="3.48263095 12.2240661 8.68235156 12.2335952 8.69188068 17.4333212 3.49216007 17.4237921"
            />
            <polygon
              id="Rectangle"
              fill="#EFCA9B"
              transform="translate(11.233181, 26.540637) rotate(-45.000000) translate(-11.233181, -26.540637) "
              points="8.62855521 23.9360104 13.8282785 23.9455395 13.8378076 29.1452628 8.63808433 29.1357336"
            />
            <polygon
              id="Rectangle"
              fill="#FF811A"
              transform="translate(20.734847, 9.090100) rotate(-45.008103) translate(-20.734847, -9.090100) "
              points="18.1302216 6.48547293 23.3299423 6.49500205 23.3394714 11.694728 18.1397508 11.6851989"
            />
            <polygon
              id="Rectangle"
              fill="#1DDDBD"
              transform="translate(61.065498, 53.201640) rotate(-45.000000) translate(-61.065498, -53.201640) "
              points="58.4608715 50.5970143 63.6605947 50.6065434 63.6701239 55.8062667 58.4704006 55.7967376"
            />
            <polygon
              id="Rectangle"
              fill="#1DDDBD"
              transform="translate(52.815805, 65.900462) rotate(-45.000000) translate(-52.815805, -65.900462) "
              points="50.2111785 63.2958358 55.4109017 63.3053649 55.4204308 68.5050881 50.2207076 68.495559"
            />
            <polygon
              id="Rectangle"
              fill="#FF811A"
              transform="translate(69.120242, 68.838205) rotate(-45.000000) translate(-69.120242, -68.838205) "
              points="66.5156157 66.2335787 71.7153389 66.2431078 71.724868 71.4428311 66.5251448 71.4333019"
            />
            <polygon
              id="Rectangle"
              fill="#EFCA9B"
              transform="translate(72.739284, 52.038303) rotate(-45.000000) translate(-72.739284, -52.038303) "
              points="70.1346575 49.4336767 75.3343808 49.4432059 75.3439099 54.6429291 70.1441867 54.6334"
            />
            <polygon
              id="Rectangle"
              fill="#FF811A"
              transform="translate(66.575963, 12.989827) rotate(-45.000000) translate(-66.575963, -12.989827) "
              points="63.9713366 10.3852011 69.1710599 10.3947303 69.180589 15.5944535 63.9808657 15.5849244"
            />
            <polygon
              id="Rectangle"
              fill="#1DDDBD"
              transform="translate(26.816074, 35.258643) rotate(-45.000000) translate(-26.816074, -35.258643) "
              points="24.2114482 32.6540167 29.4111715 32.6635459 29.4207006 37.8632691 24.2209774 37.85374"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
