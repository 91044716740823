import * as yup from 'yup';

import { StateEnum } from '@sympli/ui-framework/components/formik/address-field/values';

import { yupPerson } from '../../shared/validationSchema';

export function sroCustomerIdCheck(this: yup.TestContext, value: string = '') {
  const jurisdiction: StateEnum = this.parent.id;
  const alphanumPattern = /^[a-zA-Z0-9]*$/;
  const numPattern = /^[0-9]*$/;
  switch (jurisdiction) {
    // Alphanumeric with a max length of 9
    case StateEnum.NSW: {
      return alphanumPattern.test(value) && value.length < 10;
    }
    // Numeric with a max length of 9
    case StateEnum.WA: {
      return numPattern.test(value) && value.length < 10;
    }
    // Alphanumeric with a max length of 10
    case StateEnum.QLD:
    case StateEnum.SA: {
      return alphanumPattern.test(value) && value.length < 11;
    }
    // Alphanumeric with a max length of 30
    case StateEnum.VIC: {
      return alphanumPattern.test(value) && value.length < 31;
    }
    default: {
      return alphanumPattern.test(value);
    }
  }
}

const yupCorrespondence = yup.object({
  addressId: yup.string().required('Please add an address'),
  contactPerson: yupPerson
});

export const validationSchema = yup.object({
  entityName: yup
    .string()
    .required('LRS entity name is required')
    .max(255, 'Must be 255 characters or less')
    .matches(/^[a-zA-Z0-9!"#$%&'()*,-./:;=?@ ]+$/, 'Only letters, numbers and these special characters are allowed !"#$%&\'()*,-./:;=?@'),
  businessName: yup
    .string()
    .required('LRS business name is required')
    .max(255, 'Must be 255 characters or less')
    .matches(/^[a-zA-Z0-9!"#$%&'()*,-./:;=?@ ]+$/, 'Only letters, numbers and these special characters are allowed !"#$%&\'()*,-./:;=?@'),
  lrsJurisdictions: yup.array().min(1, 'Please choose at least one jurisdiction'),
  allStates: yup.array().of(
    yup.object({
      lodgementCorrespondence: yupCorrespondence,
      financialCorrespondence: yupCorrespondence,
      stateRevenueCustomerId: yup.string().test('sro-customer-id', `Please enter a valid customer id`, sroCustomerIdCheck)
    })
  )
});
