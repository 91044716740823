import * as React from 'react';

import { IconExclamationTriangle, IconTimes } from '@sympli/ui-framework/icons';
import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';

import { DialogType } from './models';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  open?: boolean;

  type: DialogType;
  title: string;
  message: string;

  onIgnore?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  onReport?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  onClose?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class MessageDialog extends React.PureComponent<Props> {
  private labelledBy: string = _uniqueId('message-dialog-title');
  private describedby: string = _uniqueId('message-dialog-description');

  static defaultProps: Partial<Props> = {
    open: true
  };

  render() {
    const { open, classes, type, title, message } = this.props;
    const { labelledBy, describedby } = this;
    return (
      <Dialog
        open={open!}
        onClose={this.handleOnClose}
        aria-labelledby={labelledBy}
        aria-describedby={describedby}
        maxWidth="xs"
        classes={{ paper: classNames(classes.root, type) }}
      >
        <DialogTitle id={labelledBy} className={classNames(classes.title, type)}>
          <IconExclamationTriangle className={classNames(classes.titleIcon, type)} />
          {title}
        </DialogTitle>
        <DialogContent className={classes.description} id={describedby}>
          {this.resolveInstruction()}
          {message}
        </DialogContent>
        {this.renderDialogActions()}
        <IconButton className={classes.closeButton} onClick={this.handleOnClose} aria-label="Close dialog" size="large">
          <IconTimes />
        </IconButton>
      </Dialog>
    );
  }

  private renderDialogActions() {
    const { classes, type } = this.props;
    if (type === DialogType.Information) {
      return (
        <DialogActions className={classes.actions}>
          <Button onClick={this.handleOnIgnore} className={classNames(classes.primaryButton, type)} color="inherit">
            Ok
          </Button>
        </DialogActions>
      );
    }
    return (
      <DialogActions className={classes.actions}>
        <Button onClick={this.handleOnIgnore} className={classNames(classes.primaryButton, type)} color="inherit">
          OK
        </Button>
      </DialogActions>
    );
  }
  private resolveInstruction() {
    const { type, message, classes } = this.props;
    if (message) {
      return null;
    }

    switch (type) {
      default:
        return <div className={classes.defaultErrorMessage}>Please check your information and try again or contact us</div>;
    }
  }

  private handleOnClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (this.props.onClose) {
      this.props.onClose(event);
    } else {
      this.handleOnIgnore(event);
    }
  };
  private handleOnIgnore = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onIgnore && this.props.onIgnore(event);
  };
  private handleOnReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onReport && this.props.onReport(event);
  };
}

export default withStyles(styles)(MessageDialog);
