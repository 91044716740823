import * as React from 'react';

import classNames from 'classnames';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';

import { DocumentGroupModel } from 'src/models';

import Uploader from '../uploader';
import styles, { ClassKeys } from './styles';

interface UploaderFormikProps {
  title: string;
  description?: string | JSX.Element;
  subTitle?: string;
  fieldName: string;
  formikProps: FormikProps<any>;
  multiple?: boolean;
  className?: string;
  onUploadFile: (data: FormData, onUploadProgress: () => void, callback?: (err?: Error) => void) => Promise<any>;
  onDeleteFile: (fileName: string, fileGroup: DocumentGroupModel, callback?: (err?: Error) => void) => Promise<any>;
  onFileNameClick?: (...args: Array<any>) => Promise<any>;
}

type Props = UploaderFormikProps & WithStyles<ClassKeys>;

class UploaderFormik extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    multiple: false,
    description: '',
    subTitle: ''
  };
  render() {
    const { classes, onUploadFile, onDeleteFile, className, formikProps, fieldName, multiple, title, description } = this.props;
    const { errors, values, setFieldValue, touched } = formikProps;
    const fileGroup = _get(values, fieldName);
    const itemFieldName = fieldName + '.fileNames';
    const error = _get(touched, fieldName) ? _get(errors, itemFieldName) : undefined;

    return (
      <Uploader
        dataName={itemFieldName}
        multiple={multiple}
        title={title}
        className={classNames(classes.root, className)}
        description={description}
        fileGroup={fileGroup}
        error={error as any}
        onUpload={onUploadFile}
        onDelete={onDeleteFile}
        onFileGroupUpdate={updatedFileGroup => {
          setFieldValue(fieldName, updatedFileGroup, true);
        }}
        onFileNameClick={this.props.onFileNameClick}
      />
    );
  }
}

export default withStyles(styles)(UploaderFormik);
