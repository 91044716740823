import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[50],
      width: '100%',
      padding: 16,
      marginTop: 16,
      marginBottom: 16,
      boxSizing: 'border-box'
    },
    image: {
      width: 130,
      height: 130
    }
  });

export type ClassKeys = typeof styles;

export default styles;
