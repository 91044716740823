import * as React from 'react';
import * as yup from 'yup';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import Formik from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { Field, Form, FormikProps } from 'formik';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Header from 'src/components/layout/header';
import SvgSendApplication from 'src/components/svg/sendApplication';
import {
  DigicertTermsAndConditionsLabel,
  PrivacyPolicyCheckBoxLabel
} from 'src/containers/onboarding/views/onboarding-detail/steps/review-registration/labels';

import { OnboardingDetailsModel, OnboardingFormStepsEnum } from '../../models';
import ReviewApplicantDetails from './components/review-applicant-details/ReviewApplicantDetails';
import ReviewDirectDebitAccount from './components/review-direct-debit-account/ReviewDirectDebitAccount';
import ReviewLandRegistry from './components/review-land-registry';
import ReviewOrganisationDetail from './components/review-organisation-detail';
import Section from './components/section';
import VOITable from './components/voi-table/VOITable';
import { fillRefValue } from './helpers';
import { ReviewModel } from './models';
import styles, { ClassKeys } from './styles';

interface ReviewRegistrationProps {
  step: number;
  values: OnboardingDetailsModel;
  onEditClick?: (e: React.MouseEvent<HTMLButtonElement>, stepValue: number) => void;
  onStepChange: (shift: number, values?: OnboardingDetailsModel) => void;
  onSubmit?: (values: OnboardingDetailsModel, formikProps: FormikProps<ReviewModel>) => any;
}

type Props = ReviewRegistrationProps & WithStyles<ClassKeys>;

interface State {
  openConfirmationDialog: boolean;
}

const validationSchema = yup.object({
  agreeAcknowledgement: yup.array(yup.boolean().test(`Acknowledgement`, 'Please agree to this acknowledgement.', value => value === true))
});
class ReviewRegistration extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    openConfirmationDialog: false
  };

  private allValues: OnboardingDetailsModel;
  private submitForm: () => void;
  private formikProps: FormikProps<ReviewModel>;

  constructor(props: Props) {
    super(props);

    this.allValues = fillRefValue(props.values);
  }

  componentDidMount() {
    // call formik validateForm if the agreeAcknowledgement is not ticked
    // so that we can show the error by touching the field onNext
    if (this.formikProps != null) {
      this.formikProps.validateForm();
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationDialog()}
        <Header>Review your application</Header>
        <Section label="Applicant">
          <ReviewApplicantDetails applicant={this.allValues.intro.registeredPerson} />
        </Section>
        <Section label="Your organisation" stepIndex={OnboardingFormStepsEnum.OrganisationDetails} onEditClick={this.props.onEditClick}>
          <ReviewOrganisationDetail values={this.allValues.organisationDetails} addressStore={this.allValues.addressStore} />
        </Section>
        <Section label="Land registry and state revenue" stepIndex={OnboardingFormStepsEnum.LandRegistry} onEditClick={this.props.onEditClick}>
          <ReviewLandRegistry landRegistry={this.allValues.landRegistry} addressStore={this.allValues.addressStore} />
        </Section>
        <Section label="Direct debit accounts" stepIndex={OnboardingFormStepsEnum.DirectDebitAccount} onEditClick={this.props.onEditClick}>
          <ReviewDirectDebitAccount directdebitAccount={this.allValues.directDebitAccount} />
        </Section>
        {/*// ! hide trust Account for V1 WEB-2984*/}
        {/* <Section label="Trust account" stepIndex={OnboardingFormStepsEnum.TrustAccount} onEditClick={this.props.onEditClick}>
          <ReviewTrustAccount trustAccount={this.allValues.trustAccount} />
        </Section> */}
        {/*// ! hide digicert for V1 WEB-2984*/}
        {/* <Section label="Signers" stepIndex={OnboardingFormStepsEnum.Signers} onEditClick={this.props.onEditClick}>
          <PersonTable people={this.allValues.signers.signersList} />
        </Section> */}
        <Section label="Verification of identity" stepIndex={OnboardingFormStepsEnum.Signers} onEditClick={this.props.onEditClick}>
          <VOITable VOIPeople={this.allValues.signers.voiPeople} />
        </Section>
        <Formik //
          getInitialValues={this.getReviewValues}
          onPreSubmit={this.onPreSubmit}
          validationSchema={validationSchema}
          onSubmit={this.handleOnSubmit}
        >
          {(formikProps: FormikProps<ReviewModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<ReviewModel>) {
    const { classes } = this.props;
    this.formikProps = formikProps;
    // * Assign submitProps to this.submitForm for every render, for confirmationDialog
    // * refering to: 'tickle-ui\src\containers\documents\views\edit-document\EditDocument.tsx'
    this.submitForm = formikProps.submitForm;

    return (
      <Form>
        <Section label="Disclaimers">
          <Typography variant="body2" className={classes.marginBase}>
            Details from this application will be used to create supporting documents. You will not be able to edit your application after this step, so please
            check before sending your application. Documents must be signed to be uploaded.
          </Typography>
          <Typography variant="body2" className={classes.marginBase}>
            By submitting the application, you confirm on behalf of your organisation that:
          </Typography>
          <Field label={PrivacyPolicyCheckBoxLabel} name="agreeAcknowledgement[0]" component={CheckboxField} className={classes.acknowledgement} />
          <Field
            label="You authorise us to use and disclose your information to DigiCert (Symantec (Australia) Pty Ltd) to establish an account on your behalf."
            name="agreeAcknowledgement[1]"
            component={CheckboxField}
            className={classes.acknowledgement}
          />
          <Field label={DigicertTermsAndConditionsLabel} name="agreeAcknowledgement[2]" component={CheckboxField} className={classes.acknowledgement} />
          <Field
            label="You are authorised to submit this application on behalf of your organisation."
            name="agreeAcknowledgement[3]"
            component={CheckboxField}
            className={classes.acknowledgement}
          />
        </Section>
        {/* Use onNext to trigger confirmation dialog, instead of trigger formikProps.submitForm() directly*/}
        {/* Also ensure agreeAcknowledgement field ticked boxes are ticked */}
        <WizardStepper
          onBack={this.handleOnBackClick}
          nextLabel="Send application"
          disabled={!formikProps.isValid || formikProps.isSubmitting}
          onNext={this.handleOnNext}
        />
      </Form>
    );
  }

  private handleOnNext = () => {
    const { isValid } = this.formikProps;

    if (!isValid) {
      return;
    }

    return this.openSubmitConfirmation();
  };

  private renderConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={this.state.openConfirmationDialog}
        title=""
        okButtonText="Yes, send now"
        cancelButtonText="Review details"
        onClose={this.handleOnClose}
      >
        <div style={{ textAlign: 'center' }}>
          <SvgSendApplication />
          <h3>Are your details correct?</h3>
          <p>You won't be able to edit your application details after this step.</p>
        </div>
      </ConfirmationDialog>
    );
  };

  // value could be undefined('clickaway'), true('procceed'), or false('cancel')
  private handleOnClose = (value: boolean) => {
    if (value) {
      this.submitForm();
    }
    this.closeSubmitConfirmation();
  };

  private handleOnBackClick = () => {
    this.props.onStepChange(-1);
  };

  private openSubmitConfirmation = () => {
    this.setState({ openConfirmationDialog: true });
  };

  private closeSubmitConfirmation = () => {
    this.setState({ openConfirmationDialog: false });
  };

  private getReviewValues = () => {
    return this.allValues.review;
  };

  private onPreSubmit = (reviewValues: ReviewModel): OnboardingDetailsModel => {
    const submitValues: OnboardingDetailsModel = {
      ...this.props.values,
      review: {
        ...reviewValues
      }
    };
    return submitValues;
  };

  private handleOnSubmit = (values: OnboardingDetailsModel, formikProps: FormikProps<ReviewModel>) => {
    return this.props.onSubmit && this.props.onSubmit(values, formikProps);
  };
}

export default withStyles(styles)(ReviewRegistration);
