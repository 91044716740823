import * as React from 'react';

import NumberFormat from 'react-number-format';

export interface FauxChangeEvent {
  target: {
    name: string;
    value: string;
  };
}

interface NumberFormatCustomProps {
  onChange: (event: FauxChangeEvent) => void;
  name: string;
}

/**
 * Use example:
 *
 * <Input
 *   onChange={handleChange}
 *   inputComponent={InputNumberFormat}
 *   inputProps={{
 *     format: '## ### ### ###'
 *   }}
 * />
 *
 * where it is understood that the object passed to handleChange is not a full event object.
 */
export const InputNumberFormat = React.forwardRef((props: NumberFormatCustomProps, ref: React.Ref<any>) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});
