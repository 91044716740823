import * as React from 'react';

import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';

import { DEFAULT_PERSON_BASE_ITEM, PersonModel } from 'src/models';

import { convertPersonEntityToOptionItem, getValidPersonsFromFormikByPath } from '../../helpers';
import { OnboardingDetailsModel } from '../../models';
import PersonList from './person-list';

export type pathStringForListOfPerson = string;
interface PersonComponentBaseProps {}

type Props = PersonComponentBaseProps;

// * Main problem to focus are:
// * {1}. get Avaliable options from {Previous page} and {Current page other section}, options list changed based on current page change
// * {2}. on Delete/on Change add_new to other,
// * - need to delete future reference, (For deletion) update following reference
// * - need to delete reference on current page, (For deletion) update following reference
// * {3}. on delete container (BankAccount)
// * - need to delete container, update following container reference
// * - need to delete nested data reference, update following nested data reference
class PersonComponentBase<P, S = {}> extends React.PureComponent<P & Props, S> {
  // * Variables
  // if global values has been changed because of the reference change
  public touchedGlobalState;
  // Field Name in allValues for current step
  public modelFieldName;
  public allValues: OnboardingDetailsModel;
  // people from previous step as options<LookupItemModel>
  public previousPersonOptions: Array<LookupEnumModel | LookupItemModel>;
  // correspondence address from previous step
  public previousCorrespondenceAddressOption: LookupItemModel;
  // registered office address from previous step

  public previousRegisteredOfficeAddressOption: LookupItemModel;
  // where future person could refer to the person in current step
  public pathsForSectionInFutureSteps: Array<string>;

  // * Constructor and render
  constructor(props: P) {
    super(props);
    this.touchedGlobalState = false;
  }

  public render(): JSX.Element | string {
    return 'this will be overriden by children';
  }

  // * Functions
  // default person list componnent
  public renderPersonListSubSection(options: {
    formikProps: FormikProps<any>;
    fieldName: string;
    defaultPersonItem: PersonModel;
    pathsForSectionToGrabValidNamesFrom: Array<string>;
    addPersonLabel: string;
  }) {
    const { formikProps, fieldName, defaultPersonItem, pathsForSectionToGrabValidNamesFrom, addPersonLabel } = options;
    return (
      <PersonList
        formikProps={formikProps}
        fieldName={fieldName}
        defaultPersonItem={defaultPersonItem}
        pathsForSectionToGrabValidNamesFrom={pathsForSectionToGrabValidNamesFrom}
        getAvailableNamesOptions={this.getAllAvailableNamesOptions}
        addCurrentlySelectedOption={this.addCurrentlySelectedOption}
        onDelete={this.handleOnDeletePerson}
        addPersonLabel={addPersonLabel}
        onChangeSelectPerson={this.onChangeSelectPerson}
      />
    );
  }

  // {1} Used to add back the currently selected option to the options list
  // if it has been filtered out by this.getAllAvailableNamesOptions as usedname
  public addCurrentlySelectedOption = (availablePersonOptions: Array<LookupEnumModel | LookupItemModel>, existingOrNew: string, fieldValues: any) => {
    let selectedOption;
    const addNewOption = {
      id: ADD_NEW,
      name: 'Add a new name'
    };
    // if this person is 'NOT_EMPTY' and is not ADD_NEW, add it as option
    if (existingOrNew.length > 0 && existingOrNew !== ADD_NEW) {
      let personItem;

      // Because the value have not been saved into global model yet
      // if the path is to this page, look up value in this.state/formikValue
      // if not, look back on global state
      if (existingOrNew.startsWith(this.modelFieldName)) {
        // we will get the item from formik,
        personItem = _get(fieldValues, existingOrNew.replace(`${this.modelFieldName}.`, ''));
      } else {
        // otherwise the selected value is from previous steps, so we need to look into the global model
        personItem = _get(this.allValues, existingOrNew);
      }

      if (personItem) {
        selectedOption = convertPersonEntityToOptionItem(existingOrNew, personItem);
      } else {
        Logger.console(SeverityEnum.Warning, 'Could not find any person matching path %s', existingOrNew);
      }
    }

    return availablePersonOptions.concat(selectedOption || []).concat(addNewOption);
  };

  // {1} Mostly a helper function used in signers step
  public getPreviousAvailableNameOptions = (alreadyUsed: Array<PersonModel>) => {
    // collect names from previous steps
    const usedNames = alreadyUsed.map((item: PersonModel) => item.existingOrNew);

    // We need to filter out already used persons from the list of all previously calculated persons.
    //
    // alreadyUsed contains list of persons (trust account accountHolders or authorised signatories
    // ...depending for which subsection we are collecting options)
    const availablePreviousNameOptions = this.previousPersonOptions.filter((item: LookupEnumModel | LookupItemModel) => !~usedNames.indexOf(item.id as string));
    return availablePreviousNameOptions;
  };

  // {1}
  public getAllAvailableNamesOptions = (alreadyUsed: Array<PersonModel>, formikProps: FormikProps<any>, pathsForSectionToGrabValidNamesFrom: Array<string>) => {
    // collect names from previous steps
    const usedNames = alreadyUsed.map((item: PersonModel) => item.existingOrNew);

    // We need to filter out already used persons from the list of all previously calculated persons.
    //
    // alreadyUsed contains list of persons (trust account accountHolders or authorised signatories
    // ...depending for which subsection we are collecting options)
    const availablePreviousNameOptions = this.previousPersonOptions.filter((item: LookupEnumModel | LookupItemModel) => !~usedNames.indexOf(item.id as string));

    // collect names from other sub section, but only those which are valid (does not contain formik errors)
    pathsForSectionToGrabValidNamesFrom.forEach(path => {
      const validAdditionalSectionNames = getValidPersonsFromFormikByPath(path, formikProps, this.modelFieldName);
      const availableAdditionalSectionNames = validAdditionalSectionNames.filter(
        (item: LookupEnumModel | LookupItemModel) => !~usedNames.indexOf(item.id as string)
      );

      // include also valid items from neighbourhood section
      if (availableAdditionalSectionNames.length) {
        Array.prototype.push.apply(availablePreviousNameOptions, availableAdditionalSectionNames);
      }
    });

    return availablePreviousNameOptions;
  };

  // {2}
  public handleOnDeletePerson = (
    fieldName: string,
    fieldIndex: number,
    formikProps: FormikProps<any>,
    pathsForSectionToGrabValidNamesFrom: Array<string>,
    existingOrNew: string
  ) => {
    const { touched, values, setValues } = formikProps;
    const fullModelFieldName = `${this.modelFieldName}.${fieldName}[${fieldIndex}]`;

    // we need to remove person from the list
    const listOfValuesToRemoveFrom = _get(values, fieldName);
    const listOfFieldNameForNewPersonAfterDeletePerson = listOfValuesToRemoveFrom.reduce((acc, item, idx) => {
      if (idx > fieldIndex && item.existingOrNew === ADD_NEW) {
        acc.push(`${this.modelFieldName}.${fieldName}[${idx}]`);
      }
      return acc;
    }, []);

    if (existingOrNew === ADD_NEW || existingOrNew === '') {
      // the side effect to values and this.allValues
      this.sideEffectAfterRemoveAnNewPerson(
        pathsForSectionToGrabValidNamesFrom,
        values,
        fullModelFieldName,
        fieldName,
        listOfFieldNameForNewPersonAfterDeletePerson
      );
    }

    listOfValuesToRemoveFrom.splice(fieldIndex, 1);
    // change field values to the new one without removed person
    // modify formik values on field path
    _set(values, fieldName, listOfValuesToRemoveFrom);

    // same applies for touched
    // we need to remove touched flag
    const listOfTouched = _get(touched, fieldName);
    if (Array.isArray(listOfTouched)) {
      listOfTouched.splice(fieldIndex, 1);
      _set(touched, fieldName, listOfTouched);
      // TODO consider removing from touched also the list itself if it does not contain any other items
    }
    // reload formik value to rerender the form
    setValues(values);
  };

  // {2}
  public onChangeSelectPerson = (
    resolvedValue: string,
    formikProps: FormikProps<any>,
    fieldName: string,
    pathsForSectionToGrabValidNamesFrom: Array<string>
  ) => {
    const { values, setValues } = formikProps;
    const fullModelFieldName = `${this.modelFieldName}.${fieldName}`;
    const existingOrNewPath = (fieldName ? fieldName + '.' : fieldName) + 'existingOrNew';
    const isRemoveNewPerson = _get(values, existingOrNewPath) === ADD_NEW && resolvedValue !== ADD_NEW;
    // Is from add_new to other value
    if (isRemoveNewPerson) {
      _set(values, fieldName, { ...DEFAULT_PERSON_BASE_ITEM, existingOrNew: resolvedValue });
      this.sideEffectAfterRemoveAnNewPerson(pathsForSectionToGrabValidNamesFrom, values, fullModelFieldName, fieldName, []);
      setValues(values);
    }
  };

  // {2}
  private sideEffectAfterRemoveAnNewPerson = (
    pathsForSectionToGrabValidNamesFrom: Array<string>,
    currentStepValues: any,
    fullModelFieldName: string,
    fieldName: string,
    listOfFieldNameForNewPersonAfterDeletePerson: Array<string>
  ) => {
    // set the flag so that the global will be passed out
    this.touchedGlobalState = true;

    // reset also all possible references to this person
    // And all people reference to the person after the delete person

    // * mutate person
    // Update possible reference in the same page (formik value update)
    pathsForSectionToGrabValidNamesFrom.forEach(pathForPeople => {
      const people = _get(currentStepValues, pathForPeople);
      if (Array.isArray(people)) {
        people.forEach(person => {
          this.mutatePersonReferenceAfterDelete(person, person.existingOrNew, fullModelFieldName, fieldName, listOfFieldNameForNewPersonAfterDeletePerson);
        });
      } else {
        this.mutatePersonReferenceAfterDelete(people, people.existingOrNew, fullModelFieldName, fieldName, listOfFieldNameForNewPersonAfterDeletePerson);
      }
    });

    // * mutate person
    // Update possible reference in Future Steps (global value update)
    this.pathsForSectionInFutureSteps.forEach(pathForPeople => {
      const people = _get(this.allValues, pathForPeople);
      if (Array.isArray(people)) {
        people.forEach((person: PersonModel) => {
          this.mutatePersonReferenceAfterDelete(person, person.existingOrNew, fullModelFieldName, fieldName, listOfFieldNameForNewPersonAfterDeletePerson);
        });
      } else {
        this.mutatePersonReferenceAfterDelete(people, people.existingOrNew, fullModelFieldName, fieldName, listOfFieldNameForNewPersonAfterDeletePerson);
      }
    });

    // * mutate person
    // Remove person from signers list
    this.allValues.signers.signersList = this.allValues.signers.signersList.filter(person => person.existingOrNew !== '');
  };

  // {3}
  public handleOnDeleteBankAccount = (
    accountFieldName: string,
    removeIndex: number,
    formikProps: FormikProps<any>,
    pathsForSectionToGrabValidNamesFrom: Array<string>
  ) => {
    const { setFieldValue, values, touched } = formikProps;

    const fullModelFieldName = `${this.modelFieldName}.${accountFieldName}[${removeIndex}]`;
    const listOfAccountsToRemoveFrom = _get(values, accountFieldName);
    const fieldToucheds: any = _get(touched, accountFieldName);
    const deleteAccountId = listOfAccountsToRemoveFrom[removeIndex].id;

    if (Array.isArray(listOfAccountsToRemoveFrom)) {
      // set the flag so that the global will be passed out
      this.touchedGlobalState = true;
      // Get person in the following account in the list
      const listOfRefForAccountsAfterDeleteAccount = listOfAccountsToRemoveFrom.reduce((acc, item, idx) => {
        if (idx > removeIndex) {
          //
          acc.push(`${this.modelFieldName}.${accountFieldName}[${idx}]`);
        }
        return acc;
      }, []);

      // reset also all possible references to this person
      // And all people reference to the person after the delete person

      // Update possible reference in the same page (formik value update)
      pathsForSectionToGrabValidNamesFrom.forEach(pathForListOfPerson => {
        _get(values, pathForListOfPerson).forEach(person => {
          const existingOrNewAccountPrefix = person.existingOrNew.substring(0, fullModelFieldName.length);
          this.mutatePersonReferenceAfterDelete(
            person,
            existingOrNewAccountPrefix,
            fullModelFieldName,
            accountFieldName,
            listOfRefForAccountsAfterDeleteAccount
          );
        });
      });

      // Update possible reference in Future Steps (global value update)
      this.pathsForSectionInFutureSteps.forEach(pathForListOfPerson => {
        _get(this.allValues, pathForListOfPerson).forEach(person => {
          const existingOrNewAccountPrefix = person.existingOrNew.substring(0, fullModelFieldName.length);
          this.mutatePersonReferenceAfterDelete(
            person,
            existingOrNewAccountPrefix,
            fullModelFieldName,
            accountFieldName,
            listOfRefForAccountsAfterDeleteAccount
          );
        });
      });

      // For Signers Array, you need to explicitly remove the person Remove person from signers list
      // HARD CODED CALL BACKS
      this.allValues.signers.signersList = this.allValues.signers.signersList.filter(person => person.existingOrNew !== '');
      // Delete signers
      this.allValues.signers.signersList.forEach(signer => {
        signer.canSignTrustAccounts = signer.canSignTrustAccounts.filter(trustAccountId => trustAccountId !== deleteAccountId);
      });

      // Remove item from an array
      listOfAccountsToRemoveFrom.splice(removeIndex, 1);
      if (fieldToucheds instanceof Array) {
        fieldToucheds.splice(removeIndex, 1);
      }
      setFieldValue(accountFieldName, listOfAccountsToRemoveFrom);
    } else {
      Logger.console(SeverityEnum.Warning, 'Delete element is not in an Array');
    }
  };

  // {2}
  private mutatePersonReferenceAfterDelete(
    person: PersonModel,
    personReference: string,
    fullModelFieldName: string,
    fieldName: string,
    listOfRefPrefixForFollowingAccount: Array<string>
  ) {
    if (personReference === fullModelFieldName) {
      person.existingOrNew = '';
    } else {
      person.existingOrNew = this.decrementFollowingReferenceIndex(fieldName, personReference, person.existingOrNew, listOfRefPrefixForFollowingAccount);
    }
  }

  // {2}, {3} update following reference on delete array element
  private decrementFollowingReferenceIndex(
    fieldName: string,
    fieldReference: string,
    decrementFieldName: string,
    listOfFieldReferenceRequireDecrement: Array<string>
  ) {
    let res = decrementFieldName;
    const fieldNameRegExp = fieldName //
      .replace('[', '\\[')
      .replace(']', '\\]')
      .replace('.', '\\.');
    if (!!~listOfFieldReferenceRequireDecrement.indexOf(fieldReference)) {
      const re = new RegExp(`${fieldNameRegExp}\\[(\\d*)\\]`);
      // decrement the reference to the right person in the right account,
      res = decrementFieldName.replace(re, (str, p1) => {
        return `${fieldName}[${Number(p1) - 1}]`;
      });
    }
    return res;
  }
}

export default PersonComponentBase;
