import { AddressStoreModel } from '@sympli/ui-framework/components/formik/address-field/models';

import { Feature } from 'src/components/feature-toggle/models';

import { DEFAULT_INTRO_VALUES, IntroPageModel } from '../intro/models';
import { DEFAULT_DIRECT_DEBIT_ACCOUNT_VALUES, DirectDebitAccountModel } from './steps/direct-debit-account/models';
import { DEFAULT_LAND_REGISTRY_VALUES, LandRegistryModel, StateLandRegistryEntityModel } from './steps/land-registry/models';
import { DEFAULT_ORGANISATION_DETAIL_VALUES, ExecutionMethodEnum, OrganisationDetailsModel, OrganisationTypeEnum } from './steps/organisation-details/models';
import { DEFAULT_REVIEW_VALUES, ReviewModel } from './steps/review-registration/models';
import { DEFAULT_SIGNERS_VALUES, SignersModel } from './steps/signers/models';
import { DEFAULT_TRUST_ACCOUNT_VALUES, TrustAccountModel } from './steps/trust-account/models';

// ! hide trust Account for V1 WEB-2984
export enum OnboardingFormStepsEnum {
  OrganisationDetails = 0,
  LandRegistry = 1,
  DirectDebitAccount = 2,
  TrustAccount = 6,
  Signers = 3,
  UploadDocuments = 4,
  ReviewRegistration = 5
}

export const INITIAL_FORM_STEPS = [
  { label: 'Your organisation', value: OnboardingFormStepsEnum.OrganisationDetails },
  { label: 'Land registry and state revenue', value: OnboardingFormStepsEnum.LandRegistry },
  { label: 'Direct debit accounts', value: OnboardingFormStepsEnum.DirectDebitAccount },
  // ! hide trust Account for V1 WEB-2984
  // { label: 'Trust account', value: OnboardingFormStepsEnum.TrustAccount },
  { label: 'Verification of identity', value: OnboardingFormStepsEnum.Signers },
  { label: 'Upload documents', value: OnboardingFormStepsEnum.UploadDocuments },
  { label: 'Review your application', value: OnboardingFormStepsEnum.ReviewRegistration }
];

export interface OnboardingDetailsModel {
  intro: IntroPageModel;
  organisationDetails: OrganisationDetailsModel;
  landRegistry: LandRegistryModel;
  directDebitAccount: DirectDebitAccountModel;
  trustAccount: TrustAccountModel;
  signers: SignersModel;
  review: ReviewModel;
  step: OnboardingFormStepsEnum;
  addressStore: AddressStoreModel;
  feature?: Feature;
}

export type OnboardingPageStepsModelType =
  | OnboardingDetailsModel
  | IntroPageModel
  | OrganisationDetailsModel
  | SignersModel
  | DirectDebitAccountModel
  | TrustAccountModel
  | ReviewModel;

export const DEFAULT_ONBOARDING_DETAIL_VALUES: OnboardingDetailsModel = {
  // step 0
  intro: Object.assign({}, DEFAULT_INTRO_VALUES),
  // step 1
  organisationDetails: Object.assign({}, DEFAULT_ORGANISATION_DETAIL_VALUES),
  // step 2
  landRegistry: Object.assign({}, DEFAULT_LAND_REGISTRY_VALUES),
  directDebitAccount: Object.assign({}, DEFAULT_DIRECT_DEBIT_ACCOUNT_VALUES),
  // step 3
  trustAccount: Object.assign({}, DEFAULT_TRUST_ACCOUNT_VALUES),
  // step 4
  signers: Object.assign({}, DEFAULT_SIGNERS_VALUES),
  // step 6
  review: Object.assign({}, DEFAULT_REVIEW_VALUES),

  step: OnboardingFormStepsEnum.OrganisationDetails,

  addressStore: {}
};

// * A full model for checking only
export interface FullModelForDetail extends OnboardingDetailsModel {
  intro: {
    registeredPerson: {
      firstName: string;
      lastName: string;
      jobTitle: string;
      email: string;
      mobileNumber: string;
      middleName?: string;
      fullName?: string;
      existingOrNew: string;
    };
  };
  organisationDetails: {
    company: {
      organisationName: string;
      abn: string;
      acn: string;
      entityType: string;
    };
    tradingName: string;
    registeredOffice: {
      addressId: string;
    };
    principalPlaceOfBusiness: {
      isSameAsRegisteredOffice: boolean;
      addressId: string;
    };
    jurisdictions: Array<string>;
    contacts: {
      mobileNumber: string;
      workNumber: string;
    };
    organisationType: OrganisationTypeEnum;
    acln: string;
    participationAgreement: {
      executionMethod: ExecutionMethodEnum;
      moreInformation: string;
      agreementSigners: Array<{
        // PersonModel
        existingOrNew: string;
        firstName: string;
        lastName: string;
        jobTitle: string;
        email: string;
        mobileNumber: string;
        middleName?: string;
        fullName?: string;
      }>;
    };
    isTrustee: boolean;
    isSubscriberAgent: boolean;
  };
  landRegistry: {
    entityName: string;
    businessName: string;
    states: Array<StateLandRegistryEntityModel>;
  };
  directDebitAccount: {
    accounts: Array<{
      id: string;
      accountDetails: {
        // AccountDetailsModel
        bsbNumber: string;
        accountNumber: string;
        bankName: string;
        accountName: string;
      };
      accountHolders: Array<{
        // PersonModel
        existingOrNew: string;
        firstName: string;
        lastName: string;
        jobTitle: string;
        email: string;
        mobileNumber: string;
        middleName?: string;
        fullName?: string;
      }>;
    }>;
    loanAccount: {
      id: string;
      accountDetails: {
        // AccountDetailsModel
        bsbNumber: string;
        accountNumber: string;
        bankName: string;
        accountName: string;
      };
    };
  };
  trustAccount: {
    isSympliSourceAccount: boolean;
    accounts: Array<{
      id: string;
      accountDetails: {
        // AccountDetailsModel
        bsbNumber: string;
        accountNumber: string;
        bankName: string;
        accountName: string;
      };
      accountLocation: {
        suburb: string;
        state: string;
      };
      accountHolders: Array<{
        // PersonModel
        existingOrNew: string;
        firstName: string;
        lastName: string;
        jobTitle: string;
        email: string;
        mobileNumber: string;
        middleName?: string;
        fullName?: string;
      }>;
      signingAuthorityRequirements: number; // Enum: 0,1,2
    }>;
  };
  signers: {
    signersList: Array<{
      // SignerModel extends PersonModel
      existingOrNew: string;
      firstName: string;
      lastName: string;
      jobTitle: string;
      email: string;
      mobileNumber: string;
      middleName?: string;
      fullName?: string;
      allowedJurisdictionsForDocumentSigning: Array<string>;
      canSignTrustAccounts: Array<string>;
    }>;
    voiType: number;
    voiPeople: Array<{
      existingOrNew: string;
      firstName: string;
      lastName: string;
      jobTitle: string;
      email: string;
      mobileNumber: string;
      middleName?: string;
      fullName?: string;
      isParticipationAgreementSigner: boolean;
    }>;
  };
  review: {
    agreeAcknowledgement: Array<boolean>;
  };
  step: number;
}
