import { FormikErrors } from 'formik';
import _get from 'lodash-es/get';

export function getFirstFormikErrorFieldName(formikErrors: FormikErrors<any>) {
  let firstErrorFieldName = Object.keys(formikErrors)[0];
  let error = formikErrors[firstErrorFieldName];
  // Final error value should be a string
  while (typeof error === 'object') {
    if (Array.isArray(error)) {
      firstErrorFieldName += `[${getFirstNotNullErrorIndexFromArray(error)}]`;
    } else {
      firstErrorFieldName += '.' + Object.keys(error)[0];
    }
    error = _get(formikErrors, firstErrorFieldName);
  }
  return firstErrorFieldName;
}

function getFirstNotNullErrorIndexFromArray(error: Array<any>) {
  // * Array of formik errors could contain value null, which means no error for this index
  for (let i = 0; i < error.length; i++) {
    if (!!error[i]) {
      // * Find the first not null error in array
      return i;
    }
  }
  return undefined;
}
