import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import DisclaimerFooter from 'src/components/layout/disclaimer-footer';
import SvgCongrats from 'src/components/svg/congrats';
import { PersonModel } from 'src/models';

import styles, { ClassKeys } from './styles';

interface GetStartPageProps {
  applicant: PersonModel;
}

type Props = GetStartPageProps & WithStyles<ClassKeys>;

class GetStartPage extends React.PureComponent<Props> {
  render() {
    const { classes, applicant } = this.props;
    return (
      <FlexLayout flexDirection="column" className={classes.root}>
        <div className={classes.container} data-test="intro">
          <div className={classes.content}>
            <Typography variant="h2" className={classes.header} data-test="register successfully">
              Registration Successful!
            </Typography>
            <h1>Please check your email for a link to continue your application!</h1>
            <SvgCongrats className={classes.img} />
            <p>
              {applicant.jobTitle} | {`${applicant.firstName} ${applicant.middleName} ${applicant.lastName}`}
            </p>

            <p>
              <b>{applicant.mobileNumber}</b> | <b>{applicant.email}</b>
            </p>
          </div>
        </div>
        <DisclaimerFooter />
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(GetStartPage);
