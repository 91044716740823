import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    paragraph: {
      margin: 0,
      padding: 0
    },
    certificateListTitle: {
      fontWeight: 'bold',
      fontSize: 18,
      paddingBottom: 20,
      paddingTop: 20,
      borderBottom: '1px solid #D0D0D0',
      maxHeight: 16
    },
    footerValidateButtonContainer: {
      flex: 1,
      width: 300
    },
    footerValidateButton: {
      float: 'right'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
