import * as React from 'react';

import { DIGICERT_PRIVACY_POLICY_LINK, DIGICERT_TERMS_AND_CONDITION_LINK, SYMPLI_PRIVACY_POLICY_LINK } from 'src/models/values';

export const PrivacyPolicyCheckBoxLabel = (
  <span>
    You have read and agree to our{' '}
    <a href={SYMPLI_PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
    .
  </span>
);

export const DigicertTermsAndConditionsLabel = (
  <span>
    You have read and agree to the DigiCert{' '}
    <a href={DIGICERT_TERMS_AND_CONDITION_LINK} target="_blank" rel="noopener noreferrer">
      terms and conditions
    </a>{' '}
    and{' '}
    <a href={DIGICERT_PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
      DigiCert Privacy Policy
    </a>{' '}
    and authorise us to confirm this to DigiCert on your behalf.
  </span>
);
