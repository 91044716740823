import * as React from 'react';

import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { Field } from 'formik';

import { JOB_TITLE_OPTIONS } from 'src/models/values';

interface Props {
  prefixWithDot?: string;
  onChange?: (...args: Array<any>) => void;
}

const PersonEditor = (props: Props) => {
  const { prefixWithDot = '', onChange } = props;
  return (
    <React.Fragment>
      <Field
        name={`${prefixWithDot}jobTitle`}
        label="Job title"
        component={SelectField}
        options={JOB_TITLE_OPTIONS}
        placeholder="Please select"
        onChange={onChange}
      />
      <Field name={`${prefixWithDot}firstName`} label="First name" component={InputField} onChange={onChange} />
      <Field name={`${prefixWithDot}middleName`} label="Middle name  (if applicable)" component={InputField} onChange={onChange} />
      <Field name={`${prefixWithDot}lastName`} label="Last name" component={InputField} onChange={onChange} />
      <Field name={`${prefixWithDot}email`} label="Email" component={InputField} onChange={onChange} />
      <Field name={`${prefixWithDot}mobileNumber`} label="Mobile" component={InputField} onChange={onChange} />
    </React.Fragment>
  );
};

export default PersonEditor;
