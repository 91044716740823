import * as React from 'react';

import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import classNames from 'classnames';
import { Field } from 'formik';

import { InputProps } from '@mui/material/Input';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { modelKey } from 'src/utils/formUtils';

import { CertificateValidationModel } from '../../models';
import { InputNumberFormat } from '../input-number-format/InputNumberFormat';
import styles, { ClassKeys } from './styles';

interface FormBodyProps {}

type Props = FormBodyProps & WithStyles<ClassKeys>;

const fieldName = modelKey<CertificateValidationModel>();

const abnInputProps: Partial<InputProps> = {
  inputComponent: InputNumberFormat as any, //fixme: need to re-check later
  inputProps: {
    format: '## ### ### ###'
  }
};

class FormBody extends React.PureComponent<Props> {
  render() {
    const { classes } = this.props;
    return (
      <FlexLayout className={classes.formContainer}>
        <div className={classes.formBody}>
          <FlexLayout justifyContent="space-between" className={classNames(classes.formBodyFullWidth)}>
            <Field
              name={fieldName('abn')}
              label="Your organisation's ABN"
              component={InputField}
              className={classNames(classes.formBodyField, classes.formBodyABNFieldSize)}
              InputProps={abnInputProps}
            />
          </FlexLayout>

          <FlexLayout className={classNames(classes.formBodyFullWidth)}>
            <Field
              name={fieldName('givenName')}
              label="First name"
              component={InputField}
              className={classNames(classes.formBodyField, classes.formBodyFieldPadding, classes.formBodyNamesFieldSize)}
            />
            <Field
              name={fieldName('middleName')}
              label="Middle name (If applicable)"
              component={InputField}
              className={classNames(classes.formBodyField, classes.formBodyFieldPadding, classes.formBodyNamesFieldSize)}
            />
            <Field
              name={fieldName('surname')}
              label="Last name"
              component={InputField}
              className={classNames(classes.formBodyField, classes.formBodyNamesFieldSize)}
            />
          </FlexLayout>

          <Field
            name={fieldName('email')}
            label="Email"
            component={InputField}
            className={classNames(classes.formBodyFullWidth, classes.formBodyField, classes.formBodyEmailFieldSize)}
          />
        </div>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(FormBody);
