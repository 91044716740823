import * as React from 'react';

import AddressFormDialog from '@sympli/ui-framework/components/dialogs/address-form-dialog';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { AddressFormValueModel, AddressTypeEnum, DEFAULT_PHYSICAL_ADDRESS_ITEM } from '@sympli/ui-framework/components/formik/address-field/models';
import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import classNames from 'classnames';
import { Field, Form, FormikProps, setIn } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';
import _get from 'lodash-es/get';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Formik from 'src/components/formik';
import Header from 'src/components/layout/header';
import ScrollToFormikError from 'src/components/scroll-to-formik-error';
import { getGuid } from 'src/containers/onboarding/helper';
import AbnLookup from 'src/containers/onboarding/views/onboarding-detail/steps/organisation-details/components/abn-lookup';
import { AbnLookupResultModel, DEFAULT_PERSON_ITEM } from 'src/models';

import SingleAddressField from '../../components/single-address-field';
import { collectPersonsFromPreviousSteps } from '../../helpers';
import { OnboardingDetailsModel } from '../../models';
import PersonComponentBase from '../person-component-base';
import PersonList from '../person-component-base/person-list';
import {
  DEFAULT_AGREEMENT_SIGNER_ITEM,
  EXECUTION_METHOD_OPTIONS,
  ExecutionMethodEnum,
  ORGANISATION_TYPE_OPTIONS,
  OrganisationDetailsFormModel,
  OrganisationTypeEnum
} from './models';
import styles, { ClassKeys } from './styles';
import { validationSchema } from './validationSchema';

interface OrganisationDetailsProps {
  step: number;
  onSave: (data: { valuesFromCurrentStep: any; allValues?: OnboardingDetailsModel }, formikProps: FormikProps<any>) => Promise<any>;
  onStepChange: (shift: number, values?: object) => void;
  getInitialValues: (step?: number) => any;
  onAbnLookUp: (abn: string) => Promise<AbnLookupResultModel>;
}

type Props = OrganisationDetailsProps & WithStyles<ClassKeys>;

// TODO JUN move to redux
interface State {
  openAddressDialog: boolean;
  addressKey: number;
  addressFieldName: string;
  dialogTitle: string;
  fixedAddressType?: AddressTypeEnum;
}

class OrganisationDetails extends PersonComponentBase<Props, State> {
  private formikProps: FormikProps<OrganisationDetailsFormModel>;

  // TODO JUN move to redux
  readonly state: State = {
    addressKey: 1,
    addressFieldName: '',
    dialogTitle: '',
    openAddressDialog: false,
    fixedAddressType: AddressTypeEnum.PhysicalAddress
  };

  constructor(props: Props) {
    super(props);
    // Initial values for PersonComponentBase
    this.modelFieldName = 'organisationDetails';
    this.allValues = this.props.getInitialValues() as OnboardingDetailsModel;
    this.initialisePersonReference();
  }

  private initialisePersonReference() {
    const previousPersonPaths = ['intro.registeredPerson'];
    this.previousPersonOptions = collectPersonsFromPreviousSteps(this.allValues, previousPersonPaths);

    const pathsFromLandRegistry = this.allValues.landRegistry.states.reduce((acc, item, idx) => {
      return acc.concat([
        `landRegistry.states[${idx}].financialCorrespondence.contactPerson`,
        `landRegistry.states[${idx}].lodgementCorrespondence.contactPerson`
      ]);
    }, []);
    const pathsForDirectDebitAccountPersonSection = this.allValues.directDebitAccount.accounts.map((item, idx) => {
      return `directDebitAccount.accounts[${idx}].accountHolders`;
    });
    const pathsForTrustAccountPersonSection = this.allValues.trustAccount.accounts.map((item, idx) => {
      return `trustAccount.accounts[${idx}].accountHolders`;
    });
    this.pathsForSectionInFutureSteps = pathsForDirectDebitAccountPersonSection
      .concat(pathsForTrustAccountPersonSection)
      .concat(pathsFromLandRegistry)
      .concat(['signers.signersList']);
  }

  private getInitialValues = (): OrganisationDetailsFormModel => {
    const initialValues = this.props.getInitialValues(this.props.step) as OrganisationDetailsFormModel;
    // Prepopulate number
    if (!initialValues.contacts.mobileNumber) {
      initialValues.contacts.mobileNumber = this.allValues.intro.registeredPerson.mobileNumber;
    }
    // Prepopulate first signer
    if (initialValues.participationAgreement.agreementSigners.length === 0) {
      initialValues.participationAgreement.agreementSigners = [{ ...DEFAULT_PERSON_ITEM, existingOrNew: 'intro.registeredPerson' }];
    }
    return initialValues;
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header>Your organisation</Header>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Tell us about your organisation
        </Typography>
        <AddressFormDialog
          initialValues={{
            isEdit: true,
            existingOrNew: ADD_NEW,
            addressDetail: {
              type: AddressTypeEnum.PhysicalAddress,
              physicalAddress: DEFAULT_PHYSICAL_ADDRESS_ITEM
            }
          }}
          dialogTitle={this.state.dialogTitle}
          open={this.state.openAddressDialog}
          addressStore={_get(this.formikProps, 'values.addressStore', {})}
          fixedAddressType={this.state.fixedAddressType}
          onSubmit={this.handleOnUpdateAddress}
          onCloseDialog={this.handleOnCloseDialog}
          key={this.state.addressKey}
        />
        <Formik //
          getInitialValues={this.getInitialValues} //
          validationSchema={validationSchema}
          onSubmit={this.props.onSave}
          onPreSubmit={this.handleOnPreSubmit}
        >
          {(formikProps: FormikProps<OrganisationDetailsFormModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<OrganisationDetailsFormModel>) {
    const haveCompanyName = !!formikProps.values.company.organisationName;
    this.formikProps = formikProps;

    return (
      <Form>
        <ScrollToFormikError formikProps={formikProps} />
        {this.renderAbnLookup(formikProps)}
        {haveCompanyName && this.renderRestDetails(formikProps)}
        <WizardStepper nextLabel="Save and continue" disabled={!haveCompanyName || formikProps.isSubmitting} />
      </Form>
    );
  }

  private handleOnBlurForTradingName = (e: React.FocusEvent<HTMLInputElement>, ...args: any[]) => {
    const [resolveValue, form] = args as [any, FormikProps<any>];
    form.setFieldValue(e.target.name, resolveValue.trim());
  };

  private renderAbnLookup = (formikProps: FormikProps<OrganisationDetailsFormModel>) => {
    const { onAbnLookUp, classes } = this.props;
    return (
      <>
        <AbnLookup formikProps={formikProps} onAbnLookUp={onAbnLookUp} />
        <FormGroup
          title="Your Sympli subscriber name"
          description="This can be your trading as name or business name. Supporting evidence may be requested if this is different to your organisation name."
        >
          <Field
            className={classNames(classes.fullWidth, classes.fitContent)}
            label="This name will be publicly visible to all Sympli subscribers"
            name="tradingName"
            component={InputField}
            onBlur={this.handleOnBlurForTradingName}
          />
        </FormGroup>
      </>
    );
  };

  private renderRestDetails = (formikProps: FormikProps<OrganisationDetailsFormModel>) => {
    return (
      <React.Fragment>
        {this.renderRegisteredOfficeSection(formikProps)}
        {this.renderPrincipalPlaceSection(formikProps)}
        {this.renderContactsSection()}
        {this.renderOrganisationTypeSection(formikProps)}
        {this.renderAgreementSection(formikProps)}
        {/* {this.renderTrusteeQuestion()} */}
      </React.Fragment>
    );
  };

  private renderRegisteredOfficeSection = (formikProps: FormikProps<OrganisationDetailsFormModel>) => {
    const {
      values: { addressStore }
    } = formikProps;
    const title = 'Main office address';
    const fieldName = 'registeredOffice.addressId';
    return (
      <FormGroup title={title}>
        <SingleAddressField
          fieldName={fieldName}
          dialogTitle="Add address"
          formikProps={formikProps}
          addressStore={addressStore}
          fixedAddressType={AddressTypeEnum.PhysicalAddress}
          onAddClick={this.handleOnAddAddressClick}
          onEditClick={this.handleOnEditAddressClick}
        />
      </FormGroup>
    );
  };

  private renderPrincipalPlaceSection = (formikProps: FormikProps<OrganisationDetailsFormModel>) => {
    const {
      values: { addressStore }
    } = formikProps;
    const fieldName = 'principalPlaceOfBusiness.addressId';
    const title = 'Correspondence/Postal address';
    return (
      <FormGroup title={title}>
        <SingleAddressField
          fieldName={fieldName}
          dialogTitle="Add address"
          formikProps={formikProps}
          addressStore={addressStore}
          onAddClick={this.handleOnAddAddressClick}
          onEditClick={this.handleOnEditAddressClick}
        />
      </FormGroup>
    );
  };

  private renderContactsSection = () => {
    return (
      <FormGroup title="Contact number">
        <Field label="Mobile" name="contacts.mobileNumber" component={InputField} />
        <Field label="Work (optional)" name="contacts.workNumber" component={InputField} />
      </FormGroup>
    );
  };

  private renderOrganisationTypeSection(formikProps: FormikProps<OrganisationDetailsFormModel>) {
    // ! organisationType use value 0 as placeholder, its value must be larger than 1
    return (
      <React.Fragment>
        <FormGroup title="Organisation type">
          <Field //
            label="Select organisation type"
            name="organisationType"
            component={SelectField}
            options={ORGANISATION_TYPE_OPTIONS}
            className={this.props.classes.fullWidth}
          />
          {/* <Field
            name="isSubscriberAgent"
            label="My company will be operating as a Subscriber Agent"
            component={CheckboxField}
            className={this.props.classes.subAgentCheckBox}
          /> */}
        </FormGroup>
        {formikProps.values.organisationType === OrganisationTypeEnum.FinancialInstitution && (
          <FormGroup title="Australian credit licence number">
            <Field label="ACLN" name="acln" component={InputField} className={this.props.classes.fullWidth} />
          </FormGroup>
        )}
      </React.Fragment>
    );
  }

  private renderAgreementSection(formikProps: FormikProps<OrganisationDetailsFormModel>) {
    const { classes } = this.props;
    if (formikProps.values.organisationType === OrganisationTypeEnum.Empty) {
      return null;
    }
    return (
      <React.Fragment>
        <Typography variant="h2" className={classNames(classes.subTitle, classes.marginTop)}>
          Sympli subscriber agreement
        </Typography>
        {this.renderExecutionMethodSection(formikProps)}
        {this.renderAgreementSignatorsSection(formikProps)}
      </React.Fragment>
    );
  }

  private renderExecutionMethodSection(formikProps: FormikProps<OrganisationDetailsFormModel>) {
    const { classes } = this.props;
    // ! executionMethod use value 0 as placeholder, its value must be larger than 1
    return (
      <FormGroup title="Method of execution">
        <Field
          className={classes.fullWidth}
          MenuProps={{ classes: { paper: classes.autoWidth } }} // set max-width to none when the options are too long
          label="Select method of execution"
          name="participationAgreement.executionMethod"
          component={SelectField}
          options={EXECUTION_METHOD_OPTIONS}
        />
        {formikProps.values.participationAgreement.executionMethod === ExecutionMethodEnum.Other && (
          <Field className={classes.fullWidth} label="Please provide more information" name="participationAgreement.moreInformation" component={InputField} />
        )}
      </FormGroup>
    );
  }

  private renderAgreementSignatorsSection(formikProps: FormikProps<OrganisationDetailsFormModel>) {
    return (
      <FormGroup title="Who will be signing the Sympli subscriber agreement?">
        {this.renderAgreementSignersSubSection(formikProps, 'participationAgreement.agreementSigners')}
      </FormGroup>
    );
  }

  private renderAgreementSignersSubSection = (formikProps: FormikProps<OrganisationDetailsFormModel>, fieldName: string) => {
    return (
      <PersonList
        formikProps={formikProps}
        fieldName={fieldName}
        defaultPersonItem={DEFAULT_AGREEMENT_SIGNER_ITEM}
        getAvailableNamesOptions={this.getAllAvailableNamesOptions}
        addCurrentlySelectedOption={this.addCurrentlySelectedOption}
        onDelete={this.handleOnDeletePerson}
        addPersonLabel={'Add another signatory'}
        onChangeSelectPerson={this.onChangeSelectPerson}
      />
    );
  };

  private handleOnAddAddressClick = (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => {
    this.setState({
      openAddressDialog: true,
      addressKey: this.state.addressKey + 1, // * We force the address form dialog to re-render in order to reset underlining formik
      addressFieldName: fieldName,
      dialogTitle: 'Add address',
      fixedAddressType
    });
  };

  private handleOnEditAddressClick = (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => {
    this.setState({
      openAddressDialog: true,
      addressKey: this.state.addressKey + 1, // * We force the address form dialog to re-render in order to reset underlining formik
      addressFieldName: fieldName,
      dialogTitle: 'Edit address',
      fixedAddressType
    });
  };

  private handleOnUpdateAddress = (submitAddressValue: AddressFormValueModel) => {
    const { setValues, values, setFieldTouched, touched } = this.formikProps;
    const { existingOrNew, isEdit, addressDetail } = submitAddressValue;
    let newValues: OrganisationDetailsFormModel = values;
    if (existingOrNew === ADD_NEW) {
      // * for ADD_NEW, append addressStore, set new addressId
      const id = getGuid();
      newValues = setIn(newValues, `addressStore[${id}]`, { id, count: 1, detail: addressDetail });
      newValues = setIn(newValues, this.state.addressFieldName, id);
    } else {
      const selectedId = existingOrNew;
      const currentAddressId = _get(values, this.state.addressFieldName);
      const { addressStore } = values;
      if (currentAddressId === selectedId && isEdit) {
        // * for same addressId, update address detail in addressStore
        newValues = setIn(newValues, `addressStore[${selectedId}]`, { ...addressStore[selectedId], detail: addressDetail });
      } else {
        // * for update addressId
        if (!!currentAddressId) {
          // ! decrement counter, for removing address reference for currentAddressId
          newValues = setIn(newValues, `addressStore[${currentAddressId}]`, {
            ...addressStore[currentAddressId],
            count: addressStore[currentAddressId].count - 1
          });
        }
        // ! increment counter, for adding new address reference
        newValues = setIn(newValues, `addressStore[${selectedId}]`, { ...addressStore[selectedId], count: addressStore[selectedId].count + 1 });
        newValues = setIn(newValues, this.state.addressFieldName, selectedId);
      }
    }
    // * setIn, if called, will return a shallow copy of values
    if (newValues !== values) {
      setValues(newValues);
    }
    if (!_get(touched, this.state.addressFieldName)) {
      setFieldTouched(this.state.addressFieldName as any, true);
    }
    this.handleOnCloseDialog();
  };

  private handleOnCloseDialog = () => {
    this.setState({
      dialogTitle: '',
      addressFieldName: '',
      openAddressDialog: false,
      fixedAddressType: undefined
    });
  };

  private handleOnPreSubmit = (values: OrganisationDetailsFormModel) => {
    // Remove leading and trailing whitespace
    values.tradingName = values.tradingName.trim();
    const updateValue = _cloneDeep(values);
    // ! this is important
    const prevOrgName = this.allValues.organisationDetails.company.organisationName;
    const prevPublicName = this.allValues.organisationDetails.tradingName;
    if (prevOrgName !== values.company.organisationName) {
      // * default lrs entityName only when organisationName is changed
      this.touchedGlobalState = true;
      this.allValues.landRegistry.entityName = values.company.organisationName;
    }
    if (prevPublicName !== values.tradingName) {
      // * default lrs businessName only when tradingName/publicName is changed
      this.touchedGlobalState = true;
      this.allValues.landRegistry.businessName = values.tradingName;
    }
    return this.touchedGlobalState ? { valuesFromCurrentStep: updateValue, allValues: this.allValues } : { valuesFromCurrentStep: updateValue };
  };
}

export default withStyles(styles)(OrganisationDetails);
