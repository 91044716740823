import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    addressLine: {
      marginRight: 10
    },
    marginLeft: {
      marginLeft: 10
    },
    addressFieldContainer: {
      marginBottom: 20,
      position: 'relative',
      width: '100%'
    },
    errorMessage: {
      position: 'absolute',
      bottom: -15
    }
  });

export type ClassKeys = typeof styles;

export default styles;
