import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface TodoPanelProps {
  title: string;
  children?: React.ReactNode;
}

type Props = TodoPanelProps & WithStyles<ClassKeys>;

const TodoPanel = (props: Props) => {
  const { classes, title, children } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default withStyles(styles)(TodoPanel);
