import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    progressBar: {},
    progressStatus: {
      width: 400
    },
    deleteIconButton: {
      width: 32,
      height: 32,
      marginTop: -8,
      marginLeft: -8
    },
    deleteIcon: {
      fontSize: 18,
      fill: theme.palette.primary.main
    },
    title: {
      fontSize: 16,
      verticalAlign: 'middle'
    },
    description: {
      marginBottom: 16,
      marginTop: 16,
      marginRight: 16
    },
    dropZone: {
      cursor: 'pointer',
      height: 110,
      width: 270,
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.primary.main
    },
    disabledDropzone: {
      border: `1px dashed ${theme.palette.divider}`,
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.divider,
      cursor: 'not-allowed'
    },
    dropZoneText: {
      textAlign: 'center',
      color: 'inherit',
      '& > span': {
        fontWeight: 'bold',
        textDecoration: 'underline'
      }
    },
    acceptedFile: {
      background: `repeating-linear-gradient(-45deg, rgba(242,242,242,0.25), rgba(242,242,242,0.25) 10px, ${theme.palette.primary.light} 10px, ${theme.palette.primary.light} 20px)`
    },
    rejectedFile: {
      background: 'repeating-linear-gradient(-45deg, rgba(242,242,242,0.25), rgba(242,242,242,0.25) 10px, #ff0000 10px, #ff0000 20px)'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
