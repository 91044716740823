import * as React from 'react';

import { AddressStoreModel } from '@sympli/ui-framework/components/formik/address-field/models';
import { getAddressLine } from '@sympli/ui-framework/utils/formatters';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { nameLineWithJobTitle } from 'src/utils/formatters';

import { LandRegistryModel, LrsCorrespondenceModel } from '../../../land-registry/models';
import LineItem from '../line-item';
import styles, { ClassKeys } from './styles';

interface ReviewLandRegistryProps {
  landRegistry: LandRegistryModel;
  addressStore: AddressStoreModel;
}

type Props = ReviewLandRegistryProps & WithStyles<ClassKeys>;

class ReviewLandRegistry extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      landRegistry: { states, entityName, businessName }
    } = this.props;

    return (
      <>
        <LineItem title="Customer account names">
          <Typography className={classes.lightText}>Entity name:</Typography>
          <Typography>{entityName}</Typography>
          <Divider style={{ margin: '10px 0px' }} />
          <Typography className={classes.lightText}>Business name:</Typography>
          <Typography>{businessName}</Typography>
        </LineItem>
        {states.map((item, idx) => {
          const state = item.id;
          const { lodgementCorrespondence, financialCorrespondence, stateRevenueCustomerId } = item;
          return (
            <LineItem title={state} key={state} noBottomLine={idx === states.length - 1}>
              {this.renderCorrespondence(lodgementCorrespondence, `${state} lodgement correspondence:`)}
              <Divider style={{ margin: '10px 0px' }} />
              {this.renderCorrespondence(financialCorrespondence, `${state} financial correspondence:`)}
              <Divider style={{ margin: '10px 0px' }} />
              {this.renderStateRevenueCustomerID(stateRevenueCustomerId, `${state} state revenue customer ID`)}
            </LineItem>
          );
        })}
      </>
    );
  }

  private renderStateRevenueCustomerID = (customerID: string, title: string) => {
    const { classes } = this.props;
    return (
      <Typography>
        <span className={classes.lightText}>{title}: </span> <span>{customerID}</span>
      </Typography>
    );
  };

  private renderCorrespondence = (correspondence: LrsCorrespondenceModel, title: string) => {
    const { addressStore, classes } = this.props;
    const { contactPerson, addressId } = correspondence;
    const address = addressStore[addressId];
    return (
      <>
        <Typography className={classes.lightText}>{title}</Typography>
        <Typography>{nameLineWithJobTitle(contactPerson)}</Typography>
        <Typography>{`${contactPerson.mobileNumber} | ${contactPerson.email}`}</Typography>
        <Typography>{!!address ? getAddressLine(address.detail) : 'No address'}</Typography>
      </>
    );
  };
}

export default withStyles(styles)(ReviewLandRegistry);
