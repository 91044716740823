import * as React from 'react';

import Dropzone, { ImageFile } from 'react-dropzone';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconCloudUpload, IconDelete } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';
import Logger from '@sympli/ui-logger';
import classNames from 'classnames';
import _get from 'lodash-es/get';
import _uniq from 'lodash-es/uniq';

import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentGroupModel } from 'src/models';

import styles, { ClassKeys } from './styles';

interface UploaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  error?: string; // error from formik
  title: string;
  description?: string | JSX.Element;
  multiple?: boolean;
  acceptedFiles?: Array<string>;
  fileGroup: DocumentGroupModel;
  onUpload: (data: FormData, onUploadProgress: (...args: any[]) => void, callback?: (err?: Error) => void) => Promise<any>;
  onDelete: (fileName: string, fileGroup: DocumentGroupModel, callback?: (err?: Error) => void) => Promise<any>;
  onFileGroupUpdate: (fileGroup: DocumentGroupModel) => void;
  onFileNameClick?: (...args: Array<any>) => Promise<any>;
  dataName?: string;
}

type Props = UploaderProps & WithStyles<ClassKeys>;

interface State {
  fileName: string;
  percentageUploaded: number;
  errorMessage: string;
}

class Uploader extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    fileName: '',
    percentageUploaded: 0,
    errorMessage: ''
  };

  // * to be a fixed value
  private maxMegaByte = 10;
  public static defaultProps: Partial<Props> = {
    multiple: false,
    acceptedFiles: ['application/pdf']
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFormikError = prevProps.error || '';
    const nextFormikError = this.props.error || '';
    if (prevFormikError !== nextFormikError) {
      this.setState({ errorMessage: nextFormikError });
    }
  }

  render() {
    const { classes, title, description, className, dataName } = this.props;

    return (
      <FlexLayout justifyContent="space-between" className={className}>
        <div data-name={dataName}>
          {title ? <div className={classes.title}>{title}</div> : null}
          <Typography className={classes.description}>{description}</Typography>
          {this.renderUploadedFilesArea()}
          {this.renderUploadingFileArea()}
          {this.renderErrorMessage()}
        </div>
        <div>{this.renderDropZoneArea()}</div>
      </FlexLayout>
    );
  }

  private renderUploadingFileArea() {
    const { classes } = this.props;
    // file being uploaded
    const { fileName, percentageUploaded } = this.state;
    if (!fileName || percentageUploaded === 100) {
      // placeholder
      return <div style={{ height: 24 }} />;
    }

    return (
      <FlexLayout>
        <IconButton disabled={true} color="primary" onClick={this.handleOnDelete} classes={{ root: classes.deleteIconButton }} size="large">
          <IconDelete className={classes.deleteIcon} />
        </IconButton>
        <div className={classes.progressStatus}>
          <FlexLayout justifyContent="space-between">
            <span>{fileName}</span>
            <span>{percentageUploaded}%</span>
          </FlexLayout>
          <LinearProgress variant="determinate" value={percentageUploaded} />
        </div>
      </FlexLayout>
    );
  }

  private renderUploadedFilesArea() {
    // files that has been already uploaded

    const { classes, fileGroup } = this.props;

    return fileGroup.fileNames.map((uploadedFileName, idx) => {
      return (
        <FlexLayout key={'uploaded-file-' + uploadedFileName}>
          <IconButton color="primary" classes={{ root: classes.deleteIconButton }} data-filename={uploadedFileName} onClick={this.handleOnDelete} size="large">
            <IconDelete className={classes.deleteIcon} />
          </IconButton>
          {/* TODO change to button */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            data-filename={uploadedFileName}
            data-filegroup={fileGroup.groupName}
            onClick={this.handleClickFileName}
            title="Open document"
          >
            {uploadedFileName}
          </a>
        </FlexLayout>
      );
    });
  }

  private handleClickFileName = (e: React.SyntheticEvent<any>) => {
    if (this.props.onFileNameClick) {
      const fileName = dataAttribute('filename', e) || 'file.pdf';
      const fileGroup = dataAttribute('filegroup', e) || 'unknownGroup';
      e.preventDefault();
      this.props.onFileNameClick(fileName, fileGroup);
    }
  };

  private renderErrorMessage() {
    return this.state.errorMessage ? <FormHelperText error>{this.state.errorMessage}</FormHelperText> : <div style={{ height: 20 }} />;
  }

  private handleOnDelete = e => {
    const { fileGroup } = this.props;
    const removedFileName = dataAttribute('filename', e) || '';

    const fileNames = fileGroup.fileNames.filter(fileName => fileName !== removedFileName);
    this.props
      .onDelete(removedFileName, fileGroup)
      .then(() => {
        this.props.onFileGroupUpdate({
          ...fileGroup,
          fileNames
        });
      })
      .catch(err => {
        Logger.captureException(err);
      });
  };

  private renderDropZoneArea() {
    const { classes, fileGroup, multiple } = this.props;
    // if not multiple, disabled === false, if is multiple, check fileNames length
    const disabled = !multiple && fileGroup.fileNames.length > 0;
    return (
      <Dropzone
        disabled={disabled}
        maxSize={this.maxMegaByte * 1000 * 1000}
        multiple={multiple}
        accept={this.props.acceptedFiles!.join(',')}
        acceptClassName={classes.acceptedFile}
        rejectClassName={classes.rejectedFile}
        className={classNames(classes.dropZone, disabled && classes.disabledDropzone)}
        onDrop={this.handleOnDrop}
      >
        <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <IconCloudUpload fill="currentColor" />
          <Typography className={classes.dropZoneText}>
            Drag and drop your file here, <br />
            or <span>browse</span>.
          </Typography>
        </FlexLayout>
      </Dropzone>
    );
  }

  private handleOnDrop = (acceptedFiles: ImageFile[], rejectedFiles: ImageFile[]) => {
    acceptedFiles.forEach(file => {
      window.URL.revokeObjectURL(file.preview!);
      const data = new FormData();

      // * Append fileName, groupName, category, subGroupName? to FormData
      data.append('file', file);
      data.append('filename', file.name);
      data.append('groupname', this.props.fileGroup.groupName);
      data.append('category', this.props.fileGroup.category);
      this.props.fileGroup.subGroupName && data.append('subGroupName', this.props.fileGroup.subGroupName);

      this.setState({ fileName: file.name, percentageUploaded: 0, errorMessage: '' }, () => this.uploadFile(data, file.name));
    });
    rejectedFiles.forEach(file => {
      // handle rejectedFile / onDrop Reject
      this.setState({ errorMessage: `Please upload file type ${this.props.acceptedFiles!.join(',')} within ${this.maxMegaByte} MB only.` });
    });
  };

  private uploadFile = (data: FormData, fileName: string) => {
    this.props //
      .onUpload(data, this.handleOnUploadProgress)
      .then(this.handleOnUploadFinish.bind(null, fileName))
      .catch(this.onUploadError);
  };

  private onUploadError = err => {
    Logger.captureException(err);
    const res = _get(err, 'response.data', '');
    let msg;
    if (typeof res === 'string') {
      msg = res;
    } else {
      // try to get the nested message
      const nestedMessage = _get(res, 'message', undefined);
      msg = typeof nestedMessage === 'string' ? nestedMessage : 'There was a problem with uploading file.';
    }
    this.setState({ errorMessage: msg + ' Please try again.' });
  };

  private handleOnUploadProgress = ({ loaded, total }) => {
    let percentageUploaded = Math.floor((loaded * 100) / total);
    this.setState({ percentageUploaded });
  };

  private handleOnUploadFinish = fileName => {
    const { fileGroup, multiple } = this.props;
    let fileNames: Array<string> = [];

    if (multiple) {
      fileNames = _uniq(fileGroup.fileNames.concat(fileName));
    } else {
      fileNames = [fileName];
    }

    this.props.onFileGroupUpdate({
      ...fileGroup,
      fileNames
    });
  };
}

export default withStyles(styles)(Uploader);
