import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    container: {
      flexGrow: 1,
      width: '100%'
    },
    header: {
      fontFamily: '"ff-cocon-pro", Arial, Helvetica, sans-serif',
      fontSize: 56,
      lineHeight: 'normal',
      color: theme.palette.primary.main,
      textTransform: 'none',
      fontWeight: 'bold'
    },
    img: {
      padding: 32
    },
    content: {
      width: 510,
      marginLeft: 120,
      paddingTop: 110,
      // * refer to ./src/index.css: line: 9
      [`@media (min-width:1280px)`]: {
        margin: 'auto'
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
