import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from 'src/components/layout/constants';

const GAP_PANEL_LEFT_48 = 48;
const WIDTH_MAIN_CONTENT_800 = 800;
const WIDTH_PANEL_CONTENT_272 = 272;
const PADDING_SIDE_40 = 40;
export const WIDTH_MIDDLE_CONTAINER_1120 = WIDTH_MAIN_CONTENT_800 + GAP_PANEL_LEFT_48 + WIDTH_PANEL_CONTENT_272;

const styles = (theme: Theme) =>
  createStyles({
    main: {
      paddingLeft: PADDING_SIDE_40,
      width: WIDTH_MAIN_CONTENT_800,
      paddingTop: 36,
      paddingBottom: 36,
      [`@media (min-width:${PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40}px)`]: {
        marginLeft: `calc(50vw - ${(PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40) / 2}px)`
      }
    },
    panel: {
      position: 'fixed',
      width: WIDTH_PANEL_CONTENT_272,
      left: PADDING_SIDE_40 + WIDTH_MAIN_CONTENT_800 + GAP_PANEL_LEFT_48,
      paddingTop: 90,
      [`@media (min-width:${PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40}px)`]: {
        left: `calc(50vw + ${WIDTH_MIDDLE_CONTAINER_1120 / 2 - WIDTH_PANEL_CONTENT_272}px)`
      },
      // fix to align the divider line
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        paddingTop: 84
      }
    },
    content: {
      minHeight: 'calc(100% - 160px)'
    },
    footer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: WIDTH_MIDDLE_CONTAINER_1120,
      [`@media (min-width:${PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40}px)`]: {
        marginLeft: `calc(50vw - ${(PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40) / 2 - PADDING_SIDE_40}px)`
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
