import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    detailBoxContainer: {
      border: '1px solid #D0D0D0',
      backgroundColor: theme.palette.grey[100],
      padding: 30,
      marginTop: 20,
      marginBottom: 25,
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '" "',
        width: 24,
        height: 24,
        transform: 'rotate(45deg)',
        border: '1px solid #D0D0D0',
        backgroundColor: theme.palette.grey[100],
        top: -12,
        left: 30,
        zIndex: 5,
        borderRight: 'none',
        borderBottom: 'none'
      }
    },
    fullWidth: {
      width: '100%'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
