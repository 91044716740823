import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import backGroundImg from './rocket-background.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    container: {
      flexGrow: 1,
      width: '100%',
      background: `url(${backGroundImg}) no-repeat`,
      backgroundSize: '120% auto',
      backgroundPosition: 'right',
      backgroundPositionY: 'bottom'
    },
    form: {
      width: 510,
      marginLeft: 40,
      paddingTop: 65,
      [`@media (min-width:840px)`]: {
        marginLeft: `calc(50vw - ${(40 + 510 + 200) / 2}px)`
      }
    },
    fullWidth: {
      width: '100%'
    },
    inputContainer: {
      marginBottom: 15
    },
    header: {
      fontFamily: '"ff-cocon-pro", Arial, Helvetica, sans-serif',
      fontSize: 56,
      lineHeight: 'normal',
      color: theme.palette.primary.main,
      marginBottom: 15,
      fontWeight: 'bold'
    },
    disclaimer: {
      color: theme.palette.text.secondary,
      maxWidth: 375,
      textAlign: 'justify',
      fontSize: 14
    },
    stepper: {
      alignItems: 'flex-start'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
