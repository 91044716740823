import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    progressStepLabel: {
      textAlign: 'left',
      textDecoration: 'none'
    },
    progressStepDescription: {
      fontWeight: 'normal',
      fontSize: 14
    }
  });

export type ClassKeys = typeof styles;

export default styles;
