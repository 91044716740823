import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    sectionContainer: {
      paddingTop: 5,
      paddingBottom: 5,
      boxSizing: 'border-box',
      flexGrow: 1
    },
    sectionHeader: {
      width: 240,
      color: theme.palette.text.secondary
    },
    sectionContent: {
      flexGrow: 1
    }
  });

export type ClassKeys = typeof styles;

export default styles;
