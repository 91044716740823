import * as React from 'react';
import * as yup from 'yup';

import { Action, Dispatch } from 'redux';

import { ICertInfo } from '@sympli/digital-signing/interfaces';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import Formik from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { MessageNotification } from '@sympli/ui-framework/components/message-notification';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconCaretLeft } from '@sympli/ui-framework/icons';
import { Form, FormikProps } from 'formik';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { updateCertificateValidationInfo } from 'src/actions/application';
import { Box } from 'src/components/layout';
import { resolveSigningErrorMessage, setSigningCertAndSign } from 'src/utils/signing/signing';

import { ErrorVariantEnum, UserInfo } from '../../models';
import CertificateListFormBody from './components/form-body';
import { CertificateListFormModel } from './models';
import styles, { ClassKeys } from './styles';

interface CertificateListPageProps {
  dispatch: Dispatch<Action>;
  certificates: ICertInfo[];
  gatekeepers: string[];
  userInfo: UserInfo;
  logGroupId: string;
}

type Props = CertificateListPageProps & WithStyles<ClassKeys>;

interface State {
  errorInfo: {
    primary?: string;
    secondary: string;
  } | null;
}

class CertificateListPage extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    errorInfo: null
  };

  private getInitialValues(): CertificateListFormModel {
    return {
      selectedCertificateSerialNumber: null
    };
  }

  private getValidationSchema() {
    return yup.object<CertificateListFormModel>({
      selectedCertificateSerialNumber: yup.string().nullable().required('Required')
    });
  }

  render() {
    const { errorInfo } = this.state;
    return (
      <React.Fragment>
        <Box className={this.props.classes.certificateListTitle}>Select a certificate to validate</Box>

        <MessageNotification //
          variant={ErrorVariantEnum.Error}
          open={!!errorInfo}
          primary={errorInfo?.primary || 'This digital certificate is not supported by Sympli.'}
          secondary={errorInfo ? errorInfo.secondary : ''}
        />

        <Formik
          onSubmit={this.handleOnSubmit}
          getInitialValues={this.getInitialValues} //
          validationSchema={this.getValidationSchema} // initialize Yup validation schema
        >
          {(formikProps: FormikProps<CertificateListFormModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<CertificateListFormModel>) {
    const { certificates, classes } = this.props;
    return (
      <Form>
        <CertificateListFormBody certificates={certificates} />

        <FlexLayout alignItems="center">
          <ButtonLink onClick={this.handleOnBackClick} icon={<IconCaretLeft width="18" height="18" />} color="inherit">
            Back
          </ButtonLink>
          <div className={classes.footerValidateButtonContainer}>
            <SympliButton
              type="submit"
              className={classes.footerValidateButton}
              color="primary"
              variant="contained"
              arrowRight
              isLoading={formikProps.isSubmitting}
              disabled={formikProps.isSubmitting}
            >
              Validate
            </SympliButton>
          </div>
        </FlexLayout>
      </Form>
    );
  }

  private handleOnSubmit = async ({ selectedCertificateSerialNumber }: CertificateListFormModel) => {
    this.setState({
      errorInfo: null
    });

    const { certificates, userInfo, logGroupId, gatekeepers } = this.props;

    const selectedCertificate = certificates.find(item => item.id.certSerialNumber === selectedCertificateSerialNumber);
    if (!selectedCertificate) {
      // this should never happen
      throw new Error("Can't find certificate");
    }

    try {
      await setSigningCertAndSign(selectedCertificate.id, userInfo, gatekeepers, logGroupId);
      this.props.dispatch(
        updateCertificateValidationInfo({
          selectedCertificate
        })
      );
    } catch (err) {
      this.setState({
        //
        errorInfo: {
          secondary: resolveSigningErrorMessage(err)
        }
      });
    }
  };

  private handleOnBackClick = () => {
    this.props.dispatch(
      updateCertificateValidationInfo({
        certificates: [], // reset certificates to empty
        selectedCertificate: null
      })
    );
  };
}

export default withStyles(styles)(CertificateListPage);
