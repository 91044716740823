import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import { PersonModel } from 'src/models';

import { OnboardingDetailsModel } from './models';

export const resolveFullTitle = item => item.firstName + ' ' + item.lastName + ', ' + item.jobTitle;

export const convertPersonEntityToOptionItem = (path: string, item: PersonModel, idx?: number) => {
  const name = resolveFullTitle(item);
  if (idx !== undefined) {
    return { id: `${path}[${idx}]`, name };
  }
  return { id: path, name };
};

export const collectPersonsFromPreviousSteps = (model: OnboardingDetailsModel, paths: Array<string>) => {
  const options = paths.reduce((results: Array<LookupEnumModel | LookupItemModel>, path: string) => {
    const items = _get(model, path);
    if (Array.isArray(items)) {
      items.forEach((item: PersonModel, idx: number) => {
        if (item.existingOrNew === ADD_NEW) {
          results.push(convertPersonEntityToOptionItem(path, item, idx));
        }
      });
    } else {
      // in case of Intro.registeredPerson it's just a single item
      if (items.existingOrNew === ADD_NEW) {
        results.push(convertPersonEntityToOptionItem(path, items));
      }
    }

    return results;
  }, []);

  return options;
};

export const collectAddressFromPreviousSteps = (model: OnboardingDetailsModel, path: string) => {
  const item = _get(model, path);
  return { id: path, name: item };
};

const getErrorIndexesByPath = (path: string, errors: object) => {
  const invalidIndexes = _get(errors, path, []).reduce((indexes, err, idx) => {
    if (err) {
      indexes.push(idx);
    }
    return indexes;
  }, []);

  return invalidIndexes;
};

export const getValidPersonsFromFormikByPath = (path: string, formikProps: FormikProps<any>, pathPrefix?: string) => {
  const { values, errors, touched } = formikProps;
  // Array<PersonModel> or PersonModel
  const items = _get(values, path);
  let validPersons = [];
  if (Array.isArray(items)) {
    // items: Array<PersonModel>
    const invalidIndexes = getErrorIndexesByPath(path, errors);

    // * Potential problem: on a page initialized with two people array sections,
    // * in first render, error object is not produced yet
    // * options could include EMPTY NON_ERROR person
    items.reduce((results: Array<LookupEnumModel | LookupItemModel>, item: PersonModel, idx: number) => {
      if (item.existingOrNew === ADD_NEW) {
        if (!~invalidIndexes.indexOf(idx)) {
          results.push(convertPersonEntityToOptionItem(`${pathPrefix ? pathPrefix + '.' : ''}${path}`, item, idx));
        }
      }
      return results;
    }, validPersons);
  } else {
    // items: PersonModel
    if (items.existingOrNew === ADD_NEW) {
      // TODO fix walkaround
      // Potential problem: when go back to page, person is NOT TOUCHED,
      // if it has value(email) it is a prepopulated added person and can be picked (in options)
      // if it does NOT have value(email) it is a default new person, who can not be picked (in options)
      // * if no error, and [not touched OR have email address(this is a WALKAROUND)]
      if (!_get(errors, path) && (_get(touched, path) || !!items.email)) {
        validPersons.push(convertPersonEntityToOptionItem(`${pathPrefix ? pathPrefix + '.' : ''}${path}`, items));
      }
    }
  }

  return validPersons as Array<LookupEnumModel | LookupItemModel>;
};
