import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import sharedStyles from '../sharedStyles';

const styles = (theme: Theme) => {
  return {
    ...sharedStyles(theme),
    ...createStyles({
      error: {
        color: 'red'
      }
    })
  };
};

export type ClassKeys = typeof styles;

export default styles;
