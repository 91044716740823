import '@sympli/ui-framework/extensions';
import 'src/utils/http/overrides';

import * as React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Logger, { PageActionEnum } from '@sympli/ui-logger';
import NewRelicBrowser from 'new-relic-browser';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ErrorDialog from './components/error-dialog';
import Router from './Router';
import store from './store';
import theme from './theme';
import runtimeEnvResolver from './utils/runtimeEnvResolver';

import './index.css';

const environments = runtimeEnvResolver();

declare global {
  interface Window {
    simulateError(): void;
    simulateMessage(): void;
    newrelic?: typeof NewRelicBrowser;
    NR_APP_ID?: string;
    NR_RELEASE_ID?: string;
  }
}

if (typeof window.NR_RELEASE_ID === 'string') {
  // we need to add release info otherwise source map won't be correctly mapped by NewRelic
  window.newrelic?.addRelease(environments.RUNTIME_ENV, window.NR_RELEASE_ID);
}

Logger.capturePageAction(PageActionEnum.RouteInitialPageLoad);

// https://material-ui.com/styles/api/#creategenerateclassname-options-class-name-generator
// need to disable the global css style as it will cause css weight diff in dev and prod build
// which is causing the style diff between env
const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'jss'
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <BrowserRouter basename={import.meta.env.VITE_PUBLIC_URL}>
              <ErrorDialog />
              <Router />
            </BrowserRouter>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StylesProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
