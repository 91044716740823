import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { WithStyles } from '@mui/styles/withStyles';
import withStyles, { ClassKeyOfStyles, ClassNameMap } from '@mui/styles/withStyles';

import DisclaimerFooter from '../disclaimer-footer';
import { ClassKeys as ClassKeysOfDisclaimerFooter } from '../disclaimer-footer/styles';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  mainBody: JSX.Element;
  panelBody: JSX.Element;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class OnboardingBody extends React.PureComponent<Props> {
  private footerClasses: ClassNameMap<ClassKeyOfStyles<ClassKeysOfDisclaimerFooter>>;

  constructor(props: Props) {
    super(props);
    this.footerClasses = { footer: props.classes.footer };
  }

  render() {
    const { mainBody, panelBody, classes, ...props } = this.props;
    return (
      <React.Fragment>
        <FlexLayout className={classes.content}>
          <div className={classes.main} {...props}>
            {mainBody}
          </div>
          <div className={classes.panel}>{panelBody}</div>
        </FlexLayout>
        <DisclaimerFooter classes={this.footerClasses} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OnboardingBody);
