import * as React from 'react';

import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { camelCaseToSentenceCase } from 'src/containers/onboarding/helper';
import { DocumentApiItemModel, DocumentGroupModel } from 'src/models';

import LineItem from '../line-item';
import styles, { ClassKeys } from './styles';

export interface DocumentGroupListModel {
  [groupName: string]: DocumentGroupModel;
}

interface ReviewDocumentsProps {
  documents: Array<DocumentApiItemModel>;
  subTitle: string;
}

type Props = ReviewDocumentsProps & WithStyles<ClassKeys>;

class ReviewDocuments extends React.PureComponent<Props> {
  render() {
    const { documents, classes, subTitle } = this.props;

    // TODO group by function
    const documentGroups: DocumentGroupListModel = documents.reduce((acc, cur) => {
      const fullGroupName = cur.subGroupName ? `${cur.groupName} - ${cur.subGroupName}` : cur.groupName;
      if (!!acc[fullGroupName]) {
        acc[fullGroupName].fileNames.push(cur.fileName);
      } else {
        acc[fullGroupName] = { groupName: fullGroupName, fileNames: [cur.fileName] };
      }
      return acc;
    }, {});

    return (
      <React.Fragment>
        <Typography className={classNames(classes.marginBase, classes.bold)}>{subTitle}</Typography>
        {Object.keys(documentGroups).map((fullGroupName, idx) => {
          const documentGroup = documentGroups[fullGroupName];
          const title = camelCaseToSentenceCase(fullGroupName);
          return (
            <LineItem title={title} key={documentGroup.groupName} noBottomLine={idx === Object.keys(documentGroups).length - 1}>
              <Typography>
                {documentGroup.fileNames.map((fileName, j) => (
                  <React.Fragment key={fileName + j}>
                    <span style={{ textDecoration: 'underline' }}>{fileName}</span>
                    {j !== documentGroup.fileNames.length && ', '}
                  </React.Fragment>
                ))}
              </Typography>
            </LineItem>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewDocuments);
