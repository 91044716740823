import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import CertificateValidation from 'src/containers/certificate-validation';
import Onboarding from 'src/containers/onboarding';

const Router = () => (
  <Routes>
    <Route path="/certificate" element={<CertificateValidation />} />
    <Route path="/" element={<Onboarding />} />
  </Routes>
);

export default Router;
