import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';

import { createRootReducer } from '../reducers';
import sagas from '../sagas';

export default function configureStore(preloadedState: any = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createRootReducer(), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleware
        // ... other middlewares ...
      )
    )
  );

  sagaMiddleware.run(sagas);

  return store;
}
