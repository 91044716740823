import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: 24
    },
    boxHeader: {
      paddingBottom: 6
    },

    linkRoot: {
      minWidth: 0
    },
    title: {
      maxWidth: '100%'
    },

    linkText: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    linkIcon: {
      fontSize: 18,
      flexShrink: 0
    },

    linkMovedLeft: {
      marginLeft: -18
    },

    actionLabel: {
      fontSize: 13,
      lineHeight: '14px',
      fontWeight: 'bold'
    },

    actionRoot: {
      height: 22,
      padding: '0 4px',
      marginRight: -4
    },

    actionRootDisabled: {
      background: 'none'
    },

    actionIcon: {
      width: 12,
      fontSize: 12
    },

    gutters: {
      paddingTop: 40,
      paddingLeft: 14,
      paddingRight: 14
    }
  });

export type ClassKeys = typeof styles;

export default styles;
