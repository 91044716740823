import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    topBodyContainer: {
      paddingTop: 5,
      background: '#FAFAFA',
      paddingRight: 28
    },
    bottomBodyContainer: {
      background: '#FAFAFA',
      paddingBottom: 28
    },
    certificateDetailsBox: {
      fontSize: 16
    },
    certificateDetails: {
      fontSize: 14,
      margin: 0,
      padding: 0,
      color: '#888888'
    },
    footerContainer: {
      paddingTop: 10,
      display: 'flex',
      alignItems: 'center'
    },
    footerBackButtonContainer: {
      cursor: 'pointer',
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      fontSize: 13,
      display: 'flex',
      alignItems: 'center'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
