import { PersonBaseModel } from 'src/models';

export function nameLineWithJobTitle(data: PersonBaseModel) {
  const text = `${data.firstName} ${data.lastName}, ${data.jobTitle}`;
  return text;
}

export function nameLine(data: PersonBaseModel): string {
  return [data.firstName, data.middleName, data.lastName].filter(d => d).join(' ');
}
