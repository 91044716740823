import { ADD_NEW, StateEnum } from '@sympli/ui-framework/components/formik/address-field/values';

import { BLANK_PERSON_ITEM, DEFAULT_PERSON_ITEM, PersonModel, WithAddressStore } from 'src/models';

export interface AccountHolderEntityModel extends PersonModel {}
export const DEFAULT_ACCOUNT_HOLDER_ITEM: AccountHolderEntityModel = Object.assign({}, DEFAULT_PERSON_ITEM);

export const getDefaultLandRegistryEntity = (state: StateEnum): StateLandRegistryViewModel => ({
  id: state,
  show: false,
  financialCorrespondence: {
    contactPerson: Object.assign({}, BLANK_PERSON_ITEM),
    addressId: ''
  },
  lodgementCorrespondence: {
    contactPerson: Object.assign({}, BLANK_PERSON_ITEM),
    addressId: ''
  },
  stateRevenueCustomerId: ''
});

export interface StateLandRegistryEntityModel {
  id: StateEnum; // jurisdiction
  financialCorrespondence: LrsCorrespondenceModel;
  lodgementCorrespondence: LrsCorrespondenceModel;
  stateRevenueCustomerId: string;
}

export interface LrsCorrespondenceModel {
  contactPerson: PersonModel;
  addressId: string;
}
export interface LandRegistryModel {
  entityName: string;
  businessName: string;
  states: Array<StateLandRegistryEntityModel>;
}

export const DEFAULT_LAND_REGISTRY_VALUES: LandRegistryModel = { entityName: '', businessName: '', states: [] };

export interface LandRegistryViewModel {
  lrsJurisdictions: Array<string>;
  allStates: Array<StateLandRegistryViewModel>;
}

export interface StateLandRegistryViewModel extends StateLandRegistryEntityModel {
  show: boolean;
}

export type LandRegistryFormikModel = LandRegistryModel & LandRegistryViewModel & WithAddressStore;

export const MOCK_UP_LAND_REGISTRY_VALUES: LandRegistryModel = {
  entityName: 'entity name',
  businessName: 'business name',
  states: [
    {
      id: StateEnum.NSW,
      financialCorrespondence: {
        contactPerson: {
          existingOrNew: ADD_NEW,
          firstName: 'Peter',
          lastName: 'Kelemen',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        },
        addressId: ''
      },
      lodgementCorrespondence: {
        contactPerson: {
          existingOrNew: ADD_NEW,
          firstName: 'John',
          lastName: 'Smith',
          jobTitle: 'Secretary',
          email: 'john@smith.com',
          mobileNumber: '0416342735'
        },
        addressId: ''
      },
      stateRevenueCustomerId: ''
    }
  ]
};
