import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: 740
    },
    fullWidth: {
      width: '100%'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
