import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    paragraph: {
      margin: 0,
      padding: 0
    },
    formBody: {
      paddingTop: 4,
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      fontSize: 13,
      fontWeight: 'bold',
      color: '#333333'
    },
    formBodyField: {
      minHeight: 60
    },
    formBodyFieldPadding: {
      paddingRight: '25px'
    },
    formBodyNamesFieldSize: {
      maxWidth: '210px',
      height: '34px'
    },
    formBodyABNFieldSize: {
      width: '210px',
      height: '34px',
      paddingLeft: 0,
      paddingRight: 0
    },
    formBodyEmailFieldSize: {
      maxWidth: '680px',
      height: '34px'
    },
    formBodyHalfWidth: {
      width: '47.5%'
    },
    formBodyFullWidth: {
      width: '100%'
    },
    formBodyCheckboxes: {
      paddingTop: 4
    },
    formContainer: {
      paddingTop: 16
    },
    formPanel: {
      paddingRight: 20,
      width: 270
    },
    formPanelTitle: {
      fontWeight: 'normal',
      fontSize: 16,
      color: '#333333'
    },
    formPanelText: {
      fontSize: 13,
      color: '#888888'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
