import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      zIndex: 100,
      background: 'white',
      textAlign: 'center',
      paddingTop: 40
    },
    header: {
      fontSize: 32,
      textTransform: 'none'
    },
    img: {
      padding: 32
    },
    description: {
      fontWeight: 'bold'
    },

    link: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5)
    },
    endAdornment: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginTop: -1,
      marginBottom: -1,
      marginRight: -1
    },
    verifyInputAdornmentEnd: {
      marginRight: 0
    },
    resendSuccess: {
      color: theme.palette.primary.main
    },

    // for popup
    paper: {
      minWidth: 360 - 44 - 44,
      borderRadius: 'unset',
      boxShadow: 'none',
      padding: '40px 44px'
    },
    popupContent: {
      fontSize: 14,
      textAlign: 'center'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
