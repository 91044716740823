import * as React from 'react';

import { AddressStoreModel } from '@sympli/ui-framework/components/formik/address-field/models';
import { getAddressLine } from '@sympli/ui-framework/utils/formatters';
import classNames from 'classnames';
import _find from 'lodash-es/find';
import _get from 'lodash-es/get';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { PersonBaseModel } from 'src/models';
import { nameLineWithJobTitle } from 'src/utils/formatters';

import { EXECUTION_METHOD_OPTIONS, ORGANISATION_TYPE_OPTIONS, OrganisationDetailsModel } from '../../../organisation-details/models';
import LineItem from '../line-item';
import styles, { ClassKeys } from './styles';

interface ReviewOrganisationDetailProps {
  values: OrganisationDetailsModel;
  addressStore: AddressStoreModel;
  applicant?: PersonBaseModel;
}

type Props = ReviewOrganisationDetailProps & WithStyles<ClassKeys>;
class ReviewOrganisationDetail extends React.PureComponent<Props> {
  render() {
    const { applicant, classes, values, addressStore } = this.props;
    const registeredAddress = addressStore[values.registeredOffice.addressId];
    const correspondenceAddress = addressStore[values.principalPlaceOfBusiness.addressId];
    return (
      <React.Fragment>
        <Typography className={classNames(classes.marginBase, classes.bold)}>Sympli subscriber name: {values.tradingName}</Typography>

        <LineItem title="Entity name:">
          <Typography>{values.company.organisationName}</Typography>
        </LineItem>
        <LineItem title="ABN:">
          <Typography>{values.company.abn}</Typography>
        </LineItem>
        {applicant && (
          <LineItem title="Applicant:">
            <Typography>
              {nameLineWithJobTitle(applicant)}
              <br />
              {applicant.mobileNumber}&nbsp;|&nbsp;{applicant.email}
            </Typography>
          </LineItem>
        )}
        <LineItem title="Main office address:">
          <Typography>{!!registeredAddress ? getAddressLine(registeredAddress.detail) : 'No address'}</Typography>
        </LineItem>
        <LineItem title="Correspondence address:">
          <Typography>{!!correspondenceAddress ? getAddressLine(correspondenceAddress.detail) : 'No address'}</Typography>
        </LineItem>
        <LineItem title="Jurisdictions:">
          <Typography>{values.jurisdictions.join(', ')}</Typography>
        </LineItem>
        <LineItem title="Mobile number:">
          <Typography>{values.contacts.mobileNumber}</Typography>
        </LineItem>
        <LineItem title="Work number:">
          <Typography>{values.contacts.workNumber.length ? values.contacts.workNumber : 'Not provided'}</Typography>
        </LineItem>
        <LineItem title="Organisation type:">
          <Typography>{_get(_find(ORGANISATION_TYPE_OPTIONS, { id: values.organisationType }), 'name')}</Typography>
        </LineItem>
        {/* <LineItem title="Operating as subscriber agent:" noBottomLine>
          <Typography>{values.isSubscriberAgent ? 'Yes' : 'No'}</Typography>
        </LineItem> */}

        <Typography className={classNames(classes.marginBase, classes.bold)}>Sympli subscriber agreement</Typography>
        <LineItem title="Method of execution:">
          <Typography>{_get(_find(EXECUTION_METHOD_OPTIONS, { id: values.participationAgreement.executionMethod }), 'name')}</Typography>
        </LineItem>
        {values.participationAgreement.agreementSigners.map((signatory, idx, signers) => {
          return (
            <LineItem title={`Signatory${signers.length > 1 ? ` ${idx + 1}` : ''}:`} key={'signtory' + idx}>
              <Typography>
                {nameLineWithJobTitle(signatory)}
                <br />
                {signatory.mobileNumber}&nbsp;|&nbsp;{signatory.email}
              </Typography>
            </LineItem>
          );
        })}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ReviewOrganisationDetail);
