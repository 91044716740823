import DocumentSigning from '@sympli/digital-signing';
import { ICertIdentifier, ISignRequestPayload, IUserInfo } from '@sympli/digital-signing/interfaces';
import Http from '@sympli/http-agent';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { GatekeepersResponse, UserInfo } from 'src/containers/certificate-validation/models';

const buildSigningLogger =
  (logGroupId: string) =>
  (...args: any[]) => {
    // WEB-17729 - log everything to NR for better visibility
    Logger.capturePageAction(PageActionEnum.DocumentSigning, {
      step: 'library',
      log: args,
      logGroupId // WEB-27575
    });
  };

function resolveUserInfo(userInfo: UserInfo, supportedGatekeepers: string[]) {
  const middleNamePostfix = userInfo.middleName ? ' ' + userInfo.middleName : '';
  const userInfoCopy: IUserInfo = {
    ...userInfo, //
    givenName: userInfo.givenName + middleNamePostfix,
    abn: userInfo.abn.replace(/ /g, ''),
    //
    //NOTE: The following two fields are required by document signing toolkit.
    //This is a library used outside of this project.
    allowSoftToken: true,
    allowHardToken: true,
    supportedGatekeepers
  };
  return userInfoCopy;
}

export async function getUserCertificatesFromToolKit(userInfo: UserInfo, supportedGatekeepers: string[], logGroupId: string) {
  // always create new instance otherwise there are issues in scenarios when challenge code approval failed
  const ds = new DocumentSigning({
    //
    logger: buildSigningLogger(logGroupId)
  });
  const { certs, errors } = await ds.getAllSigningCerts(resolveUserInfo(userInfo, supportedGatekeepers));

  return { certs, errors };
}

export async function validateSignedPayload(data: any) {
  return Http.post<any>('/Signatures/verify', data);
}

export async function getGatekeepers() {
  const url = '/Signatures/gatekeepers';
  return Http.get<GatekeepersResponse>(url);
}

export async function setSigningCertAndSign(identifier: ICertIdentifier, userInfo: UserInfo, supportedGatekeepers: string[], logGroupId: string) {
  try {
    const ds = new DocumentSigning({
      //
      logger: buildSigningLogger(logGroupId)
    });

    const payloads: ISignRequestPayload[] = [
      {
        payload: '<?xml version="1.0" encoding="UTF-8"?><CounterpartData>Hello</CounterpartData>',
        xPath: '//CounterpartData',
        identifier: '//CounterpartData'
      }
    ];

    const {
      payloads: [signedCounterpartData]
    } = await ds.sign({
      payloads,
      userInfo: resolveUserInfo(userInfo, supportedGatekeepers),
      certificateIdentifier: identifier,
      includeChain: false
    });

    const result = await validateSignedPayload({
      signedObject: signedCounterpartData.xml,
      signatureFormat: 'XmlDsig',
      userDetails: {
        firstName: userInfo.givenName,
        middleName: userInfo.middleName,
        lastName: userInfo.surname,
        abn: userInfo.abn,
        email: userInfo.email
      }
    });

    if (result.isValid === true) {
      return;
    }

    throw new Error('Certificate is not valid');
  } catch (err) {
    throw err;
  }
}

const NO_HARD_TOKEN_CERTIFICATE_FOUND = 'NO_HARD_TOKEN_CERTIFICATE_FOUND';
const NO_SOFT_TOKEN_CERTIFICATE_FOUND = 'NO_SOFT_TOKEN_CERTIFICATE_FOUND';
const NO_CERTIFICATE_FOUND = 'NO_CERTIFICATE_FOUND';
const SYMPLI_EXTENSION_NATIVE_HOST_NOT_READY = 'Native host not ready';

const errorMessageMapping: Record<string, string> = {
  CKR_SESSION_HANDLE_INVALID: `The specified session handle was invalid at the time that the function was invoked.
    Note that this can happen if the session's token is removed before the function invocation,
    since removing a token closes all sessions with it. Please unplug and plug in your hard token and try again.`,
  // from customized certificate checking when the tool kit return the
  [NO_HARD_TOKEN_CERTIFICATE_FOUND]: 'Please make sure you are using a supported hard token device and device is plugged in.',
  [NO_SOFT_TOKEN_CERTIFICATE_FOUND]: 'Please make sure the certificate has been installed on you computer.',
  [NO_CERTIFICATE_FOUND]: 'No valid signing certificate found. Please ensure you have installed your signing certificate, or your hard token is plugged in.',
  [SYMPLI_EXTENSION_NATIVE_HOST_NOT_READY]: 'Please make sure required signing software is installed on your computer.'
};

export function resolveSigningErrorMessage(err: Error) {
  return errorMessageMapping[err.message] || 'There was an error while verifying certificate. Please try again.';
}
