import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import tableStyles from '../../../signers/components/person-table/styles';

const styles = (theme: Theme) => {
  return {
    ...tableStyles(theme),
    ...createStyles({
      subTitle: {
        paddingBottom: 13,
        borderBottom: '1px solid #D0D0D0',
        marginBottom: 36,
        position: 'relative'
      },
      headerRow: {
        '&:first-child': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white,
          height: 27,
          fontSize: 13,
          fontWeight: 'bold'
        },
        '&:nth-child(2)': {
          backgroundColor: theme.palette.grey[100],
          fontWeight: 'bold'
        }
      }
    })
  };
};

export type ClassKeys = typeof styles;

export default styles;
