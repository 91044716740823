import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { DEFAULT_PERSON_ITEM, PersonModel, VOIPersonModel, VOITypeEnum } from 'src/models';

export interface SignerEntityModel extends PersonModel {
  canSignTrustAccounts: Array<string>;
  allowedJurisdictionsForDocumentSigning: Array<string>;
}
export const DEFAULT_SIGNER_ITEM: SignerEntityModel = Object.assign({}, DEFAULT_PERSON_ITEM, {
  canSignTrustAccounts: [],
  allowedJurisdictionsForDocumentSigning: []
});
export type SignersModel = {
  signersList: Array<SignerEntityModel>;
  voiType: VOITypeEnum;
  voiPeople: Array<VOIPersonModel>;
};

export const DEFAULT_SIGNERS_VALUES: SignersModel = {
  signersList: [],
  voiType: -1,
  voiPeople: []
};

export const MOCK_UP_SIGNERS_VALUES: SignersModel = {
  signersList: [
    {
      existingOrNew: ADD_NEW,
      firstName: 'Juraj',
      lastName: 'Nagy',
      jobTitle: 'Secretary',
      email: 'juraj@nagy.com',
      mobileNumber: '0462637464',
      canSignTrustAccounts: [],
      allowedJurisdictionsForDocumentSigning: ['NSW']
    },
    {
      existingOrNew: 'intro.registeredPerson',
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      mobileNumber: '',
      canSignTrustAccounts: [],
      allowedJurisdictionsForDocumentSigning: ['NSW']
    },
    {
      existingOrNew: 'organisationDetails.participationAgreement.agreementSigners[1]',
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      mobileNumber: '',
      canSignTrustAccounts: [],
      allowedJurisdictionsForDocumentSigning: []
    }
  ],
  voiType: -1,
  voiPeople: []
};
