import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[100],
      padding: '24px 20px 10px 20px',
      marginTop: 36,
      marginBottom: 36
    },
    abn: {
      paddingRight: 29,
      width: 240,
      marginBottom: 0,
      '& > button': {
        margin: '20px 20px 0 0'
      }
    },
    companyName: {
      flexGrow: 1,
      marginBottom: 0
    },
    error: {
      color: '#ff1744' // TODO reference this from theme
    },
    endButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: -1
    },
    line: {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      paddingBottom: 16
    },
    tradingName: {
      width: '100%',
      marginBottom: 0
    },
    verifyInputAdornmentEnd: {
      marginRight: 0
    }
  });

export type ClassKeys = typeof styles;

export default styles;
