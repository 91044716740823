import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    noScroll: {
      overflowY: 'hidden'
    },
    subTitle: {
      paddingBottom: 16,
      borderBottom: '1px solid #D0D0D0'
    },
    body: {
      paddingTop: 80,
      height: '100%',
      boxSizing: 'border-box'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
