import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    subTitle: {
      marginBottom: 24,
      paddingBottom: 13,
      borderBottom: '1px solid #D0D0D0',
      fontWeight: 'bold'
    },
    jurisdiction: {},
    wrapperWithBottomBorder: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey['300']}`
    },
    fullWidth: {
      width: '100%',
      marginBottom: 8
    },
    fitContent: {
      height: 'fit-content'
    },
    marginTop: {
      marginTop: 40
    },
    autoWidth: {
      maxWidth: 'none'
    },
    bold: {
      fontWeight: 'bold'
    },
    subAgentCheckBox: {
      marginTop: 10
    }
  });

export type ClassKeys = typeof styles;

export default styles;
