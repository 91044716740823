import * as yup from 'yup';

import { DocumentGroupEnum } from 'src/models';

export const validationSchema = yup.object({
  [DocumentGroupEnum.PrincipalPracticingCertificate]: yup.array().of(
    yup.object({
      groupName: yup.string(),
      fileNames: yup.array().min(1, 'This Certificate document is required')
    })
  ),
  [DocumentGroupEnum.EvidenceOfIdentity]: yup.object({
    groupName: yup.string(),
    fileNames: yup.array().min(1, 'Evidence Of Identity document is required')
  })
});
