import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    deleteButton: {
      position: 'absolute',
      left: theme.spacing(-3),
      top: theme.spacing(2.5),
      height: 'auto',
      width: 'auto'
    },
    marginBottom: {
      marginBottom: 24
    },
    lineItem: {
      position: 'relative',
      '& + $lineItem': {
        marginTop: theme.spacing(1.5)
      }
    },
    fullWidth: {
      width: '100%'
    },
    spaceBetween: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1
    },
    dialogContainer: {},
    paper: {
      width: 740
    },
    dialogTitle: {
      paddingBottom: 8,
      borderBottom: '1px solid',
      borderColor: theme.palette.divider
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 40,
      height: 40
    },
    closeIcon: {
      fontSize: 20
    },
    subTitle: {
      paddingBottom: 13,
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: 36
    }
  });

export type ClassKeys = typeof styles;

export default styles;
