import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    spaceBetween: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1
    },
    deleteButton: {
      position: 'absolute',
      left: theme.spacing(-3),
      top: theme.spacing(2.5),
      height: 'auto',
      width: 'auto'
    },
    bottomBorder: {
      borderBottom: `1px dotted ${theme.palette.grey['300']}`
    },
    lineItem: {
      position: 'relative',
      '& + $lineItem': {
        marginTop: theme.spacing(1.5)
      }
    },
    fullWidth: {
      width: '100%'
    },
    deleteIcon: {
      fill: theme.palette.primary.main
    }
  });

export type ClassKeys = typeof styles;

export default styles;
