import * as React from 'react';

export default (props: React.SVGProps<any>) => (
  <svg width="130" height="130" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="02-//-EXTERNAL-ONBOARDING-v6" stroke="none" fill="none">
      <g id="Onboarding-//-Step-7C---warning-message" transform="translate(-575.000000, -368.000000)">
        <g id="illo-send-application" transform="translate(575.000000, 368.000000)">
          <g id="Group" fill="#F2F2F2">
            <g id="SVGID_1_-link">
              <circle id="SVGID_1_" cx="65" cy="65" r="65" />
            </g>
            <circle id="Oval" cx="65" cy="65" r="65" />
            <g id="SVGID_3_-link">
              <circle id="SVGID_3_" cx="65" cy="65" r="65" />
            </g>
          </g>
          <g id="Group" transform="translate(15.785714, 26.928571)">
            <g transform="translate(20.428571, 0.000000)" id="Rectangle">
              <g opacity="0.1" transform="translate(1.857143, 2.785714)" fill="#000000">
                <rect x="0.742857143" y="0.185714286" width="51.5357143" height="71.0357143" />
                <rect x="7.24285714" y="7.8" width="17.2714286" height="3.71428571" />
                <rect x="7.24285714" y="17.9214286" width="38.7214286" height="2.78571429" />
                <rect x="7.24285714" y="22.5642857" width="30.6428571" height="2.78571429" />
                <rect x="7.24285714" y="30.3642857" width="38.7214286" height="2.78571429" />
                <rect x="7.24285714" y="35.0071429" width="30.6428571" height="2.78571429" />
                <rect x="7.24285714" y="42.8071429" width="38.7214286" height="2.78571429" />
                <rect x="7.24285714" y="47.45" width="30.6428571" height="2.78571429" />
                <rect x="7.24285714" y="55.25" width="38.7214286" height="2.78571429" />
                <rect x="7.24285714" y="59.9857143" width="30.6428571" height="2.78571429" />
              </g>
              <g>
                <rect fill="#FFFFFF" x="0.464285714" y="0.835714286" width="51.5357143" height="71.0357143" />
                <rect fill="#888888" x="6.96428571" y="8.35714286" width="17.2714286" height="3.71428571" />
                <g transform="translate(6.500000, 17.642857)" fill="#888888">
                  <rect x="0.464285714" y="0.835714286" width="38.7214286" height="2.78571429" />
                  <rect x="0.464285714" y="5.57142857" width="30.6428571" height="2.78571429" />
                </g>
                <g transform="translate(6.500000, 30.642857)" fill="#888888">
                  <rect x="0.464285714" y="0.371428571" width="38.7214286" height="2.78571429" />
                  <rect x="0.464285714" y="5.01428571" width="30.6428571" height="2.78571429" />
                </g>
                <g transform="translate(6.500000, 42.714286)" fill="#888888">
                  <rect x="0.464285714" y="0.742857143" width="38.7214286" height="2.78571429" />
                  <rect x="0.464285714" y="5.38571429" width="30.6428571" height="2.78571429" />
                </g>
                <g transform="translate(6.500000, 55.714286)" fill="#888888">
                  <rect x="0.464285714" y="0.185714286" width="38.7214286" height="2.78571429" />
                  <rect x="0.464285714" y="4.82857143" width="30.6428571" height="2.78571429" />
                </g>
              </g>
            </g>
            <g transform="translate(0.000000, 44.571429)" id="Path">
              <path
                d="M18.9428571,36.4 C18.9428571,36.4 25.9071429,31.0142857 26.1857143,23.1214286 C26.1857143,22.0071429 25.4428571,19.2214286 27.3,15.4142857 C28.3214286,13.3714286 30.0857143,9.75 31.4785714,6.87142857 C32.5928571,4.55 31.5714286,1.67142857 29.1571429,0.65 C28.2285714,0.278571429 27.2071429,0.742857143 26.7428571,1.67142857 C25.4428571,4.82857143 22.5642857,11.5142857 20.8928571,14.3 L20.8928571,0.371428571 L19.6857143,0.371428571 C18.9428571,0.371428571 18.2,0.742857143 17.7357143,1.3 C16.5285714,2.78571429 14.2071429,5.85 12.1642857,8.82142857 C10.3071429,11.5142857 9.1,14.6714286 8.63571429,17.9214286 C7.89285714,22.4714286 6.77857143,29.5285714 6.68571429,30.8285714 C6.59285714,32.8714286 18.9428571,36.4 18.9428571,36.4 Z"
                fill="#EFCA9B"
              />
              <polygon fill="#FFFFFF" points="3.15714286 32.8714286 20.3357143 40.0214286 22.3785714 35.9357143 5.10714286 28.7857143" />
              <path
                d="M0.464285714,36.4928571 C5.10714286,41.7857143 10.5857143,46.2428571 16.7142857,49.7714286 L16.9928571,49.1214286 L21.45,39.5571429 L2.78571429,31.7571429 L0.464285714,36.4928571 Z"
                fill="#53BBB3"
              />
            </g>
          </g>
          <g id="Group" transform="translate(85.428571, 12.071429)" fill="#FF811A">
            <path
              d="M18.5714286,20.4285714 L8.72857143,20.4285714 C7.98571429,20.4285714 7.42857143,19.8714286 7.42857143,19.1285714 C7.42857143,18.3857143 7.98571429,17.8285714 8.72857143,17.8285714 L18.5714286,17.8285714 C19.3142857,17.8285714 19.8714286,18.3857143 19.8714286,19.1285714 C19.8714286,19.8714286 19.3142857,20.4285714 18.5714286,20.4285714 Z"
              id="Path"
            />
            <path
              d="M2.6,1.85714286 L2.6,11.7 C2.6,12.4428571 2.04285714,13 1.3,13 C0.557142857,13 -1.42108547e-14,12.4428571 -1.42108547e-14,11.7 L-1.42108547e-14,1.85714286 C-1.42108547e-14,1.11428571 0.557142857,0.557142857 1.3,0.557142857 C2.04285714,0.557142857 2.6,1.11428571 2.6,1.85714286 Z"
              id="Path"
            />
            <path
              d="M16.0642857,6.22142857 L7.8,14.4857143 C7.33571429,14.95 6.5,14.95 5.94285714,14.4857143 C5.47857143,14.0214286 5.47857143,13.1857143 5.94285714,12.6285714 L14.2071429,4.36428571 C14.6714286,3.9 15.5071429,3.9 16.0642857,4.36428571 C16.6214286,4.82857143 16.6214286,5.66428571 16.0642857,6.22142857 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
