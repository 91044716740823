import * as React from 'react';

export default (props: React.SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 150 150" width="130" height="130" {...props}>
    <g xmlns="http://www.w3.org/2000/svg" id="02-//-EXTERNAL-ONBOARDING-v6" stroke="none" fill="none">
      <g id="Onboarding-//-Progress-2---under-review" transform="translate(-415.000000, -283.000000)">
        <g id="externalOnboarding-UnderReview" transform="translate(415.000000, 283.000000)">
          <circle id="Oval" fill="#F2F2F2" cx="65" cy="65" r="65" />
          <g id="Group" transform="translate(21.357143, 14.857143)">
            <path
              d="M69.2714286,80.4142857 L19.3142857,80.4142857 C18.4785714,80.4142857 17.8285714,79.7642857 17.8285714,78.9285714 L17.8285714,11.4214286 C17.8285714,10.5857143 18.4785714,9.93571429 19.3142857,9.93571429 L69.2714286,9.93571429 C70.1071429,9.93571429 70.7571429,10.5857143 70.7571429,11.4214286 L70.7571429,78.9285714 C70.7571429,79.7642857 70.1071429,80.4142857 69.2714286,80.4142857 Z"
              id="Shape"
              fill="#222222"
            />
            <polygon id="Rectangle-path" fill="#FFFFFF" points="20.5214286 12.35 68.1571429 12.35 68.1571429 78 20.5214286 78" />
            <path
              d="M54.7857143,13.8357143 L33.8,13.8357143 L33.8,9.75 C33.8,7.89285714 35.2857143,6.40714286 37.1428571,6.40714286 L51.4428571,6.40714286 C53.3,6.40714286 54.7857143,7.89285714 54.7857143,9.75 L54.7857143,13.8357143 Z"
              id="Shape"
              fill="#53BBB3"
            />
            <path
              d="M44.2928571,0 C41.9714286,0 40.2071429,1.85714286 40.2071429,4.17857143 C40.2071429,6.5 42.0642857,8.26428571 44.3857143,8.26428571 C46.7071429,8.26428571 48.4714286,6.40714286 48.4714286,4.08571429 C48.4714286,1.76428571 46.5214286,0 44.2928571,0 Z M44.2928571,5.94285714 C43.2714286,5.94285714 42.5285714,5.10714286 42.5285714,4.17857143 C42.5285714,3.25 43.3642857,2.41428571 44.2928571,2.41428571 C45.2214286,2.41428571 46.0571429,3.25 46.0571429,4.17857143 C46.0571429,5.10714286 45.3142857,5.94285714 44.2928571,5.94285714 Z"
              id="Shape"
              fill="#53BBB3"
            />
            <polygon id="Rectangle-path" fill="#2F9E8E" points="33.8928571 12.2571429 54.7857143 12.2571429 54.7857143 13.9285714 33.8928571 13.9285714" />
            <polygon id="Rectangle-path" fill="#888888" points="26.4642857 19.3142857 61.8428571 19.3142857 61.8428571 22.75 26.4642857 22.75" />
            <g transform="translate(37.142857, 27.857143)" fill="#888888" id="Rectangle-path">
              <polygon points="0.371428571 0.835714286 26.65 0.835714286 26.65 3.34285714 0.371428571 3.34285714" />
              <polygon points="0.371428571 5.2 21.1714286 5.2 21.1714286 7.70714286 0.371428571 7.70714286" />
            </g>
            <g transform="translate(37.142857, 39.928571)" fill="#888888" id="Rectangle-path">
              <polygon points="0.371428571 0.278571429 26.65 0.278571429 26.65 2.78571429 0.371428571 2.78571429" />
              <polygon points="0.371428571 4.64285714 21.1714286 4.64285714 21.1714286 7.15 0.371428571 7.15" />
            </g>
            <g transform="translate(37.142857, 51.071429)" fill="#888888" id="Rectangle-path">
              <polygon points="0.371428571 0.65 26.65 0.65 26.65 3.15714286 0.371428571 3.15714286" />
              <polygon points="0.371428571 5.01428571 21.1714286 5.01428571 21.1714286 7.52142857 0.371428571 7.52142857" />
            </g>
            <g transform="translate(37.142857, 63.142857)" fill="#888888" id="Rectangle-path">
              <polygon points="0.371428571 0.0928571429 26.65 0.0928571429 26.65 2.6 0.371428571 2.6" />
              <polygon points="0.371428571 4.45714286 21.1714286 4.45714286 21.1714286 6.96428571 0.371428571 6.96428571" />
            </g>
            <path
              d="M26.1857143,44.3857143 L28.0428571,46.3357143 C28.2285714,46.5214286 28.5071429,46.5214286 28.6928571,46.3357143 L32.6857143,42.9 C32.9642857,42.7142857 32.8714286,42.25 32.5928571,42.1571429 L31.6642857,41.7857143 C31.4785714,41.6928571 31.2928571,41.7857143 31.2,41.8785714 L28.6928571,44.1071429 L27.4857143,43.1785714 C27.3,43.0857143 27.1142857,43.0857143 26.9285714,43.1785714 L26.2785714,43.7357143 C26,43.9214286 26,44.2 26.1857143,44.3857143 Z"
              id="Shape"
              fill="#F08026"
            />
            <g transform="translate(0.000000, 52.928571)" id="Shape">
              <path
                d="M15.7857143,36.4 C15.7857143,36.4 22.75,31.0142857 23.0285714,23.1214286 C23.0285714,22.0071429 22.2857143,19.2214286 24.1428571,15.4142857 C25.1642857,13.3714286 26.9285714,9.75 28.3214286,6.87142857 C29.4357143,4.55 28.4142857,1.67142857 26,0.65 C25.0714286,0.278571429 24.05,0.742857143 23.5857143,1.67142857 C22.2857143,4.82857143 19.4071429,11.5142857 17.7357143,14.3 L17.7357143,0.371428571 L16.5285714,0.371428571 C15.7857143,0.371428571 15.0428571,0.742857143 14.5785714,1.3 C13.3714286,2.78571429 11.05,5.85 9.00714286,8.82142857 C7.15,11.5142857 5.94285714,14.6714286 5.47857143,17.9214286 C4.73571429,22.4714286 3.62142857,29.5285714 3.52857143,30.8285714 C3.43571429,32.8714286 15.7857143,36.4 15.7857143,36.4 Z"
                fill="#EFCA9B"
              />
              <polygon fill="#FFFFFF" points="0.0928571429 32.8714286 17.2714286 40.0214286 19.2214286 35.9357143 2.04285714 28.7857143" />
            </g>
            <path
              d="M84.3142857,83.85 C84.4071429,85.8928571 72.0571429,89.3285714 72.0571429,89.3285714 C72.0571429,89.3285714 65.8357143,84.5 64.9071429,77.4428571 C64.8142857,76.9785714 65.0928571,76.4214286 65.5571429,76.2357143 C66.8571429,75.4928571 67.7857143,74.2857143 68.3428571,72.8 C67.6928571,72.15 67.0428571,71.4071429 66.95,71.3142857 C66.8571429,71.1285714 68.9928571,66.8571429 68.9928571,66.8571429 L69.4571429,67.0428571 L70.0142857,67.2285714 C70.0142857,67.3214286 70.1071429,67.3214286 70.1071429,67.4142857 L70.1071429,53.7642857 C70.1071429,53.5785714 70.2928571,53.3 70.4785714,53.3 L71.2214286,53.3 C71.9642857,53.3 72.7071429,53.6714286 73.2642857,54.3214286 C74.4714286,55.8071429 76.7928571,58.8714286 78.7428571,61.75 C80.6,64.5357143 81.8071429,67.6 82.3642857,70.85 C83.1071429,75.4 84.3142857,82.4571429 84.3142857,83.85 Z"
              id="Shape"
              fill="#EFCA9B"
            />
            <path
              d="M70.0142857,67.1357143 L69.4571429,66.95 C69.4571429,66.6714286 69.4571429,66.4857143 69.4571429,66.2071429 C69.6428571,66.5785714 69.8285714,66.8571429 70.0142857,67.1357143 Z"
              id="Shape"
              fill="#E6BE93"
            />
            <polygon id="Shape" fill="#FFFFFF" points="87.8428571 85.8 70.6642857 92.95 68.6214286 88.8642857 85.8928571 81.7142857" />
            <path
              d="M83.5714286,70.85 L83.2,72.0571429 C83.1071429,72.4285714 82.7357143,72.6142857 82.3642857,72.4285714 L70.9428571,68.3428571 C70.85,68.3428571 70.7571429,68.1571429 70.7571429,68.0642857 C70.7571429,67.9714286 70.9428571,67.8785714 71.0357143,67.8785714 L82.1785714,71.7785714 C82.4571429,71.8714286 82.7357143,71.6857143 82.8285714,71.5 L83.1071429,70.6642857 L83.5714286,70.85 Z"
              id="Shape"
              fill="#222222"
            />
            <g transform="translate(70.571429, 66.857143)" fill="#222222" id="Shape">
              <path d="M2.97142857,2.22857143 L0.742857143,1.48571429 C0.742857143,1.48571429 1.20714286,0.835714286 2.13571429,1.11428571 C2.97142857,1.39285714 2.97142857,2.22857143 2.97142857,2.22857143 Z" />
              <path d="M0.65,1.39285714 C0.65,1.39285714 1.11428571,0.65 2.04285714,1.02142857 C2.97142857,1.3 2.97142857,2.13571429 2.97142857,2.13571429 L0.65,1.39285714 Z M2.04285714,1.11428571 C1.3,0.835714286 0.835714286,1.3 0.742857143,1.48571429 L2.97142857,2.22857143 C2.87857143,2.04285714 2.87857143,1.39285714 2.04285714,1.11428571 Z" />
            </g>
            <path
              d="M83.2928571,71.4071429 L56.55,62.4 C56.1785714,62.3071429 55.7142857,62.1214286 55.3428571,61.9357143 L55.1571429,61.8428571 C52.8357143,60.8214286 50.5142857,59.8 48.2857143,58.5928571 L48.5642857,57.85 L52.2785714,58.5 C53.3,58.6857143 54.3214286,58.8714286 55.25,59.0571429 L56.2714286,59.2428571 C56.7357143,59.3357143 57.1071429,59.4285714 57.5714286,59.6142857 L84.4071429,68.5285714 C85.0571429,68.7142857 85.4285714,69.4571429 85.2428571,70.1071429 L85.15,70.4785714 C84.6857143,71.3142857 83.9428571,71.6857143 83.2928571,71.4071429 Z"
              id="Shape"
              fill="#222222"
            />
            <polygon
              id="Rectangle-path"
              fill="#222222"
              transform="translate(73.893871, 66.767457) rotate(-71.644775) translate(-73.893871, -66.767457) "
              points="72.4546193 66.303182 75.3331228 66.303182 75.3331228 66.8603117 72.4546193 66.8603117"
            />
            <g transform="translate(82.642857, 67.785714)" fill="#222222" id="Shape">
              <path d="M1.20714286,0.65 L1.57857143,0.742857143 C1.67142857,0.742857143 1.67142857,0.835714286 1.76428571,0.835714286 L0.835714286,3.62142857 C0.742857143,3.62142857 0.65,3.62142857 0.65,3.52857143 L0.278571429,3.43571429 L1.20714286,0.65 Z" />
              <path d="M1.20714286,0.65 L1.57857143,0.742857143 C1.67142857,0.742857143 1.67142857,0.835714286 1.76428571,0.835714286 C1.76428571,0.835714286 1.76428571,0.835714286 1.76428571,0.835714286 L0.835714286,3.62142857 C0.742857143,3.62142857 0.65,3.62142857 0.65,3.52857143 L0.278571429,3.43571429 L1.20714286,0.65 Z M1.76428571,0.928571429 C1.76428571,0.928571429 1.76428571,0.928571429 1.76428571,0.928571429 C1.67142857,0.928571429 1.67142857,0.835714286 1.57857143,0.835714286 L1.20714286,0.742857143 L0.278571429,3.52857143 L0.65,3.62142857 C0.742857143,3.62142857 0.835714286,3.62142857 0.835714286,3.71428571 L1.76428571,0.928571429 Z" />
            </g>
            <g transform="translate(47.357143, 57.571429)" fill="#222222" id="Shape">
              <path d="M0.0928571429,0.371428571 C0.0928571429,0.464285714 0.0928571429,0.557142857 0.185714286,0.557142857 L1.39285714,0.928571429 C1.48571429,0.928571429 1.57857143,0.928571429 1.57857143,0.835714286 C1.57857143,0.742857143 1.57857143,0.65 1.48571429,0.65 L0.278571429,0.278571429 C0.185714286,0.278571429 0.0928571429,0.371428571 0.0928571429,0.371428571 Z" />
              <path d="M5.94285714,3.52857143 C4.27142857,2.78571429 2.50714286,1.95 0.835714286,1.02142857 L1.11428571,0.278571429 L4.82857143,0.928571429 C5.47857143,1.02142857 6.12857143,1.20714286 6.77857143,1.3 L5.94285714,3.52857143 Z" />
            </g>
            <path
              d="M68.9928571,66.7642857 C67.1357143,63.7928571 64.2571429,58.3142857 62.8642857,55.6214286 C62.4,54.6928571 61.2857143,54.4142857 60.45,54.7857143 C58.1285714,55.9928571 57.2,58.8714286 58.5,61.1 C60.0785714,63.8857143 62.1214286,67.3214286 63.2357143,69.2714286 C65.3714286,72.8928571 64.8142857,75.7714286 64.9071429,76.8857143 C64.9071429,76.8857143 64.9071429,76.8857143 64.9071429,76.8857143 C64.9071429,77.0714286 65.1857143,77.1642857 65.2785714,77.0714286 C68.3428571,75.5857143 69.0857143,71.4071429 68.9928571,66.7642857 Z"
              id="Shape"
              fill="#EFCA9B"
            />
            <path
              d="M70.2,53.8571429 C70.1071429,53.6714286 69.8285714,53.5785714 69.7357143,53.7642857 C67.9714286,55.7142857 59.7071429,64.9071429 59.8928571,70.9428571 C60.0785714,77.7214286 72.2428571,59.6142857 72.2428571,59.6142857 L70.2,53.8571429 Z"
              id="Shape"
              fill="#EFCA9B"
            />
            <path
              d="M70.1071429,67.1357143 L70.1071429,67.2285714 C70.0142857,67.2285714 70.0142857,67.1357143 70.1071429,67.1357143 Z"
              id="Shape"
              fill="#E6BE93"
            />
            <path
              d="M26.1857143,33.3357143 L28.0428571,35.2857143 C28.2285714,35.4714286 28.5071429,35.4714286 28.6928571,35.2857143 L32.6857143,31.85 C32.9642857,31.6642857 32.8714286,31.2 32.5928571,31.1071429 L31.6642857,30.7357143 C31.4785714,30.6428571 31.2928571,30.7357143 31.2,30.8285714 L28.6928571,33.0571429 L27.4857143,32.1285714 C27.3,32.0357143 27.1142857,32.0357143 26.9285714,32.1285714 L26.2785714,32.6857143 C26,32.7785714 26,33.15 26.1857143,33.3357143 Z"
              id="Shape"
              fill="#F08026"
            />
          </g>
          <circle id="Oval" cx="65" cy="65" r="65" />
          <path
            d="M90.8142857,107.342857 L95.2714286,116.907143 L97.2214286,121.457143 C103.35,117.928571 108.921429,113.378571 113.564286,108.178571 L109.571429,99.6357143 L90.8142857,107.342857 Z"
            id="Shape"
            fill="#53BBB3"
          />
          <path
            d="M16.7142857,108.55 C21.45,113.75 27.0214286,118.207143 33.15,121.642857 L35.1928571,116.814286 L39.65,107.25 L20.8928571,99.45 L16.7142857,108.55 Z"
            id="Shape"
            fill="#53BBB3"
          />
        </g>
      </g>
    </g>
  </svg>
);
