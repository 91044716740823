import * as React from 'react';

import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { AccountDetailsModel, PersonModel } from 'src/models';
import { nameLineWithJobTitle } from 'src/utils/formatters';

import LineItem from '../line-item';
import styles, { ClassKeys } from './styles';

interface ReviewBankAccountProps {
  account: {
    accountDetails: AccountDetailsModel;
    accountHolders: Array<PersonModel>;
  };
}

type Props = ReviewBankAccountProps & WithStyles<ClassKeys>;
class ReviewBankAccount extends React.PureComponent<Props> {
  render() {
    const { classes, account } = this.props;

    return (
      <React.Fragment>
        <Typography className={classNames(classes.marginBase, classes.bold)}>{account.accountDetails.accountName}</Typography>
        <LineItem title="BSB:">
          <Typography>{account.accountDetails.bsbNumber}</Typography>
        </LineItem>
        <LineItem title="Bank name:">
          <Typography>{account.accountDetails.bankName}</Typography>
        </LineItem>
        <LineItem title="Account number:">
          <Typography>{account.accountDetails.accountNumber}</Typography>
        </LineItem>
        {account.accountHolders.map((accountHolder, idx, holders) => {
          return (
            <LineItem title={`Account holder${holders.length > 1 ? ` ${idx + 1}` : ''}:`} key={'directdebitholder' + idx}>
              <Typography>
                {nameLineWithJobTitle(accountHolder)}
                <br />
                {accountHolder.mobileNumber}&nbsp;|&nbsp;{accountHolder.email}
              </Typography>
            </LineItem>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewBankAccount);
