import * as React from 'react';

import { MessageNotification } from '@sympli/ui-framework/components/message-notification';

interface OwnProps {
  open: boolean;
  children: JSX.Element | string;
}

type Props = OwnProps;

const ApplicationWarning = ({ open, children }: Props) => {
  return <MessageNotification variant="warning" open={open} primary={children} />;
};

export default ApplicationWarning;
