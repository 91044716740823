import {
  AddressEntityModel,
  AddressStoreModel,
  AddressTypeEnum,
  DEFAULT_ADDRESS_ENTITY_ITEM,
  ReferenceStoreModel
} from '@sympli/ui-framework/components/formik/address-field/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { DistributiveOmit } from '@mui/types';

import { OnboardingDetailsModel, OnboardingFormStepsEnum } from 'src/containers/onboarding/views/onboarding-detail/models';
import { DirectDebitAccountModel } from 'src/containers/onboarding/views/onboarding-detail/steps/direct-debit-account/models';
import { LandRegistryModel, StateLandRegistryEntityModel } from 'src/containers/onboarding/views/onboarding-detail/steps/land-registry/models';
import { OrganisationDetailsModel } from 'src/containers/onboarding/views/onboarding-detail/steps/organisation-details/models';
import { ReviewModel } from 'src/containers/onboarding/views/onboarding-detail/steps/review-registration/models';
import { SignerEntityModel, SignersModel } from 'src/containers/onboarding/views/onboarding-detail/steps/signers/models';
import { TrustAccountModel } from 'src/containers/onboarding/views/onboarding-detail/steps/trust-account/models';
import { PersonModel, ReferencedObject, VOIPersonModel, VOITypeEnum } from 'src/models';

// TODO JUN remove
export const emptyId = '00000000-0000-0000-0000-000000000000';

let tmpAddressList: Array<ApiAddressModel> = [];

type ApiAddressModel = AddressEntityModel & ReferencedObject;

type ApiOrganisationDetailsModel = DistributiveOmit<OrganisationDetailsModel, 'registeredOffice' | 'principalPlaceOfBusiness'> & {
  registeredOffice: {
    address: AddressEntityModel & ReferencedObject;
  };
  principalPlaceOfBusiness: {
    isSameAsRegisteredOffice: boolean;
    address: AddressEntityModel & ReferencedObject;
  };
};

interface ApiLandRegistryModel {
  entityName: string;
  businessName: string;
  states: Array<
    DistributiveOmit<StateLandRegistryEntityModel, 'financialCorrespondence' | 'lodgementCorrespondence'> & {
      financialCorrespondence: {
        contactPerson: PersonModel;
        address: AddressEntityModel & ReferencedObject;
      };
      lodgementCorrespondence: {
        contactPerson: PersonModel;
        address: AddressEntityModel & ReferencedObject;
      };
    }
  >;
}

// Dirty functions for data transformation
interface ApiGetOnboardDetailsModel {
  organisationDetails: ApiOrganisationDetailsModel;
  landRegistry: ApiLandRegistryModel;
  directDebitAccount: DirectDebitAccountModel;
  trustAccount: TrustAccountModel;
  signers: Array<SignerEntityModel>;
  review: {
    voiPeople: Array<VOIPersonModel>;
    voiType: VOITypeEnum;
    agreeAcknowledgement: boolean;
  };
  step: OnboardingFormStepsEnum;
}

interface ApiSubmitOnboardModel extends ApiGetOnboardDetailsModel {
  // Needed for submit
  applicant?: PersonModel;
}

// making data structure easy to use by frontend(formik)
export function apiDetailToOnboardingDetail(apiRes: {
  details: ApiGetOnboardDetailsModel;
  step: OnboardingFormStepsEnum;
  applicant: PersonModel;
}): OnboardingDetailsModel {
  const { details: apiDetail, applicant } = apiRes;

  const intro = { registeredPerson: applicant };
  const organisationDetails = convertToOnboardingOrganisationDetail(apiDetail.organisationDetails);
  const landRegistry = convertToOnboardingLandRegistry(apiDetail.landRegistry);
  const { signers, review } = convertToOnboardingVOI(apiDetail);
  const step = apiRes.step;
  const addressStore = getStoreFromList<AddressEntityModel>(tmpAddressList);
  tmpAddressList = [];
  return { ...apiDetail, organisationDetails, landRegistry, signers, review, step, intro, addressStore };
}

function convertToOnboardingOrganisationDetail(organisationDetails: ApiOrganisationDetailsModel): OrganisationDetailsModel {
  const {
    principalPlaceOfBusiness: { isSameAsRegisteredOffice, address: businessAddress },
    registeredOffice: { address: registeredAddress }
  } = organisationDetails;
  const businessAddressId = businessAddress.id === emptyId || !businessAddress ? '' : businessAddress.id;
  const registeredAddressId = registeredAddress.id === emptyId || !registeredAddress ? '' : registeredAddress.id;
  tmpAddressList.push(businessAddress);
  tmpAddressList.push(registeredAddress);
  return {
    ...organisationDetails,
    registeredOffice: {
      addressId: registeredAddressId
    },
    principalPlaceOfBusiness: {
      isSameAsRegisteredOffice,
      addressId: businessAddressId
    }
  };
}

function convertToOnboardingLandRegistry(apiLandRegistry: ApiLandRegistryModel): LandRegistryModel {
  return {
    ...apiLandRegistry,
    states: apiLandRegistry.states.map(stateDetail => {
      const {
        id,
        financialCorrespondence: { contactPerson: finContactPerson, address: finAddress },
        lodgementCorrespondence: { contactPerson: logContactPerson, address: logAddress },
        stateRevenueCustomerId = ''
      } = stateDetail;
      const finAddressId = finAddress.id === emptyId || !finAddress ? '' : finAddress.id;
      const logAddressId = logAddress.id === emptyId || !logAddress ? '' : logAddress.id;
      tmpAddressList.push(finAddress);
      tmpAddressList.push(logAddress);
      return {
        id,
        financialCorrespondence: { contactPerson: finContactPerson, addressId: finAddressId },
        lodgementCorrespondence: { contactPerson: logContactPerson, addressId: logAddressId },
        stateRevenueCustomerId
      };
    })
  };
}

function convertToOnboardingVOI(apiDetail: ApiGetOnboardDetailsModel): { signers: SignersModel; review: ReviewModel } {
  const signers: SignersModel = {
    signersList: apiDetail.signers,
    voiPeople: apiDetail.review.voiPeople,
    voiType: apiDetail.review.voiType
  };
  const review: ReviewModel = {
    agreeAcknowledgement: new Array(4).fill(apiDetail.review.agreeAcknowledgement)
  };
  return { signers, review };
}

export function onboardingDetailToApiDetail(onboardingDetail: OnboardingDetailsModel): ApiSubmitOnboardModel {
  // From intro.registeredPerson to applicant
  const applicant = onboardingDetail.intro.registeredPerson;
  const organisationDetails = convertToApiOrganisationDetails(onboardingDetail.organisationDetails, onboardingDetail.addressStore);
  const landRegistry = convertToApiLandRegistryDetail(onboardingDetail.landRegistry, onboardingDetail.addressStore);
  const { signers, review } = convertToApiVOI(onboardingDetail);

  // get rest property except intro
  const { intro, addressStore, ...rest } = onboardingDetail;

  // TODO tmp hide trust account, to be removed
  // ! hide trust Account for V1 WEB-2984
  // rest.trustAccount.isSympliSourceAccount = true;
  // TODO end

  return { ...rest, organisationDetails, landRegistry, signers, review, applicant };
}

function convertToApiOrganisationDetails(organisationDetails: OrganisationDetailsModel, addressStore: AddressStoreModel): ApiOrganisationDetailsModel {
  const {
    registeredOffice: { addressId: registeredAddressId },
    principalPlaceOfBusiness: { addressId: busAddressId },
    ...rest
  } = organisationDetails;
  const registeredAddress: ApiAddressModel = getApiAddress(addressStore, registeredAddressId);
  const busAddress: ApiAddressModel = getApiAddress(addressStore, busAddressId);
  return {
    ...rest,
    registeredOffice: {
      address: registeredAddress
    },
    principalPlaceOfBusiness: {
      isSameAsRegisteredOffice: false,
      address: busAddress
    }
  };
}

function convertToApiLandRegistryDetail(landRegistry: LandRegistryModel, addressStore: AddressStoreModel): ApiLandRegistryModel {
  return {
    ...landRegistry,
    states: landRegistry.states.map(stateDetail => {
      const {
        financialCorrespondence: { contactPerson: finContactPerson, addressId: finAddressId },
        lodgementCorrespondence: { contactPerson: logContactPerson, addressId: logAddressId },
        stateRevenueCustomerId
      } = stateDetail;

      const finAddress: ApiAddressModel = getApiAddress(addressStore, finAddressId);
      const busAddress: ApiAddressModel = getApiAddress(addressStore, logAddressId);
      return {
        id: stateDetail.id,
        financialCorrespondence: {
          contactPerson: finContactPerson,
          address: finAddress
        },
        lodgementCorrespondence: {
          contactPerson: logContactPerson,
          address: busAddress
        },
        stateRevenueCustomerId
      };
    })
  };
}

function convertToApiVOI(onboardingDetail: OnboardingDetailsModel) {
  const signers: Array<SignerEntityModel> = onboardingDetail.signers.signersList;
  const agreeAcknowledgement = onboardingDetail.review.agreeAcknowledgement.every(agree => agree === true);
  const review = {
    agreeAcknowledgement,
    voiPeople: onboardingDetail.signers.voiPeople as Array<VOIPersonModel>,
    voiType: onboardingDetail.signers.voiType as VOITypeEnum
  };
  return { signers, review };
}

// * Typescript cannot do advanced type induction here, just keep this function as it is
function getStoreFromList<E>(list: Array<ReferencedObject>): ReferenceStoreModel<E> {
  return list.reduce((resStore, refObj) => {
    const { id, ...entity } = refObj;
    if (id === emptyId || !id) {
      return resStore;
    }
    if (resStore[id]) {
      resStore[id].count++;
    } else {
      resStore[id] = {
        detail: entity,
        count: 1,
        id
      };
    }
    return resStore;
  }, {});
}

function getApiAddress(store: AddressStoreModel, id: string) {
  const emptyAddress = {
    id: '',
    ...DEFAULT_ADDRESS_ENTITY_ITEM,
    type: AddressTypeEnum.ApiNone
  } as ApiAddressModel;
  if (!id) {
    return emptyAddress;
  }
  const address = store[id];
  if (!address) {
    Logger.console(SeverityEnum.Warning, `id: ${id} does not exist in addressStore. Please check store:`, store);
    return emptyAddress;
  }
  return { id, ...address.detail };
}
