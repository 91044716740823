import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import { WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';

import logoWhite from './logo-white.svg';
import styles, { ClassKeys } from './styles';

interface OwnProps {}

type Props = OwnProps & WithStyles<ClassKeys>;

class MainAppBar extends React.PureComponent<Props> {
  public render() {
    const { classes } = this.props;

    return (
      <AppBar position="fixed" className={classes.root} color="inherit">
        <Toolbar>
          <a href="/#">
            <img className={classes.logo} alt="e-Settlements made simple" src={logoWhite} />
          </a>
          {this.renderHelp()}
        </Toolbar>
      </AppBar>
    );
  }

  protected renderHelp() {
    const { classes } = this.props;
    return (
      <FlexLayout className={classes.helpLink} alignItems="center" justifyContent="flex-end">
        Need help? Contact us on&nbsp;
        <span>1300 SYMPLI (1300 796 754)</span>
        &nbsp;or&nbsp;
        <span className={classes.underline}>help@sympli.com.au</span>
        <Icon className={classes.helpIcon}>help</Icon>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(MainAppBar);
