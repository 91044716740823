import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    subTitle: {
      paddingBottom: 13,
      borderBottom: '1px solid #D0D0D0',
      marginBottom: 36,
      position: 'relative',
      fontWeight: 'bold'
    },
    spaceBetween: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1
    },
    deleteButtonInSectionTitle: {
      position: 'absolute',
      left: theme.spacing(-3),
      top: 5,
      height: 'auto',
      width: 'auto'
    },
    addButtonInStepper: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 1,
      marginRight: 16
    },
    addButton: {
      // TODO use button in AddButton
      color: theme.palette.primary.main,
      border: '1px solid',
      padding: '5px 12px',
      fontSize: 16,
      textTransform: 'initial',
      '&:hover': {
        backgroundColor: 'rgba(24, 205, 182, 0.08)'
      }
    },
    bottomBorder: {
      borderBottom: `1px dotted ${theme.palette.grey['300']}`
    },
    lineItem: {
      position: 'relative',
      '& + $lineItem': {
        marginTop: theme.spacing(1.5)
      }
    },
    fullWidth: {
      width: '100%'
    },
    marginTop: {
      marginTop: 40
    },
    deleteIcon: {
      fill: theme.palette.primary.main
    }
  });

export type ClassKeys = typeof styles;

export default styles;
