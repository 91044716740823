import { combineReducers } from 'redux';

import { submitFormikReducer as submitFormik } from '@sympli/ui-framework/components/formik';

import application from './application';
import globalErrors from './globalErrors';

export type Store = ReturnType<ReturnType<typeof createRootReducer>>;

export const createRootReducer = () =>
  combineReducers({
    submitFormik,
    application,
    globalErrors
  });
