import * as React from 'react';

import { Action, Dispatch } from 'redux';

import { ICertInfo } from '@sympli/digital-signing/interfaces';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconCaretLeft } from '@sympli/ui-framework/icons';
import dateFormat from 'dateformat';

import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { updateCertificateValidationInfo } from 'src/actions/application';

import AyeOk from './components/icons/aye-ok';
import styles, { ClassKeys } from './styles';

interface CertificateSuccessfullyValidatedPageProps {
  dispatch: Dispatch<Action>;
  selectedCertificate: ICertInfo;
}

type Props = CertificateSuccessfullyValidatedPageProps & WithStyles<ClassKeys>;

class CertificateSuccessfullyValidatedPage extends React.PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        {this.renderBody()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }
  renderBody() {
    const { classes, selectedCertificate } = this.props;

    const issuer = selectedCertificate.issuer.name;
    const tokenType = `${selectedCertificate.tokenType} token`;
    const notAfter = selectedCertificate.notAfter ? `Expires on ${dateFormat(selectedCertificate.notAfter, `ddd d mmm yyyy, a't' h:MM TT`)}` : '';

    return (
      <React.Fragment>
        <FlexLayout flexDirection="column" alignItems="flex-end" className={classes.topBodyContainer}>
          <Box onClick={this.goBackToStart} className={classes.footerBackButtonContainer}>
            <Icon color="primary">refresh</Icon>
            <p>
              <b>
                <u>Start again</u>
              </b>
            </p>
          </Box>
        </FlexLayout>
        <FlexLayout flexDirection="column" alignItems="center" className={classes.bottomBodyContainer}>
          <AyeOk />
          <FlexLayout flexDirection="column" alignItems="center" className={classes.certificateDetailsBox}>
            <p>
              <b>Your certificate is supported by Sympli</b>
            </p>
          </FlexLayout>
          <FlexLayout flexDirection="column" alignItems="center" className={classes.certificateDetailsBox}>
            <p className={classes.certificateDetails}>
              <b>{issuer}</b>
            </p>
            <p className={classes.certificateDetails}>{tokenType}</p>
            <p className={classes.certificateDetails}>{notAfter}</p>
          </FlexLayout>
        </FlexLayout>
      </React.Fragment>
    );
  }
  private renderFooter() {
    const { classes } = this.props;

    return (
      <div className={classes.footerContainer}>
        <Box onClick={this.goBackToList} className={classes.footerBackButtonContainer}>
          <IconCaretLeft width="18" height="18" color="primary" />
          <p>
            <b>
              <u>Back</u>
            </b>
          </p>
        </Box>
      </div>
    );
  }

  private goBackToList = () => {
    const { dispatch } = this.props;
    dispatch(
      updateCertificateValidationInfo({
        selectedCertificate: null
      })
    );
  };

  private goBackToStart = () => {
    const { dispatch } = this.props;
    dispatch(
      updateCertificateValidationInfo({
        selectedCertificate: null,
        certificates: []
      })
    );
  };
}

export default withStyles(styles)(CertificateSuccessfullyValidatedPage);
