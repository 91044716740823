import * as React from 'react';

import { connect } from 'react-redux';

import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { Body, Box, Header, MainAppBar } from 'src/components/layout';
import { ConnectedDispatchProps } from 'src/models/redux';
import { SYMPLI_SIGNING_APPLICATION_LINK } from 'src/models/values';
import { Store } from 'src/reducers';
import { getGatekeepers } from 'src/utils/signing/signing';

import styles, { ClassKeys } from './styles';
import CertificateList from './views/certificate-list';
import CertificateSuccessfullyValidated from './views/certificate-successfully-validated';
import CertificateValidationDetail from './views/certificate-validation-detail';

// * A component act as a router
interface OwnProps {}

type ConnectedStateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & ConnectedStateProps & ConnectedDispatchProps & WithStyles<ClassKeys>;

interface State {
  gatekeepers: string[];
}

class CertificateValidationPage extends React.PureComponent<Props, State> {
  readonly state: State = {
    gatekeepers: []
  };
  public async componentDidMount() {
    try {
      const gatekeepers = await getGatekeepers();
      this.setState({
        gatekeepers: gatekeepers.accreditedGatekeepers
      });
    } catch (error) {
      // This is intentional to ignore this error
      // We'll send an empty list here and backend will
      // use their default list.
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MainAppBar />
        <div className={classes.summary}>{this.renderSummary()}</div>
        <Body
          mainBody={this.renderBody()}
          panelBody={this.renderPanel()}
          classes={{ panel: classes.panelOverrides, main: classes.bodyOverrides, content: classes.contentOverrides }}
        />
      </React.Fragment>
    );
  }

  renderBody() {
    const { dispatch, certificates, selectedCertificate, userInfo, logGroupId } = this.props;
    // step 1
    // first page where we ask user for their details in order to list certificates
    if (!certificates.length) {
      return (
        <CertificateValidationDetail //
          userInfo={userInfo}
          gatekeepers={this.state.gatekeepers}
          dispatch={dispatch}
        />
      );
    }

    // step 2
    // certificate was not selected yet
    if (!selectedCertificate) {
      return (
        <CertificateList //
          dispatch={dispatch}
          certificates={certificates}
          gatekeepers={this.state.gatekeepers}
          userInfo={userInfo}
          logGroupId={logGroupId}
        />
      );
    }
    // step 3
    return (
      <CertificateSuccessfullyValidated //
        dispatch={dispatch}
        selectedCertificate={selectedCertificate}
      />
    );
  }

  private renderSummary() {
    const { classes, selectedCertificate } = this.props;
    return (
      <React.Fragment>
        <Header className={classes.summaryTitle}>Digital certificate validation tool</Header>
        {!selectedCertificate && (
          <Typography variant="h2" className={classes.summarySubTitle}>
            As a signer on the Sympli platform you will need a valid digital certificate to digitally sign documents.
          </Typography>
        )}
      </React.Fragment>
    );
  }

  private renderPanel() {
    const { classes } = this.props;
    // const link = 'https://gatekeeper.digicert.com/repository/privacy_statement.pdf';
    return (
      <React.Fragment>
        <div className={classes.panelBackground}>
          <div className={classes.panelLeftRightPadding}>
            <Box className={classes.panelSubTitle}>
              <div>Install Sympli's signing applications</div>
            </Box>
            <div className={classes.panelBody}>
              Use our easy to follow steps to install the signing applications required to sign documents on the Sympli platform.
            </div>
            <div className={classes.panelButtonPadding}>
              <MuiButton href={SYMPLI_SIGNING_APPLICATION_LINK} target="_blank" rel="noopener noreferrer" color="primary" variant="outlined">
                Install now
              </MuiButton>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    logGroupId: state.application.logGroupId,
    certificates: state.application.certificates,
    selectedCertificate: state.application.selectedCertificate,
    userInfo: state.application.userInfo
  };
};

const StyledComponent = withStyles(styles)(CertificateValidationPage);

export default connect<ConnectedStateProps, ConnectedDispatchProps, OwnProps>(mapStateToProps)(StyledComponent);
