import * as React from 'react';

import { FormikErrors, FormikProps } from 'formik';

import ErrorBeforeSubmit from '../error-before-submit';
import { getFirstFormikErrorFieldName } from './helper';

interface ScrollToFormikErrorProps {
  formikProps: FormikProps<any>;
}

class ScrollToFormikError extends React.PureComponent<ScrollToFormikErrorProps> {
  render() {
    return <ErrorBeforeSubmit formikProps={this.props.formikProps} onErrorBeforeSubmit={this.ErrorBeforeSubmit} />;
  }

  private ErrorBeforeSubmit(formikErrors: FormikErrors<any>, formikProps: FormikProps<any>) {
    const firstErrorFieldName = getFirstFormikErrorFieldName(formikErrors);
    const errorElement = document.querySelector(`[data-name="${firstErrorFieldName}"]`);
    if (!!errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

export default ScrollToFormikError;
