import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      top: 40,
      left: 8,
      right: 8,
      zIndex: 1400,
      alignItems: 'center',
      justifyContent: 'center'
    },
    errorsContainer: {},
    warningsContainer: {
      marginTop: 15
    }
  });

export type ClassKeys = typeof styles;

export default styles;
