import * as React from 'react';

import { FormikErrors, FormikProps } from 'formik';

function formikJustStopSubmit(prevFormikProps: FormikProps<any>, thisFormikProps: FormikProps<any>) {
  // if form was submitting, but now is not submitting
  return prevFormikProps.isSubmitting && !thisFormikProps.isSubmitting;
}

function haveFormikErrors(formikProps: FormikProps<any>) {
  // ! do not use isValid flag, because for prepopulated forms, isValid flag is always set to false if its not touched
  return Object.keys(formikProps.errors).length !== 0;
}

interface ErrorBeforeSubmitProps {
  formikProps: FormikProps<any>;
  onErrorBeforeSubmit: (errors: FormikErrors<any>, formikProps: FormikProps<any>) => void;
}

class ErrorBeforeSubmit extends React.PureComponent<ErrorBeforeSubmitProps> {
  componentDidUpdate(prevProps: ErrorBeforeSubmitProps) {
    const { formikProps: prevFormikProps } = prevProps;
    const { formikProps: thisFormikProps } = this.props;
    if (formikJustStopSubmit(prevFormikProps, thisFormikProps) && haveFormikErrors(thisFormikProps)) {
      this.props.onErrorBeforeSubmit(thisFormikProps.errors, thisFormikProps);
    }
  }

  render() {
    return null;
  }
}

export default ErrorBeforeSubmit;
