import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      width: '100%',
      boxSizing: 'border-box',
      padding: 16,
      '& div': { color: 'grey', fontSize: 13 },
      '& div:first-child': { textAlign: 'left' },
      '& div:last-child': { textAlign: 'right' }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
