import * as React from 'react';

import { DirectDebitAccountModel } from '../../../direct-debit-account/models';
import ReviewBankAccount from '../review-bank-account';

interface ReviewDirectDebitAccountProps {
  directdebitAccount: DirectDebitAccountModel;
}

type Props = ReviewDirectDebitAccountProps;
class ReviewDirectDebitAccount extends React.PureComponent<Props> {
  render() {
    const { directdebitAccount } = this.props;
    return directdebitAccount.accounts.map((account, index) => <ReviewBankAccount key={account.accountDetails.accountName + index} account={account} />);
  }
}

export default ReviewDirectDebitAccount;
