import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  name: string;
  description: string;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const ProgressStepLabel = (props: Props) => {
  const { name, description, classes } = props;
  return (
    <div className={classes.progressStepLabel}>
      <div>
        <strong>{name}</strong>
      </div>
      {/* use &nbsp; (\u00A0) to take the empty line for default styling */}
      <div className={classes.progressStepDescription}>{description || '\u00A0'}</div>
    </div>
  );
};

export default withStyles(styles)(ProgressStepLabel);
