import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      fontSize: 14
    },
    firstColumn: {
      flexGrow: 1,
      borderRight: '1px solid #D0D0D0',
      paddingLeft: 8,
      paddingRight: 8,
      height: '100%'
    },
    secondColumn: {
      flexBasis: '30%',
      height: '100%'
    },
    thirdColumn: {
      flexBasis: '15%',
      borderLeft: '1px solid #D0D0D0',
      height: '100%'
    },
    row: {
      border: '1px solid #D0D0D0',
      borderTop: 'none',
      height: 50,
      '&:first-child': {
        borderTop: '1px solid #D0D0D0'
      }
    },
    headerRow: {
      '&:first-child': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: 27,
        fontSize: 13,
        fontWeight: 'bold'
      },
      '&:nth-child(2)': {
        backgroundColor: theme.palette.grey[100],
        fontWeight: 'bold'
      }
    },
    checkContainer: {
      flex: '1 1 0',
      height: '100%',
      justifyContent: 'center',
      borderRight: '1px solid #D0D0D0',
      '&:last-child': { borderRight: 'none' }
    },
    personName: {
      fontWeight: 'bold'
    },
    clickable: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    description: {
      fontSize: 13,
      color: theme.palette.divider
    },
    checkboxRoot: {
      margin: 0,
      padding: 0
    },
    permissionCell: {
      margin: 5,
      textAlign: 'center',
      fontSize: 13
    },
    personDetail: {},
    chip: {
      borderRadius: 5,
      height: 25
    },
    deleteButton: {
      width: 20,
      height: 20,
      marginRight: 16
    },
    deleteIcon: {
      fill: theme.palette.primary.main
    }
  });

export type ClassKeys = typeof styles;

export default styles;
