import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: 'bold',
      fontSize: 18,
      paddingBottom: 16,
      borderBottom: '1px solid #D0D0D0',
      maxHeight: 16
    },
    iconStyle: { padding: 10 },
    beforeYouStartContainer: { paddingTop: 30, paddingRight: 0, lineHeight: '24px' },
    beforeYouStartTextDisclaimer: { paddingTop: 10, fontSize: 12, color: '#888888' },
    beforeYouStartTitle: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 16
    },
    beforeYouStartBody: {
      paddingTop: 10,
      paddingLeft: 15,
      backgroundColor: '#F2F2F2',
      paddingBottom: 23
    },
    beforeYouStartText: {
      paddingLeft: 50
    },
    pointers: {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '18px'
    },
    heightPadding: {
      paddingTop: '20px'
    },
    footerVerifyButtonContainer: {
      flex: 1,
      width: 300
    },
    footerVerifyButton: {
      float: 'right'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
