import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface State {
  isOpen: boolean;
}

interface OwnProps {
  stepIndex?: number;
  label: string;
  onEditClick?: (event: React.SyntheticEvent<HTMLButtonElement>, step: number) => void;
  toggleable?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class Section extends React.PureComponent<React.PropsWithChildren<Props>, State> {
  public readonly state: Readonly<State> = {
    isOpen: false
  };

  static defaultProps: Partial<Props> = {
    toggleable: false
  };

  render() {
    const { classes, label } = this.props;
    return (
      <div className={classes.root}>
        <FlexLayout className={classes.title} alignItems="center" justifyContent="space-between">
          <Typography variant="h2" className={classes.bold}>
            {label}
          </Typography>
          {this.renderEditLink()}
          {this.renderToggleButton()}
        </FlexLayout>
        {this.renderChildren()}
      </div>
    );
  }

  private renderEditLink() {
    const { onEditClick, stepIndex, classes } = this.props;
    return (
      !!onEditClick && (
        <div className={classes.editLink}>
          <ButtonLink data-step-index={stepIndex} onClick={this.handleOnEditClick} color="inherit">
            Edit
          </ButtonLink>
        </div>
      )
    );
  }

  private renderToggleButton() {
    const { toggleable, classes } = this.props;
    return (
      toggleable && (
        <Box className={classes.toggleButton} onClick={this.handleOnToggleClick} title={this.state.isOpen ? 'Hide details' : 'Show more details'}>
          {this.state.isOpen ? '-' : '+'}
        </Box>
      )
    );
  }

  private renderChildren() {
    const { toggleable, classes, children } = this.props;
    if (toggleable && !this.state.isOpen) {
      return null;
    }
    return <div className={classes.content}>{children}</div>;
  }

  private handleOnEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const stepIndex = Number(dataAttribute('step-index', e));
    this.props.onEditClick && this.props.onEditClick(e, stepIndex);
  };

  private handleOnToggleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    this.setState({ isOpen: !this.state.isOpen });
  };
}

export default withStyles(styles)(Section);
