import { createAction } from 'typesafe-actions';

import { ICertInfo } from '@sympli/digital-signing/interfaces';

import { UserInfo } from 'src/containers/certificate-validation/models';

export const showVerification = createAction('SHOW_VERIFICATION')<boolean>();

export const showVerificationPopup = createAction('SHOW_VERIFICATION_POPUP')<boolean>();

export const updateCertificateValidationInfo = createAction('UPDATE_CERTIFICATE_VALIDATION_INFO')<
  Partial<{
    certificates: ICertInfo[];
    selectedCertificate: ICertInfo | null;
    userInfo: UserInfo;
    logGroupId: string;
  }>
>();
