import * as React from 'react';

import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import { Form, Formik as OrigFormik, FormikHelpers, FormikProps } from 'formik';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Header from 'src/components/layout/header';
import { DEFAULT_PERSON_BASE_ITEM, VOIPersonModel, VOITypeEnum } from 'src/models';

import { collectPersonsFromPreviousSteps } from '../../helpers';
import { OnboardingDetailsModel, OnboardingFormStepsEnum } from '../../models';
import PersonComponentBase from '../person-component-base';
import Section from '../review-registration/components/section';
import VOITable from '../review-registration/components/voi-table';
import { copyPersonFromValues } from '../review-registration/helpers';
import PersonDialog from './components/person-modal/PersonModal';
import PersonTable from './components/person-table';
import { DEFAULT_SIGNER_ITEM, SignerEntityModel, SignersModel } from './models';
import styles, { ClassKeys } from './styles';
import { signersValidationSchema } from './validationSchema';

// ! The name of this page has been change to VOI
interface SignersProps {
  step: number;
  onSave: (data: { valuesFromCurrentStep: any; allValues?: OnboardingDetailsModel }, formikActions: FormikHelpers<any>) => Promise<any>;
  onStepChange: (shift: number, values?: object) => void;
  getInitialValues: (step?: number) => any;
}

type Props = SignersProps & WithStyles<ClassKeys>;

// State to control modal
interface State {
  openPersonDialog: boolean;
  isEditPerson: boolean;
  editPersonIndex: number;
}
class Signers extends PersonComponentBase<Props, State> {
  public readonly state: Readonly<State> = {
    openPersonDialog: false,
    isEditPerson: false,
    editPersonIndex: 0
  };

  // ! hide trust Account for V1 WEB-2984
  // private trustAccountSignerOptions: Array<LookupEnumModel | LookupItemModel>;
  private docSigningOptions: Array<LookupEnumModel | LookupItemModel>;
  private agreementSigners: Array<VOIPersonModel>;
  private initialValues: SignersModel;

  constructor(props: Props) {
    super(props);

    this.modelFieldName = 'signers';
    this.allValues = this.props.getInitialValues();
    this.initialValues = this.getInitialValues();

    this.initialisePersonReference();
    /// const isSympliSourceAccount = this.allValues.trustAccount.isSympliSourceAccount;
    // ! hide trust Account for V1 WEB-2984
    // this.trustAccountSignerOptions = isSympliSourceAccount
    //   ? [{ name: 'Sympli Trust Account', id: this.allValues.trustAccount.accounts[0].id }]
    //   : this.allValues.trustAccount.accounts.map(item => ({
    //       name: `Trust account (Name: ${item.accountDetails.accountName})`,
    //       id: item.id
    //     }));
    this.docSigningOptions = this.allValues.organisationDetails.jurisdictions.map(state => {
      return { id: state, name: `${state} Registry Instruments` };
    });

    this.agreementSigners = this.allValues.organisationDetails.participationAgreement.agreementSigners.map((person, idx) => {
      return {
        ...copyPersonFromValues(person, this.allValues),
        isParticipationAgreementSigner: true
      };
    });

    // This screen contains complex logic for referencing existing and newly added person across different sections.
    // The below lines are to help you to understand what's going on here.
    //
    // We need to collect people from previous steps:
    //
    // Step: Organisation Detail
    //  model: OrganisationDetails.participationAgreement.agreementSigners
    //
    // Step: Direct Debit Account
    //  model: directDebitAccount.accounts[].accountHolders[]
    //
    // Step: Trust Account
    //  model: trustAccount.accounts[].accountHolders[]
    //
    // Additional rules and restrictions:
    //
    // don't include names already used
    // don't include names which are invalid (checking names from current step only,
    // ...since we believe names from previous steps were valid
    // include name currently selected
    //
    // when person is removed, we need to iterate through all the place where
    // ...it could have been possibly referenced and reset the values to empty.
    // This applies to steps which follows after this one as well,
    // since we might have revisited this step for example by clickin onto the email link.
    //
    // Conclusion:
    // good luck!!!
  }

  private initialisePersonReference() {
    const pathsForDirectDebitAccountPersonSection = this.allValues.directDebitAccount.accounts.map((item, idx) => {
      return `directDebitAccount.accounts[${idx}].accountHolders`;
    });
    const pathsForTrustAccountPersonSection = this.allValues.trustAccount.accounts.map((item, idx) => {
      return `trustAccount.accounts[${idx}].accountHolders`;
    });
    const pathsFromLandRegistry = this.allValues.landRegistry.states.reduce((acc, item, idx) => {
      return acc.concat([
        `landRegistry.states[${idx}].financialCorrespondence.contactPerson`,
        `landRegistry.states[${idx}].lodgementCorrespondence.contactPerson`
      ]);
    }, []);
    const previousPersonPaths = ['intro.registeredPerson', 'organisationDetails.participationAgreement.agreementSigners']
      .concat(pathsForDirectDebitAccountPersonSection)
      .concat(pathsForTrustAccountPersonSection)
      .concat(pathsFromLandRegistry);
    this.previousPersonOptions = collectPersonsFromPreviousSteps(this.allValues, previousPersonPaths);
  }

  // Use OrigFormik because we want it to
  // rerender the form AND open dialog for this.state.openPersonDialog change
  render() {
    return (
      <OrigFormik //
        initialValues={this.initialValues}
        validationSchema={signersValidationSchema}
        onSubmit={this.handleOnSubmit}
      >
        {(formikProps: FormikProps<SignersModel>) => this.renderForm(formikProps)}
      </OrigFormik>
    );
  }

  private renderPersonDialog(pageFormikProps: FormikProps<SignersModel>) {
    // TODO optimize calculation, cached them all
    const notUsedNameOptions = this.getPreviousAvailableNameOptions(_get(pageFormikProps.values, 'signersList', []));
    const options = this.addCurrentlySelectedOption(notUsedNameOptions, ADD_NEW, pageFormikProps.values);
    const title = this.state.isEditPerson ? 'Add new signer' : 'Edit signer';
    const initialValue = this.state.isEditPerson
      ? _get(pageFormikProps.values, `signersList[${this.state.editPersonIndex}]`, DEFAULT_SIGNER_ITEM)
      : DEFAULT_SIGNER_ITEM;
    const handleOnSubmit = this.state.isEditPerson ? this.onSubmitEditSigner.bind(this, pageFormikProps) : this.onSubmitAddSigner.bind(this, pageFormikProps);
    return (
      <PersonDialog
        title={title}
        open={this.state.openPersonDialog}
        onClose={this.handleOnClosePersonDialog}
        onSubmit={handleOnSubmit}
        docSigningOptions={this.docSigningOptions}
        // ! hide trust Account for V1 WEB-2984
        // trustAccountSignersOptions={this.trustAccountSignerOptions}
        trustAccountSignersOptions={[]}
        options={options}
        initialValue={initialValue}
        hasPicker={!this.state.isEditPerson}
      />
    );
  }

  private onClickAddSigner = () => {
    this.openDialog('add');
  };

  private onClickEditPerson = (editPersonIndex: number) => {
    this.openDialog('edit', editPersonIndex);
  };

  private handleOnClosePersonDialog = () => {
    if (window.confirm('You will lose the unsaved data. Do you want to continue?')) {
      this.closeDialog();
    }
  };

  private onSubmitAddSigner = (pageFormikProps: FormikProps<SignersModel>, values: SignerEntityModel) => {
    const addedPerson = copyPersonFromValues(values, this.allValues);
    this.closeDialog();
    // update page formik state
    const previousSigners = _get(pageFormikProps.values, 'signersList', [] as Array<SignerEntityModel>);
    const newPersonIndex = previousSigners.length;
    pageFormikProps.setFieldValue('signersList', previousSigners.concat(addedPerson as SignerEntityModel));
    pageFormikProps.setFieldTouched(`signersList[${newPersonIndex}]` as any, true);
  };

  private onSubmitEditSigner = (pageFormikProps: FormikProps<SignersModel>, editPerson: SignerEntityModel) => {
    this.touchedGlobalState = true;
    const { canSignTrustAccounts, allowedJurisdictionsForDocumentSigning, ...personModel } = editPerson;
    if (editPerson.existingOrNew !== ADD_NEW) {
      // if is a person from pervious steps, update it to allValues[path/exsitingOrNew]
      // keep existingOrNew as ADD_NEW
      _set(this.allValues, editPerson.existingOrNew, { ...personModel, existingOrNew: ADD_NEW });
    }
    this.closeDialog();
    // update page formik state
    pageFormikProps.setFieldValue(`signersList[${this.state.editPersonIndex}]` as any, editPerson);
    pageFormikProps.setFieldTouched(`signersList[${this.state.editPersonIndex}]` as any, true);
  };

  private renderPersonTable(pageFormikProps: FormikProps<SignersModel>) {
    return (
      <Section label="Users to be issued with digital certificates">
        <FlexLayout flexDirection="row" justifyContent="space-between">
          <div>All signers will require a digital certificate.</div>
          <Button color="primary" style={{ height: 34 }} variant="outlined" onClick={this.onClickAddSigner}>
            <Icon>add</Icon>Add new signer
          </Button>
        </FlexLayout>
        <br />
        <PersonTable
          className={this.props.classes.marginBottom}
          people={_get(pageFormikProps.values, 'signersList', [])}
          onAddPerson={this.onClickAddSigner}
          onDeletePerson={this.onDeletePerson.bind(this, pageFormikProps)}
          onEditPerson={this.onClickEditPerson}
        />
      </Section>
    );
  }

  private onDeletePerson = (pageFormikProps: FormikProps<SignersModel>, personIndex: number) => {
    const newPersonList = _get(pageFormikProps.values, 'signersList', [] as Array<SignerEntityModel>);
    pageFormikProps.setFieldValue('signersList', newPersonList.splice(personIndex, 1));
  };

  private handleOnSubmit = (values: SignersModel, formikActions: FormikHelpers<SignersModel>) => {
    const submitValues = this.handleOnPreSubmit(values);
    this.props.onSave(submitValues, formikActions);
  };

  private getInitialValues = () => {
    const values = this.props.getInitialValues(OnboardingFormStepsEnum.Signers);
    values.signersList = values.signersList.map(signer => copyPersonFromValues(signer, this.allValues));
    return values;
  };

  private renderForm(pageFormikProps: FormikProps<SignersModel>) {
    return (
      <React.Fragment>
        <FlexLayout flexDirection="row" justifyContent="space-between">
          <Header>Verification of identity</Header>
        </FlexLayout>
        {this.renderPersonDialog(pageFormikProps)}
        <Form>
          {/* {// ! hide digicert for V1 WEB-2984} */}
          {/* {this.renderPersonTable(pageFormikProps)} */}
          <Section label="Individuals requiring verification of identity (VOI)">{this.renderVOI(pageFormikProps)}</Section>
          {pageFormikProps.isValid ? null : <p style={{ color: 'red' }}>{pageFormikProps.errors.signersList}</p>}
          <WizardStepper onBack={this.handleOnBackClick} nextLabel="Continue" disabled={pageFormikProps.isSubmitting} />
        </Form>
      </React.Fragment>
    );
  }

  private renderVOI(pageFormikProps: FormikProps<SignersModel>) {
    return (
      <React.Fragment>
        {/* <Typography variant="body2" component="p">
          All individuals requiring VOI will receive a document and instructions via email. Some more information about VOI and requirements goes here. You can
          complete VOI via at an InfoTrack office or selected Australia Post stores (wording TBC).
        </Typography>
        <FormGroup title="I would like to complete VOI via">
          <Field className={this.props.classes.fullWidth} name={'voiType'} component={RadioField} options={VOI_TYPE_OPTIONS} vertical={true} format="number" />
        </FormGroup> */}
        <Typography variant="body2" component="p">
          The following individuals will require verification of identity. We will contact you on further instructions once your application has been reviewed.
        </Typography>
        <br />
        <VOITable VOIPeople={this.agreementSigners} />
      </React.Fragment>
    );
  }

  private handleOnPreSubmit = (values: SignersModel) => {
    values.voiType = VOITypeEnum.AusPost;

    // clear people's detail for who is not ADD_NEW
    const signers = values.signersList.map(signer => (signer.existingOrNew === ADD_NEW ? signer : { ...signer, ...DEFAULT_PERSON_BASE_ITEM }));

    const submitValues = { ...values, signersList: signers, voiPeople: this.agreementSigners };
    return this.touchedGlobalState ? { valuesFromCurrentStep: submitValues, allValues: this.allValues } : { valuesFromCurrentStep: submitValues };
  };

  private handleOnBackClick = () => {
    // alert lose data
    this.props.onStepChange(-1);
  };

  // * Dialog
  private closeDialog() {
    this.setState({
      openPersonDialog: false,
      isEditPerson: false,
      editPersonIndex: 0
    });
  }

  private openDialog(mode: 'add' | 'edit', editIndex?: number) {
    switch (mode) {
      case 'add': {
        this.setState({
          openPersonDialog: true,
          isEditPerson: false,
          editPersonIndex: 0
        });
        break;
      }
      case 'edit': {
        if (editIndex !== undefined) {
          this.setState({
            openPersonDialog: true,
            isEditPerson: true,
            editPersonIndex: editIndex
          });
        }
        break;
      }
      default: {
      }
    }
  }
}
export default withStyles(styles)(Signers);
