import Http, { axiosClient as axios } from '@sympli/http-agent';
import { BsbLookupApiResponse } from '@sympli/ui-framework/components/formik/bank-account-field';
import Logger, { SeverityEnum } from '@sympli/ui-logger';
import queryString from 'query-string';

import { showVerification, showVerificationPopup } from 'src/actions/application';
import { OnboardingFullModel } from 'src/containers/onboarding/models';
import { OnboardingDetailsModel } from 'src/containers/onboarding/views/onboarding-detail/models';
import { AbnLookupResultModel, DocumentApiItemModel, PersonModel } from 'src/models';
import { globalDispatch } from 'src/store';
import { apiDetailToOnboardingDetail, onboardingDetailToApiDetail } from 'src/utils/helper';

const ABN_LOOKUP_URI = '/Lookups/abn';

export interface QueryObject {
  [key: string]: any;
}

export default class Api {
  // * Create onboard
  static register(data: PersonModel): Promise<any> {
    return Http.post('/onboard', data);
  }

  // * GetAccessToken, it does not require smscode at the first time
  static getAccessToken(urlToken: string, smsCode: string): Promise<void> {
    const uri = '/Account/GetAccessToken';

    return new Promise((resolve, reject) => {
      axios
        .post(uri, { urlToken: urlToken, code: smsCode })
        .then((data: any) => {
          const { accessToken, expiry } = data;
          // ! Always add the accessToken to header of customHttp
          axios.defaults.headers.common['accessToken'] = accessToken;

          setTimeout(() => {
            globalDispatch(showVerificationPopup(true));
          }, expiry);

          resolve();
          globalDispatch(showVerification(false));
          globalDispatch(showVerificationPopup(false));
        })
        .catch(reject);
    });
  }

  // * Resend smspasscode to mobile
  static resendSmsCode(urlToken: string): Promise<any> {
    const url = '/Account/sendpasscode';
    return Http.post(url, { urlToken: urlToken });
  }

  // * Get onboard data, including form detail, status, error
  static loadData() {
    return new Promise((resolve, reject) =>
      Http.get<any>('/onboard')
        .then(responseData => {
          const onboarding: OnboardingFullModel = { ...responseData, details: apiDetailToOnboardingDetail(responseData) };
          resolve(onboarding);
        })
        .catch(reject)
    );
  }

  // * Update details for form details
  static updateDetails(data: OnboardingDetailsModel): Promise<any> {
    const postData = onboardingDetailToApiDetail(data);
    return Http.put('/onboard', postData);
  }

  // * Submit details for form details
  static submitDetails(): Promise<any> {
    return new Promise((resolve, reject) =>
      Http.put<any>('/onboard/submit', {})
        .then(responseData => {
          const onboarding: OnboardingFullModel = { ...responseData, details: apiDetailToOnboardingDetail(responseData) };
          resolve(onboarding);
        })
        .catch(reject)
    );
  }

  // * Get all documents for this application
  static getDocumentsList(): Promise<Array<DocumentApiItemModel>> {
    return Http.get(`/document`);
  }

  // * Upload documents
  // * @param FormDate: {groupName: string; subGroupName: string; fileName: string;	category: string; file: blob }
  static uploadFile(data: FormData, onUploadProgress: (progressEvent: any) => void): Promise<any> {
    return Http.post('/document', data, {
      onUploadProgress,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  // * Delete Document
  static deleteDocument(fileName: string, groupName: string, subGroupName: string): Promise<any> {
    const params: QueryObject = { group: groupName, name: fileName, subGroupName: subGroupName };
    const query = queryString.stringify(params);
    return Http.delete(`/document?${query}`);
  }

  // * Get the document blob
  static getDocumentContent(fileName: string, fileGroup: string): Promise<any> {
    const params: QueryObject = { group: fileGroup, name: fileName };
    const query = queryString.stringify(params);
    return Http.get(`/document/content?${query}`, { responseType: 'blob' });
  }

  // * Download File by objectURl, remember to revoke the url when finish
  // TODO improvement, cache the url and manage to download it only once
  static downloadFile(fileName: string, fileGroup: string): Promise<any> {
    return Api.getDocumentContent(fileName, fileGroup).then(response => {
      const url = window.URL.createObjectURL(response);
      Logger.console(SeverityEnum.Info, url);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  }

  // * Helper lookups
  static bsbLookUp(bsbNumber: string): Promise<BsbLookupApiResponse> {
    const url = `/Lookups/bsbnumber/${encodeURIComponent(bsbNumber)}`;
    return Http.get<BsbLookupApiResponse>(url);
  }

  static abnLookUp(abn: string): Promise<AbnLookupResultModel> {
    const url = `${ABN_LOOKUP_URI}/${abn}`;
    return Http.get<AbnLookupResultModel>(url);
  }
}
