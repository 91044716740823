import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { getGuid } from 'src/containers/onboarding/helper';
import { AccountDetailsModel, DEFAULT_ACCOUNT_DETAILS_ITEM, DEFAULT_PERSON_ITEM, PersonModel } from 'src/models';

export interface TrustAccountHolderEntityModel extends PersonModel {}
export const DEFAULT_TRUST_ACCOUNT_HOLDER_ITEM: TrustAccountHolderEntityModel = Object.assign({}, DEFAULT_PERSON_ITEM);

export const BOOLEAN_OPTIONS = [
  { name: 'Yes', id: false }, // isSympliSourceAccount: false
  { name: 'No, I will use the Sympli Source Account', id: true } // isSympliSourceAccount: true
];

export enum SigningAuthorityRequirementsEnum {
  OTHER = 0,
  ANYONE = 1,
  TWO_OR_MORE = 2
}
export const SIGNING_AUTHORITY_OPTIONS = [
  { name: 'Any one signatory may sign', id: SigningAuthorityRequirementsEnum.ANYONE },
  { name: 'Any two or more signatories may sign jointly', id: SigningAuthorityRequirementsEnum.TWO_OR_MORE },
  { name: 'Other (e.g. "one primary signatory and any authorised signatory")', id: SigningAuthorityRequirementsEnum.OTHER }
];

export interface TrustAccountEntityModel {
  id: string;
  accountDetails: AccountDetailsModel;
  accountHolders: Array<TrustAccountHolderEntityModel>;
  accountLocation: {
    suburb: string;
    state: string;
  };
  signingAuthorityRequirements: SigningAuthorityRequirementsEnum;
}

export function getDefaultTrustAccountItem(): TrustAccountEntityModel {
  return {
    id: getGuid(),
    accountDetails: Object.assign({}, DEFAULT_ACCOUNT_DETAILS_ITEM),
    accountLocation: {
      suburb: '',
      state: ''
    },
    accountHolders: [Object.assign({}, DEFAULT_TRUST_ACCOUNT_HOLDER_ITEM)],
    signingAuthorityRequirements: SigningAuthorityRequirementsEnum.ANYONE
  };
}

export interface TrustAccountModel {
  isSympliSourceAccount: boolean;
  accounts: Array<TrustAccountEntityModel>;
}

export const DEFAULT_TRUST_ACCOUNT_VALUES: TrustAccountModel = {
  isSympliSourceAccount: false,
  // ! hide trust Account for V1 WEB-2984
  // TODO add default account holder back
  accounts: []
  // accounts: [getDefaultTrustAccountItem()]
};

export const MOCK_UP_TRUST_ACCOUNT_VALUES: TrustAccountModel = {
  isSympliSourceAccount: true,
  accounts: [
    {
      id: '8084d5dd-4f91-4590-927c-4751b49f2de0',
      accountDetails: {
        bsbNumber: '062-693',
        accountNumber: '123456789',
        bankName: 'Commonwealth Bank of Australia',
        accountName: 'TEST ACCOUNT NAME'
      },
      accountLocation: {
        suburb: 'Sydney',
        state: 'VIC'
      },
      accountHolders: [
        {
          existingOrNew: ADD_NEW,
          firstName: 'John',
          lastName: 'Lennon',
          jobTitle: 'Director',
          email: 'john.lennon@test.com.au',
          mobileNumber: '0476354673'
        },
        {
          existingOrNew: 'intro.registeredPerson',
          firstName: '',
          lastName: '',
          jobTitle: '',
          email: '',
          mobileNumber: ''
        },
        {
          existingOrNew: 'directDebitAccount.accounts[0].accountHolders[0]',
          firstName: '',
          lastName: '',
          jobTitle: '',
          email: '',
          mobileNumber: ''
        }
        // {
        //   existingOrNew: 'directDebitAccount.accounts[1].accountHolders[0]',
        //   firstName: '',
        //   lastName: '',
        //   jobTitle: '',
        //   email: '',
        //   mobileNumber: ''
        // },
        // {
        //   existingOrNew: 'directDebitAccount.accounts[2].accountHolders[2]',
        //   firstName: '',
        //   lastName: '',
        //   jobTitle: '',
        //   email: '',
        //   mobileNumber: ''
        // },
      ],
      signingAuthorityRequirements: 2
    }
  ]
};
