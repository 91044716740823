import * as React from 'react';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { PersonModel } from 'src/models';
import { nameLine } from 'src/utils/formatters';

import LineItem from '../line-item';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  applicant: PersonModel;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class ReviewApplicantDetail extends React.PureComponent<Props> {
  render() {
    const { applicant } = this.props;

    return (
      <React.Fragment>
        <LineItem title="Name:">
          <Typography>{nameLine(applicant)}</Typography>
        </LineItem>
        <LineItem title="Mobile:">
          <Typography>{applicant.mobileNumber}</Typography>
        </LineItem>
        <LineItem title="Email:">
          <Typography>{applicant.email}</Typography>
        </LineItem>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ReviewApplicantDetail);
