import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    title: {
      paddingBottom: 13,
      borderBottom: '1px solid #D0D0D0',
      marginBottom: 16,
      position: 'relative'
    },
    toggleButton: {
      fontSize: 13,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      height: 16,
      width: 16,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    editLink: {
      flex: '1 1',
      marginLeft: 16
    },
    content: {
      marginTop: 20,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2.5)
    },
    bold: {
      fontWeight: 'bold'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
