import * as React from 'react';

export default (props: React.SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 150 150" width="130" height="130" {...props}>
    <g xmlns="http://www.w3.org/2000/svg">
      <path fill="#F2F2F2" width="150" height="150" d="M145,75c0,38.7-31.3,70-70,70S5,113.7,5,75S36.3,5,75,5S145,36.3,145,75" />
    </g>
    <g>
      <rect x="102" y="44" width="7" height="12" fill="#49B9AC" />
    </g>
    <g>
      <path d="M109.9,46h-7.8c-0.6,0-1.1-0.5-1.1-1.1v-0.8c0-0.6,0.5-1.1,1.1-1.1h7.8c0.6,0,1.1,0.5,1.1,1.1v0.8    C111,45.5,110.5,46,109.9,46" fill="#F08026" />
    </g>
    <g>
      <polygon id="SVGID_7_" points="59,104 17,104 17,67.1 37.8,53 59,67.1   " fill="#49B9AC" />
    </g>
    <g>
      <path
        fill="#F08026"
        d="M64.5,69.4L39,52.1C38.8,52,38.7,52,38.5,52c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0.1L12.5,69.4    c-0.4,0.3-0.6,0.8-0.3,1.3l1.6,2.9c0.3,0.5,0.8,0.6,1.3,0.3l23.5-16l23.5,16c0.4,0.3,1,0.1,1.3-0.3l1.6-2.9    C65.1,70.2,65,69.6,64.5,69.4"
      />
    </g>
    <g>
      <polygon points="111,104 66,104 66,58.6 87,46 111,60.4   " fill="#49B9AC" />
    </g>
    <g>
      <path
        d="M112.5,58.4L87,43.1C86.8,43,86.7,43,86.5,43c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0.1L60.5,58.4    c-0.4,0.3-0.6,0.8-0.3,1.3l1.6,2.8c0.3,0.4,0.8,0.6,1.3,0.3l23.5-14.1L110,62.9c0.4,0.3,1,0.1,1.3-0.3l1.6-2.8    C113.1,59.2,113,58.7,112.5,58.4"
        fill="#F08026"
      />
    </g>
    <g>
      <path d="M135.9,63H111v-5h24.9c0.6,0,1.1,0.5,1.1,1.1v2.9C137,62.5,136.5,63,135.9,63" fill="#F08026" />
    </g>
    <g>
      <rect x="110" y="63" width="23" height="41" fill="#49B9AC" />
    </g>
    <g>
      <rect x="76" y="72" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="89" y="72" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="76" y="84" width="9" height="20" fill="#2FA391" />
    </g>
    <g>
      <rect x="89" y="84" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="27" y="72" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="41" y="72" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="27" y="84" width="9" height="20" fill="#2FA391" />
    </g>
    <g>
      <rect x="41" y="84" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="117" y="72" width="9" height="8" fill="#2FA391" />
    </g>
    <g>
      <rect x="117" y="84" width="9" height="20" fill="#2FA391" />
    </g>
    <g>
      <path d="M11,103c10.8,24.7,35.5,42,64.2,42c28.7,0,53.4-17.3,64.2-42H11z" fill="#2FA391" />
    </g>
    <g>
      <path
        d="M43.8,42C43.7,42,43.7,42,43.8,42C43.7,42,43.7,42,43.8,42l-7.4,0l0,0c-0.1,0-0.2,0-0.4,0    c-2.7,0-4.9-2.2-4.9-5c0-2.5,1.9-4.6,4.4-4.9c0.7-4,4.2-7.1,8.4-7c2.1,0,4,0.8,5.5,2.1c0.9-0.4,1.9-0.6,2.9-0.6c3.9,0,7,3.2,7,7.1    c2.3,0,4.2,1.9,4.2,4.3c0,2.2-1.7,4-3.9,4.2l0,0l-0.3,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0L43.8,42z"
        fill="#F08026"
      />
    </g>
  </svg>
);
