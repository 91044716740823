import * as React from 'react';

import { connect } from 'react-redux';

import _get from 'lodash-es/get';

import { removeFirstGlobalError } from 'src/actions/globalErrors';
import { ConnectedDispatchProps } from 'src/models/redux';
import { Store } from 'src/reducers';

import MessageDialog from '../message-dialog';
import { DialogType } from '../message-dialog/models';

interface OwnProps {
  onReport?: (message: string) => void;
}

type ConnectedStateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & ConnectedStateProps & ConnectedDispatchProps;

class ErrorDialogContainer extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    onReport: message => {
      window.location.href = `mailto:hello@sympli.com.au?subject=Error%20on%20register&body=${message}`;
    }
  };

  render() {
    const { error } = this.props;
    return (
      <MessageDialog
        open={!!this.props.error}
        type={DialogType.Error}
        title={_get(error, 'title') || ''}
        message={_get(error, 'message') || ''}
        onIgnore={this.handleOnIgnore}
      />
    );
  }

  private handleOnIgnore = () => {
    this.props.dispatch(removeFirstGlobalError());
  };
}

const mapStateToProps = (state: Store, props) => ({
  error: state.globalErrors.queue[0]
});

export default connect<ConnectedStateProps, ConnectedDispatchProps, OwnProps>(mapStateToProps)(ErrorDialogContainer);
