import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { DEFAULT_PERSON_ITEM, PersonModel } from 'src/models';

export interface IntroPageModel {
  registeredPerson: PersonModel;
}

export const DEFAULT_INTRO_VALUES = {
  registeredPerson: Object.assign({}, DEFAULT_PERSON_ITEM)
};

export const MOCK_UP_INTRO_VALUES = {
  registeredPerson: {
    firstName: 'Liren',
    lastName: 'Wang',
    jobTitle: 'Director',
    email: 'liren.wang@projecttickle.com.au',
    mobileNumber: '0416939123',
    existingOrNew: ADD_NEW
  }
};
