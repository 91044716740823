import { DocumentCategoryEnum, DocumentGroupEnum, DocumentGroupModel } from 'src/models';

export interface UploadDocumentsModel {
  [DocumentGroupEnum.PrincipalPracticingCertificate]: Array<DocumentGroupModel>;
  [DocumentGroupEnum.EvidenceOfIdentity]: DocumentGroupModel;
}

// ! Make sure groupName match the property name!!
export const DEFAULT_UPLOAD_DOCUMENTS_VALUES: UploadDocumentsModel = {
  [DocumentGroupEnum.PrincipalPracticingCertificate]: [],
  [DocumentGroupEnum.EvidenceOfIdentity]: { groupName: DocumentGroupEnum.EvidenceOfIdentity, category: DocumentCategoryEnum.Onboarding, fileNames: [] }
};

// not used yet
// const MOCK_UP_UPLOAD_DOCUMENTS_VALUES: UploadDocumentsModel = {
//   [DocumentGroupEnum.PrincipalPracticingCertificate]: [
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'nsw',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['NSW-cert.pdf']
//     },
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'vic',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['VIC-cert.pdf']
//     },
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'qld',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['qld-cert.pdf']
//     },
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'wa',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['wa-cert.pdf']
//     },
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'sa',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['sa-cert.pdf']
//     },
//     {
//       groupName: DocumentGroupEnum.PrincipalPracticingCertificate,
//       subGroupName: 'nt',
//       category: DocumentCategoryEnum.Onboarding,
//       fileNames: ['nt-cert.pdf']
//     }
//   ],
//   [DocumentGroupEnum.EvidenceOfIdentity]: {
//     groupName: DocumentGroupEnum.EvidenceOfIdentity,
//     category: DocumentCategoryEnum.Onboarding,
//     fileNames: ['Evidence-of-identity.pdf']
//   }
// };
