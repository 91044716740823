import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import _cloneDeep from 'lodash-es/cloneDeep';
import _get from 'lodash-es/get';

import { PersonModel } from 'src/models';

import { OnboardingDetailsModel } from '../../models';
import { AgreementSignerEntityModel } from '../organisation-details/models';
import { SignerEntityModel } from '../signers/models';

// Highly coupled
export function fillRefValue(values: OnboardingDetailsModel) {
  let filledValues = _cloneDeep(values);
  const { organisationDetails, signers, directDebitAccount } = values;

  // * Organisation detail
  const {
    participationAgreement: { agreementSigners }
  } = organisationDetails;

  filledValues.organisationDetails.participationAgreement.agreementSigners = agreementSigners.map(item => copyPersonFromValues(item, values));

  // * Land Registry
  filledValues.landRegistry.states.forEach(item => {
    item.financialCorrespondence.contactPerson = copyPersonFromValues(item.financialCorrespondence.contactPerson, values);
    item.lodgementCorrespondence.contactPerson = copyPersonFromValues(item.lodgementCorrespondence.contactPerson, values);
    // TODO JUN
    // item.financialCorrespondence.physicalAddress = copyAddressFromValues(item.financialCorrespondence.physicalAddress, values);
    // item.lodgementCorrespondence.physicalAddress = copyAddressFromValues(item.lodgementCorrespondence.physicalAddress, values);
  });

  // ! hide trust Account for V1 WEB-2984
  // * Trust Account
  // const { accounts: trusts, isSympliSourceAccount } = trustAccount;

  // if (isSympliSourceAccount) {
  //   filledValues.trustAccount.accounts = trusts.map(trust => {
  //     return {
  //       ...trust,
  //       accountLocation: {
  //         suburb: 'Sydney',
  //         state: 'NSW'
  //       },
  //       accountDetails: {
  //         bsbNumber: '0',
  //         accountNumber: '0',
  //         bankName: 'symplisource',
  //         accountName: 'symplisource'
  //       }
  //     };
  //   });
  // } else {
  //   filledValues.trustAccount.accounts = trusts.map(trust => {
  //     const { accountHolders } = trust;
  //     return {
  //       ...trust,
  //       accountHolders: accountHolders.map(item => copyPersonFromValues(item, values))
  //     };
  //   });
  // }

  const { accounts: directdebits } = directDebitAccount;
  filledValues.directDebitAccount.accounts = directdebits.map(directdebit => {
    const { accountHolders } = directdebit;
    return {
      ...directdebit,
      accountHolders: accountHolders.map(item => copyPersonFromValues(item, values))
    };
  });

  filledValues.signers.signersList = signers.signersList.map(item => copyPersonFromValues(item, values) as SignerEntityModel);

  filledValues.signers.voiPeople = signers.voiPeople.map(voiPerson => {
    return {
      ...voiPerson,
      status: 'Awaiting on individual'
    };
  });

  return filledValues;
}

export function copyPersonFromValues(person: PersonModel | AgreementSignerEntityModel, values: OnboardingDetailsModel) {
  if (!!person.existingOrNew && person.existingOrNew !== ADD_NEW) {
    const value: PersonModel = _get(values, person.existingOrNew);
    return {
      ...person,
      firstName: value.firstName,
      lastName: value.lastName,
      jobTitle: value.jobTitle,
      email: value.email,
      mobileNumber: value.mobileNumber,
      middleName: value.middleName,
      fullName: value.fullName
    };
  }
  return person;
}
