import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';

import { SYMPLI_PRIVACY_POLICY_LINK } from 'src/models/values';

import styles, { ClassKeys } from './styles';

type Props = WithStyles<ClassKeys>;

class DisclaimerFooter extends React.PureComponent<Props> {
  render() {
    const { classes } = this.props;
    const currentYear = new Date().getFullYear();
    return (
      <FlexLayout className={classes.footer} justifyContent="space-between">
        <div>
          The Sympli logo is a trade mark belonging to Sympli Australia Pty Ltd
          <br />
          ACN 624 341 420 | ABN 43 624 341 420
        </div>
        <div>
          © {currentYear} Sympli Australia Pty Ltd
          <br />
          <a href={SYMPLI_PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
            Privacy policy
          </a>
        </div>
      </FlexLayout>
    );
  }
}

export default withStyles(styles)(DisclaimerFooter);
