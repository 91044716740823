export const JOB_TITLE_OPTIONS = [
  {
    id: 'Director',
    name: 'Director'
  },
  {
    id: 'Secretary',
    name: 'Secretary'
  },
  {
    id: 'Other',
    name: 'Other'
  }
];

export const SYMPLI_PRIVACY_POLICY_LINK = import.meta.env.VITE_SYMPLI_PRIVACY_POLICY_LINK;
export const DIGICERT_TERMS_AND_CONDITION_LINK = import.meta.env.VITE_DIGICERT_TERMS_AND_CONDITION_LINK;
export const DIGICERT_PRIVACY_POLICY_LINK = import.meta.env.VITE_DIGICERT_PRIVACY_POLICY_LINK;
export const SYMPLI_SIGNING_APPLICATION_LINK = import.meta.env.VITE_SYMPLI_SIGNING_APPLICATION_LINK;
