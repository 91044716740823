import * as React from 'react';
import * as yup from 'yup';

import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import Formik from '@sympli/ui-framework/components/formik';
import BankAccountField, { BsbLookupApiResponse } from '@sympli/ui-framework/components/formik/bank-account-field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDelete } from '@sympli/ui-framework/icons';
import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Header from 'src/components/layout/header';
import ScrollToFormikError from 'src/components/scroll-to-formik-error';

import { collectPersonsFromPreviousSteps } from '../../helpers';
import { OnboardingDetailsModel, OnboardingFormStepsEnum } from '../../models';
import { yupBankAccount, yupPersonList } from '../../shared/validationSchema';
import PersonComponentBase from '../person-component-base';
import { DEFAULT_ACCOUNT_HOLDER_ITEM, DirectDebitAccountModel } from './models';
import styles, { ClassKeys } from './styles';

interface DirectDebitAccountProps {
  step: number;
  onSave: (data: { valuesFromCurrentStep: any; allValues?: OnboardingDetailsModel }, formikProps: FormikProps<any>) => Promise<any>;
  onStepChange: (shift: number, values?: object) => void;
  onBsbLookUp: (bsbNumber: string) => Promise<BsbLookupApiResponse>;
  getInitialValues: (step?: number) => any;
}

type Props = DirectDebitAccountProps & WithStyles<ClassKeys>;

class DirectDebitAccount extends PersonComponentBase<Props> {
  constructor(props: Props) {
    super(props);

    this.modelFieldName = 'directDebitAccount';
    this.allValues = this.props.getInitialValues();

    this.initialisePersonReference();
  }

  private getInitialValues = (): DirectDebitAccountModel => {
    const directDebit: DirectDebitAccountModel = this.props.getInitialValues(OnboardingFormStepsEnum.DirectDebitAccount);
    // * Set default accountholders as applicant
    if (this.previousPersonOptions.length >= 1) {
      const applicantRefId = this.previousPersonOptions[0].id as string;
      directDebit.accounts.forEach(account => {
        account.accountHolders.forEach(person => {
          if (!person.existingOrNew) {
            person.existingOrNew = applicantRefId;
          }
        });
      });
    }
    return directDebit;
  };

  private initialisePersonReference() {
    const pathsFromLandRegistry = this.allValues.landRegistry.states.reduce((acc, item, idx) => {
      return acc.concat([
        `landRegistry.states[${idx}].financialCorrespondence.contactPerson`,
        `landRegistry.states[${idx}].lodgementCorrespondence.contactPerson`
      ]);
    }, []);
    const previousPersonPaths = ['intro.registeredPerson', 'organisationDetails.participationAgreement.agreementSigners'].concat(pathsFromLandRegistry);
    this.previousPersonOptions = collectPersonsFromPreviousSteps(this.allValues, previousPersonPaths);
    const pathsForTrustAccountPersonSection = this.allValues.trustAccount.accounts.map((item, idx) => {
      return `trustAccount.accounts[${idx}].accountHolders`;
    });
    this.pathsForSectionInFutureSteps = pathsForTrustAccountPersonSection.concat(['signers.signersList']);
  }

  private getValidationSchema() {
    return yup.object({
      accounts: yup.array().of(
        yup.object({
          accountDetails: yupBankAccount,
          accountHolders: yupPersonList
        })
      )
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header>Direct debit accounts</Header>
        <Typography variant="h2" className={this.props.classes.subTitle}>
          For payment of Sympli and land registry fees
        </Typography>
        <Formik
          getInitialValues={this.getInitialValues} //
          validationSchema={this.getValidationSchema} // initialize Yup validation schema
          onSubmit={this.props.onSave}
          onPreSubmit={this.handleOnPreSubmit}
        >
          {(formikProps: FormikProps<DirectDebitAccountModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<DirectDebitAccountModel>) {
    // const {
    //   values: { accounts },
    //   setFieldValue
    // } = formikProps;

    return (
      <Form>
        <ScrollToFormikError formikProps={formikProps} />
        {this.renderDirectDebitAccountsSection(formikProps)}
        <WizardStepper onBack={this.handleOnBackClick} nextLabel="Save and continue" disabled={formikProps.isSubmitting}>
          {/* <div className={this.props.classes.addButtonInStepper}>
            <AddButton
              className={this.props.classes.addButton}
              fieldName={'accounts'}
              formikProps={formikProps}
              defaultValue={getDefaultDirectDebitAccountItem()}
            >
              Add another direct debit account
            </AddButton>
          </div> */}
        </WizardStepper>
      </Form>
    );
  }

  private renderDirectDebitAccountsSection = (formikProps: FormikProps<DirectDebitAccountModel>) => {
    const { classes } = this.props;
    const { values } = formikProps;
    const fieldName = 'accounts';
    const fieldValues = values[fieldName];
    let accountName;
    const numberOfAccounts = values.accounts.length;
    const pathsForDirectDebitAccountPersonSection = values.accounts.map((item, idx) => {
      return `accounts[${idx}].accountHolders`;
    });

    return fieldValues.map((item, idx) => {
      accountName = `${fieldName}[${idx}]`;
      return (
        <React.Fragment key={accountName + idx}>
          {numberOfAccounts > 1 ? (
            <React.Fragment>
              <Typography variant="h2" className={classNames(classes.subTitle, classes.marginTop)}>
                <IconButton
                  color="primary"
                  className={classes.deleteButtonInSectionTitle}
                  onClick={() =>
                    this.handleOnDeleteBankAccount(
                      'accounts',
                      idx,
                      formikProps,
                      pathsForDirectDebitAccountPersonSection.filter(path => path !== `${accountName}.accountHolders`)
                    )
                  }
                  size="large"
                >
                  <IconDelete className={classes.deleteIcon} />
                </IconButton>
                Additional direct debit account {idx}
              </Typography>
            </React.Fragment>
          ) : null}
          <FormGroup
            key={accountName + idx}
            title="Direct debit - general account"
            description="A direct debit account is required for payment of Sympli and land registry fees."
          >
            <div className={classNames(classes.fullWidth, classes.bottomBorder, classes.lineItem)}>
              <Field name={`${accountName}.accountDetails`} component={BankAccountField} fetchBsbLookup={this.fetchBsbLookup} />
            </div>
          </FormGroup>
          <FormGroup title="Direct debit account signatories" description="Individual(s) that are authorised to sign the Direct Debit Service Agreement.">
            {this.renderPersonListSubSection({
              formikProps,
              fieldName: `${accountName}.accountHolders`,
              defaultPersonItem: DEFAULT_ACCOUNT_HOLDER_ITEM,
              pathsForSectionToGrabValidNamesFrom: pathsForDirectDebitAccountPersonSection.filter(path => path !== `${accountName}.accountHolders`),
              addPersonLabel: 'Add another direct debit account holder'
            })}
          </FormGroup>
        </React.Fragment>
      );
    });
  };

  private fetchBsbLookup = (bsbNumber: string): Promise<BsbLookupApiResponse> => {
    return this.props.onBsbLookUp(bsbNumber);
  };

  private handleOnPreSubmit = (values: DirectDebitAccountModel, formikProps: FormikProps<DirectDebitAccountModel>) => {
    return this.touchedGlobalState ? { valuesFromCurrentStep: values, allValues: this.allValues } : { valuesFromCurrentStep: values };
  };

  private handleOnBackClick = () => {
    this.props.onStepChange(-1);
  };
}
export default withStyles(styles)(DirectDebitAccount);
