import { all } from 'redux-saga/effects';

import { submitFormikSagas as formikWrapper } from '@sympli/ui-framework/components/formik';

const sagas = [
  //
  ...formikWrapper // formik
];

export default function* root() {
  yield all(sagas);
}
