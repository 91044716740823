import { AddressStoreModel } from '@sympli/ui-framework/components/formik/address-field/models';
import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

export enum ShiftEnum {
  Back = -1,
  Next = 1
}

export const DEFAULT_COMPANY_VALUE: OrganisationNameModel = {
  abn: '',
  organisationName: '',
  acn: '',
  entityType: ''
};

export interface PersonBaseModel {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  mobileNumber: string;
  middleName?: string;
  fullName?: string;
}

export interface PersonModel extends PersonBaseModel {
  existingOrNew: string;
}

export interface PersonModelWithPrimaryFlag extends PersonModel {
  isPrimary: boolean;
}

export const DEFAULT_PERSON_BASE_ITEM: PersonBaseModel = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  middleName: '',
  mobileNumber: ''
};

export const DEFAULT_PERSON_ITEM: PersonModel = {
  ...DEFAULT_PERSON_BASE_ITEM,
  existingOrNew: ADD_NEW
};

export const BLANK_PERSON_ITEM: PersonModel = {
  ...DEFAULT_PERSON_BASE_ITEM,
  existingOrNew: ''
};

export interface AccountDetailsModel {
  bsbNumber: string;
  accountNumber: string;
  bankName: string;
  accountName: string;
}

export const DEFAULT_ACCOUNT_DETAILS_ITEM = {
  bsbNumber: '',
  bankName: '',
  accountNumber: '',
  accountName: ''
};

export interface OrganisationNameModel {
  organisationName: string;
  abn: string;
  acn: string;
  entityType: string;
}

export interface AbnLookupResultModel extends OrganisationNameModel {
  status: string; // extra field from api call result, e.g. 'Active'
}

export enum DocumentStatusEnum {
  Missing = -1,
  Uploaded = 0,
  Reviewing = 1,
  Pass = 2,
  Download = 3,
  Error = 99
}

export interface FormModel {
  groupName: string;
  subGroupName: string;
  downloadLink: string;
}

export interface DocumentApiItemModel {
  category: DocumentCategoryEnum;
  groupName: string;
  subGroupName: string | null;
  fileName: string;
  status: DocumentStatusEnum;
  error: string; // ApplicationErrorModel | null;
  message?: {
    title: string;
    message: string;
  };
}

export interface DocumentGroupModel {
  groupName: DocumentGroupEnum;
  subGroupName?: string; // e.g. 'qld', 'vic', 'Account Name cba', 'Account Name anz'
  category: DocumentCategoryEnum;
  fileNames: Array<string>;
  // Same as document Api Model
  sampleFile?: string;
  message?: {
    title: string;
    message: string;
  };
}

export interface AccountDocumentGroupModel extends DocumentGroupModel {
  subGroupName?: string;
  sampleFile: string;
}

// * Document Type
export enum DocumentGroupEnum {
  // Onboarding documnets
  PrincipalPracticingCertificate = 'principalPracticingCertificate', // TODO name change to generalcertificate
  EvidenceOfIdentity = 'evidenceOfIdentity',
  PowerOfAttorney = 'powerOfAttorney',
  OtherDocuments = 'otherDocuments',
  // Supporting documnets ( progress screen )
  TrustAccountAgreement = 'trustAccountAgreement',
  DirectDebitAccountAgreement = 'directDebitAccountAgreement',
  CertificateOfCurrency = 'certificateOfCurrency',
  ParticipationAgreement = 'participationAgreement',
  // VOI forms (documents)
  LetterOfAuthority = 'letterOfAuthority'
}

export enum DocumentCategoryEnum {
  Onboarding = 'Onboarding',
  SupportingDocuments = 'SupportingDocuments',
  VOI = 'VOI'
}

export type ApplicationErrorModel = string;

export interface ReuploadDocumentGroupModel extends DocumentGroupModel {
  error: string;
}

export enum ApplicationStatusEnum {
  Intro = 0,
  // *Below are status from back end
  Pending = 1,
  Editing = 2,
  Received = 3,
  Reviewing = 4,
  Approved = 5,
  Rejected = 6,
  Error = 99
}

export interface ReviewProgressModel {
  progressStatus: ReviewProgressStatusEnum;
  progressPercentage: string;
}

export enum ReviewProgressStatusEnum {
  NotStarted = 1,
  InProgress = 2,
  VoiEmailSent = 3,
  VoiTasksApproved = 4,
  Completed = 5
}

export enum VOITypeEnum {
  Unknown = -1,
  IDfy = 0,
  AusPost = 1,
  Other = 2
}

export interface VOIPersonModel extends PersonModel {
  isParticipationAgreementSigner: boolean;
  status?: string;
}

export interface ReferencedObject {
  id: string;
}

export interface CountedObject {
  count: number;
}

export interface WithAddressStore {
  addressStore: AddressStoreModel;
}

// TODO JUN move this out to certificate container, or clean onboarding model
export interface Certificate {
  id: CertificateIdentifier;
  issuer: string;
  tokenType: string;
  notAfter: string;
}

export interface CertificateIdentifier {
  certSerialNumber: string;
  providerId: string;
  providerName: string;
}
