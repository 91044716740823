import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    taskRoot: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey['300']}`
    },
    infoColumn: {
      flexGrow: 1
    },
    iconColumn: {
      flex: '0 0 10%',
      paddingTop: 16,
      paddingBottom: 16
    },
    icon: {
      fill: theme.palette.primary.main,
      margin: 'auto',
      width: '55px',
      height: '55px',
      // For material ui icon
      fontSize: '55px',
      color: theme.palette.primary.main
    },
    panelFooter: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  });

export type ClassKeys = typeof styles;

export default styles;
