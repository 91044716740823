import * as React from 'react';
import * as yup from 'yup';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik/formik-wrapper/Formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconFileText } from '@sympli/ui-framework/icons';
import { Form, FormikProps } from 'formik';
import _findLast from 'lodash-es/findLast';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Formik from 'src/components/formik';
import UploaderFormik from 'src/components/uploader-field';
import { DocumentApiItemModel, DocumentGroupEnum, DocumentGroupModel, FormModel } from 'src/models';

import TodoPanel from '../todo-panel/TodoPanel';
import styles, { ClassKeys } from './styles';

export const DOCUMENT_SUBMIT_URI = '/Document/submit';

export interface SupportingDocumentsModel {
  supportingDocuments: Array<DocumentGroupModel>;
}

interface SupportingDocumentsProps {
  supportingDocuments: Array<DocumentApiItemModel>;
  forms?: Array<FormModel>;
  onUploadFile: (data: FormData, onUploadProgress: () => void, callback?: (err?: Error) => void) => Promise<any>;
  onDeleteFile: (fileName: string, fileGroup: DocumentGroupModel, callback?: (err?: Error) => void) => Promise<any>;
  onSubmitSuccess: () => void;
}

type Props = SupportingDocumentsProps & WithStyles<ClassKeys>;

interface State {
  submitError: string;
}

class SupportingDocuments extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    submitError: ''
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          method="put"
          action={`${DOCUMENT_SUBMIT_URI}`}
          getInitialValues={this.getInitialValues} //
          validationSchema={this.getValidationSchema} // initialize Yup validation schema
          onPreSubmit={this.handleOnPreSubmit}
          onPostSubmit={this.handleOnPostSubmit}
        >
          {(formikProps: FormikProps<SupportingDocumentsModel>) => this.renderForm(formikProps)}
        </Formik>
        <div className={this.props.classes.error}>{this.state.submitError}</div>
      </React.Fragment>
    );
  }

  private handleOnPreSubmit = (values: SupportingDocumentsModel, formikProps: FormikProps<SupportingDocumentsModel>) => {
    const fileGroupList = values.supportingDocuments;
    // Post a list of { groupName: string; fileName: string;, subGroupName: string;}
    return fileGroupList.reduce((acc, cur) => {
      // supporting multiple file upload
      const fileList = cur.fileNames.map(fileName => ({ groupName: cur.groupName, fileName, category: cur.category, subGroupName: cur.subGroupName }));
      return acc.concat(fileList);
    }, []);
  };

  private handleOnPostSubmit = ({ error }: FormikPostSubmitArgs<SupportingDocumentsModel>) => {
    if (error) {
      this.setState({ submitError: error.message });
    } else {
      this.props.onSubmitSuccess();
    }
  };

  private getValidationSchema = () => {
    return yup.object({
      supportingDocuments: yup.array().of(
        yup.object({
          fileNames: yup.array().min(1, 'This document is required')
        })
      )
    });
    // Schema<SupportingDocumentsModel>
    // return yup.object({
    //   [DocumentGroupEnum.DirectDebitAccountAgreement]: yup.array().of(
    //     yup.object({
    //       fileNames: yup.array().min(1, 'Please upload Direct Debit Account Agreement')
    //     })
    //   ),
    //   [DocumentGroupEnum.TrustAccountAgreement]: yup.array().of(
    //     yup.object({
    //       fileNames: yup.array().min(1, 'Please upload Trust Account Agreement')
    //     })
    //   ),
    //   [DocumentGroupEnum.CertificateOfCurrency]: yup.object({
    //     fileNames: yup.array().min(1, 'Please upload Certificate of currency of professional indemnity insurance')
    //   }),
    //   additionalDocuments: yup.array().of(
    //     yup.object({
    //       fileNames: yup.array().min(1, 'Please upload document')
    //     })
    //   )
    // });
  };

  private getInitialValues = () => {
    // const initialValues = this.props.supportingDocuments.reduce(
    //   (acc, cur) => {
    //     switch (cur.groupName) {
    //       case DocumentGroupEnum.CertificateOfCurrency: {
    //         acc[cur.groupName] = {
    //           groupName: cur.groupName,
    //           category: cur.category,
    //           fileNames: cur.fileName ? [cur.fileName] : []
    //         };
    //         break;
    //       }
    //       case DocumentGroupEnum.TrustAccountAgreement:
    //       case DocumentGroupEnum.DirectDebitAccountAgreement: {
    //         acc[cur.groupName].push({
    //           groupName: cur.groupName,
    //           subGroupName: cur.subGroupName,
    //           category: cur.category,
    //           sampleFile: 'sampleFile.pdf',
    //           fileNames: cur.fileName ? [cur.fileName] : []
    //         });
    //         break;
    //       }
    //       default: {
    //         acc[cur.groupName] = {
    //           groupName: cur.groupName,
    //           category: cur.category,
    //           fileNames: cur.fileName ? [cur.fileName] : []
    //         };
    //         break;
    //       }
    //     }
    //     return acc;
    //   },
    //   {
    //     [DocumentGroupEnum.DirectDebitAccountAgreement]: [],
    //     [DocumentGroupEnum.TrustAccountAgreement]: [],
    //     [DocumentGroupEnum.CertificateOfCurrency]: {
    //       groupName: DocumentGroupEnum.CertificateOfCurrency,
    //       category: DocumentCategoryEnum.SupportingDocuments,
    //       fileNames: []
    //     },
    //     additionalDocuments: []
    //   }
    // );
    // TODO remove when we have real sample file
    function getSampleFile(document: DocumentApiItemModel) {
      return document.groupName === DocumentGroupEnum.DirectDebitAccountAgreement || DocumentGroupEnum.TrustAccountAgreement ? 'sampleFile.pdf' : '';
    }
    // TODO sort the array
    return {
      // Make document Api model to documet group model which has better support for both single/multiple file upload
      supportingDocuments: this.props.supportingDocuments.map(doc => ({
        ...doc,
        fileNames: doc.fileName ? [doc.fileName] : [],
        sampleFile: getSampleFile(doc),
        fileName: undefined
      }))
    };
  };

  private renderForm(formikProps: FormikProps<SupportingDocumentsModel>) {
    return (
      <TodoPanel title="Additional Documents:">
        <Form>
          {/* {this.renderTrustAccountAgreementSection(formikProps)}
          {this.renderDirectDebitAccountAgreementSection(formikProps)}
          {this.renderCertificateOfCurrencySection(formikProps)}
          {this.renderAdditionalDocuments(formikProps)} */}
          {this.renderDocuments(formikProps)}
          {this.renderSubmitButton(formikProps)}
        </Form>
      </TodoPanel>
    );
  }
  private renderDocuments(formikProps: FormikProps<SupportingDocumentsModel>) {
    const { classes, onUploadFile, onDeleteFile, forms } = this.props;
    const {
      values: { supportingDocuments }
    } = formikProps;
    const arrayFieldName = 'supportingDocuments';
    return (
      <React.Fragment>
        {supportingDocuments.map((fileGroup, idx) => {
          const fieldName = arrayFieldName + '[' + idx + ']';
          return (
            <FlexLayout key={'Additional Documents' + idx} flexDirection="row" className={classes.taskRoot}>
              {fileGroup.sampleFile && (
                <div className={classes.iconColumn}>
                  <IconFileText className={classes.icon} />
                </div>
              )}
              <FlexLayout flexDirection="column" className={classes.infoColumn}>
                <UploaderFormik
                  title={fileGroup.message ? fileGroup.message.title : fileGroup.groupName}
                  description={fileGroup.message ? fileGroup.message.message : fileGroup.subGroupName}
                  fieldName={fieldName}
                  formikProps={formikProps}
                  onUploadFile={onUploadFile}
                  onDeleteFile={onDeleteFile}
                />
                <div>{this.renderDownloadButton(fileGroup, forms)}</div>
              </FlexLayout>
            </FlexLayout>
          );
        })}
      </React.Fragment>
    );
  }

  // private renderDirectDebitAccountAgreementSection(formikProps: FormikProps<SupportingDocumentsModel>) {
  //   const { classes, onUploadFile, onDeleteFile } = this.props;
  //   const { values } = formikProps;
  //   const directDebitAccountAgreementList = _get(values, DocumentGroupEnum.DirectDebitAccountAgreement);
  //   const arrayFieldName = [DocumentGroupEnum.DirectDebitAccountAgreement];
  //   return (
  //     <React.Fragment>
  //       {directDebitAccountAgreementList.map((directdebitGroup, idx) => {
  //         const fieldName = arrayFieldName + '[' + idx + ']';
  //         return (
  //           <FlexLayout key={'Direct debit agreement' + idx} flexDirection="row" className={classes.taskRoot}>
  //             <div className={classes.iconColumn}>
  //               <IconFileText className={classes.icon} />
  //             </div>
  //             <FlexLayout flexDirection="column" className={classes.infoColumn}>
  //               <UploaderFormik
  //                 title="Direct debit agreement"
  //                 description={directdebitGroup.accountName}
  //                 fieldName={fieldName}
  //                 formikProps={formikProps}
  //                 onUploadFile={onUploadFile}
  //                 onDeleteFile={onDeleteFile}
  //               />
  //               <div>{this.renderDownloadButton(directdebitGroup)}</div>
  //             </FlexLayout>
  //           </FlexLayout>
  //         );
  //       })}
  //     </React.Fragment>
  //   );
  // }

  // private renderTrustAccountAgreementSection(formikProps: FormikProps<SupportingDocumentsModel>) {
  //   const { classes, onUploadFile, onDeleteFile } = this.props;
  //   const { values } = formikProps;
  //   const trustAccountAgreementList = _get(values, DocumentGroupEnum.TrustAccountAgreement);
  //   const arrayFieldName = [DocumentGroupEnum.TrustAccountAgreement];
  //   return (
  //     <React.Fragment>
  //       {trustAccountAgreementList.map((trustAccountGroup, idx) => {
  //         const fieldName = arrayFieldName + '[' + idx + ']'; // ! important to have the idx
  //         return (
  //           <FlexLayout key={'Trust Account agreement' + idx} flexDirection="row" className={classes.taskRoot}>
  //             <div className={classes.iconColumn}>
  //               <IconFileText className={classes.icon} />
  //             </div>
  //             <FlexLayout flexDirection="column" className={classes.infoColumn}>
  //               <UploaderFormik
  //                 key={'Trust Account agreement' + idx}
  //                 title="Trust Account agreement"
  //                 description={trustAccountGroup.accountName}
  //                 fieldName={fieldName}
  //                 formikProps={formikProps}
  //                 onUploadFile={onUploadFile}
  //                 onDeleteFile={onDeleteFile}
  //               />
  //               <div>{this.renderDownloadButton(trustAccountGroup)}</div>
  //             </FlexLayout>
  //           </FlexLayout>
  //         );
  //       })}
  //     </React.Fragment>
  //   );
  // }

  private renderDownloadButton(fileGroup: DocumentGroupModel, forms?: Array<FormModel>) {
    // TODO check subGroupName
    // Each resubmission (Unlock step) appends updated forms to forms array, findLast gets the latest forms
    const form = _findLast(forms, { groupName: fileGroup.groupName }) as FormModel | undefined;
    if (!!form && form.downloadLink) {
      return (
        <SympliButton color="primary" variant="outlined">
          <a href={form.downloadLink} target="_blank" rel="noopener noreferrer">
            Download PDF
          </a>
        </SympliButton>
      );
    }
    return null;
  }

  // private renderCertificateOfCurrencySection = (formikProps: FormikProps<SupportingDocumentsModel>) => {
  //   const { onUploadFile, onDeleteFile } = this.props;
  //   const fieldName = DocumentGroupEnum.CertificateOfCurrency;
  //   const title = 'Certificate of currency of professional indemnity insurance';
  //   return <UploaderFormik title={title} fieldName={fieldName} formikProps={formikProps} onUploadFile={onUploadFile} onDeleteFile={onDeleteFile} />;
  // };

  // private renderAdditionalDocuments = (formikProps: FormikProps<SupportingDocumentsModel>) => {
  //   const { onUploadFile, onDeleteFile } = this.props;
  //   const additionalDocuments = formikProps.values.additionalDocuments;
  //   return additionalDocuments.map((fileGroup, idx) => {
  //     const fieldName = `additionalDocuments[${idx}]`;
  //     const title = camelCaseToSentenceCase(fileGroup.groupName);

  //     return (
  //       <UploaderFormik
  //         key={`${fieldName}-${fileGroup.groupName}`}
  //         title={title}
  //         fieldName={fieldName}
  //         formikProps={formikProps}
  //         onUploadFile={onUploadFile}
  //         onDeleteFile={onDeleteFile}
  //       />
  //     );
  //   });
  // };

  private renderSubmitButton(formikProps: FormikProps<SupportingDocumentsModel>) {
    return <WizardStepper nextLabel="Submit documents" disabled={formikProps.isSubmitting} />;
  }
}

export default withStyles(styles)(SupportingDocuments);
