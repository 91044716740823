// TODO use in the future
export const VOI_TYPE_OPTIONS = [
  { name: 'IDfy', id: 0 },
  { name: 'Australia Post', id: 1 },
  { name: "I've already completed VOI", id: 2 }
];

export interface ReviewModel {
  agreeAcknowledgement: Array<boolean>;
}

export const DEFAULT_REVIEW_VALUES: ReviewModel = {
  agreeAcknowledgement: [false, false, false, false]
};

export const MOCK_UP_REVIEW_VALUES: ReviewModel = {
  agreeAcknowledgement: [true, true, true, true]
};
