import * as React from 'react';
import * as yup from 'yup';

import { Action, Dispatch } from 'redux';

import Formik from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { MessageNotification } from '@sympli/ui-framework/components/message-notification';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { validateABN } from 'au-bn-validator';
import classNames from 'classnames';
import { Form, FormikProps } from 'formik';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { updateCertificateValidationInfo } from 'src/actions/application';
import { Box } from 'src/components/layout';
import { SYMPLI_PRIVACY_POLICY_LINK } from 'src/models/values';
import { getUserCertificatesFromToolKit, resolveSigningErrorMessage } from 'src/utils/signing/signing';

import { ErrorVariantEnum, UserInfo } from '../../models';
import FormBody from './components/form-body';
import { CertificateValidationModel } from './models';
import styles, { ClassKeys } from './styles';

// const IS_DEV_ENV = import.meta.env.DEV;

interface CertificateValidationDetailPageProps {
  userInfo: UserInfo;
  dispatch: Dispatch<Action>;
  gatekeepers: string[];
}

type Props = CertificateValidationDetailPageProps & WithStyles<ClassKeys>;

interface State {
  errorInfo: {
    primary: string;
    secondary: string;
  } | null;
}

class CertificateValidationDetailPage extends React.PureComponent<Props, State> {
  readonly state: State = {
    errorInfo: null
  };

  private getInitialValues = (): CertificateValidationModel => {
    const { userInfo } = this.props;
    return {
      ...userInfo
    };
  };

  private getValidationSchema() {
    return yup.object<CertificateValidationModel>({
      abn: yup //
        .string()
        .required('Required')
        .trim()
        .test('abnCheck', 'Invalid ABN', function test(this: yup.TestContext, value: string = '') {
          const isValid = validateABN(value);
          return isValid;
        }),
      email: yup //
        .string()
        .trim()
        .email('Please enter correct email address')
        .required('Required'),
      givenName: yup.string().required('Required'),
      middleName: yup.mixed<string>(),
      surname: yup.string().required('Required')
    });
  }

  render() {
    const { classes } = this.props;
    const { errorInfo } = this.state;
    return (
      <React.Fragment>
        <Box className={classes.formTitle}>Validate your digital certificates</Box>
        <Typography component="p" className={classes.beforeYouStartContainer}>
          {' '}
          If you already have digital certificates installed on your computer you can validate them to see if they are supported by Sympli. If your digital
          certificate is on a USB token, please plug it in now.
        </Typography>
        <Typography component="p" className={classNames(classes.pointers, classes.heightPadding)}>
          - Enter the same details that you originally used to set up your digital certificate.
          <br />- Personal information provided on this page will only be used for certificate validation purposes, and will be <br /> &nbsp;&nbsp;handled in
          accordance with Sympli's &nbsp;
          <a href={SYMPLI_PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>
        </Typography>

        <MessageNotification //
          variant={ErrorVariantEnum.Error}
          open={!!errorInfo}
          primary={errorInfo ? errorInfo.primary : ''}
          secondary={errorInfo ? errorInfo.secondary : ''}
        />

        <Formik
          onSubmit={this.handleOnSubmit}
          getInitialValues={this.getInitialValues} //
          validationSchema={this.getValidationSchema} // initialize Yup validation schema
        >
          {(formikProps: FormikProps<CertificateValidationModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<CertificateValidationModel>) {
    const { classes } = this.props;

    return (
      <Form>
        <FormBody />

        <FlexLayout alignItems="center">
          <div className={classes.footerVerifyButtonContainer}>
            <SympliButton
              className={classes.footerVerifyButton}
              color="primary"
              variant="contained"
              arrowRight
              disabled={formikProps.isSubmitting}
              isLoading={formikProps.isSubmitting}
              type="submit"
            >
              Check for installed certificates
            </SympliButton>
          </div>
        </FlexLayout>
      </Form>
    );
  }

  private handleOnSubmit = async (values: CertificateValidationModel) => {
    try {
      this.setState({
        errorInfo: null
      });

      const logGroupId = Date.now().toString(); // WEB-27575 - always generate new group id
      const { certs } = await getUserCertificatesFromToolKit(values, this.props.gatekeepers, logGroupId);

      this.props.dispatch(
        updateCertificateValidationInfo({
          userInfo: values,
          certificates: certs,
          logGroupId // preserve logGroupId for the next step
        })
      );

      if (certs.length === 0) {
        this.setState({
          errorInfo: {
            primary: 'No digital certificates found.',
            secondary: 'Please check your details and try again.'
          }
        });
      }
    } catch (err) {
      this.setState({
        errorInfo: {
          primary: 'Unable to obtain signing certificates.',
          secondary: resolveSigningErrorMessage(err)
        }
      });
    }
  };
}

export default withStyles(styles)(CertificateValidationDetailPage);
