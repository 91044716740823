import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      marginTop: 32,
      marginBottom: 32
    },
    title: {
      backgroundColor: theme.palette.grey[700],
      padding: '5px 16px',
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    content: {
      padding: '0px 16px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
