import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';
import { dataAttribute } from '@sympli/ui-framework/utils/dom';
import classNames from 'classnames';

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { SignerEntityModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface PersonTableProps {
  className?: string;
  people: Array<SignerEntityModel>;

  onDeletePerson?: (personIndex: number) => void;
  onEditPerson?: (personIndex: number) => void;
  onAddPerson?: () => void;
}

type Props = PersonTableProps & WithStyles<ClassKeys>;

class PersonTable extends React.Component<Props> {
  render() {
    return (
      <FlexLayout flexDirection="column" className={classNames(this.props.classes.root, this.props.className)}>
        {this.renderHeader()}
        {this.renderPeople()}
      </FlexLayout>
    );
  }

  private renderHeader() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <FlexLayout alignItems="center" className={classNames(classes.row, classes.headerRow)}>
          <FlexLayout alignItems="center" className={classNames(classes.firstColumn)}>
            Digital certificates
          </FlexLayout>
          <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.secondColumn)}>
            Can sign
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="row" className={classNames(classes.row, classes.headerRow)}>
          <FlexLayout alignItems="center" className={classes.firstColumn}>
            Names and job titles
          </FlexLayout>
          <FlexLayout flexDirection="row" className={classes.secondColumn}>
            {/*// ! hide trust Account for V1 WEB-2984*/}
            {/* <FlexLayout flexDirection="column" alignItems="center" className={classNames(classes.checkContainer)}>
              <span className={classes.permissionCell}>Trust account</span>
            </FlexLayout> */}
            <FlexLayout flexDirection="column" alignItems="center" className={classNames(classes.checkContainer)}>
              <span className={classes.permissionCell}>Documents</span>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </React.Fragment>
    );
  }

  private renderPeople() {
    const { classes, people, onAddPerson } = this.props;
    if (people.length === 0) {
      return (
        <FlexLayout flexDirection="row" className={classNames(classes.row)}>
          <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.firstColumn}>
            All signers will require a digital certificate.{' '}
            <span tabIndex={0} role="button" onClick={onAddPerson} className={classNames(classes.personName, classes.clickable)} onKeyPress={() => {}}>
              Add a new signer
            </span>
          </FlexLayout>
          <FlexLayout flexDirection="row" className={classes.secondColumn}>
            {/*// ! hide trust Account for V1 WEB-2984*/}
            {/* <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classNames(classes.checkContainer)} /> */}
            <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classNames(classes.checkContainer)} />
          </FlexLayout>
        </FlexLayout>
      );
    }
    return people.map((person, idx) => (
      <FlexLayout key={person.firstName + idx} flexDirection="row" className={classNames(classes.row)}>
        <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.firstColumn}>
          <FlexLayout flexDirection="row" justifyContent="center">
            {this.props.onDeletePerson ? (
              <IconButton data-person-index={idx} className={classes.deleteButton} onClick={this.handleOnDeletePerson} size="large">
                <IconDelete className={classes.deleteIcon} />
              </IconButton>
            ) : null}
            <FlexLayout flexDirection="column" justifyContent="center" className={classes.personDetail}>
              <span
                tabIndex={0}
                role="button"
                className={classNames(classes.personName, !!this.props.onEditPerson && classes.clickable)}
                data-person-index={idx}
                onClick={this.handleOnEditPerson}
                onKeyPress={() => {}}
              >
                {person.firstName}
              </span>

              <span className={classes.description}>{person.jobTitle}</span>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="row" className={classes.secondColumn}>
          {/* // ! hide trust Account for V1 WEB-2984*/}
          {/* <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classNames(classes.checkContainer)}>
            <div className={classes.permissionCell}>{person.canSignTrustAccounts.length ? <Icon color="primary">check</Icon> : null}</div>
          </FlexLayout> */}
          <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classNames(classes.checkContainer)}>
            <div className={classes.permissionCell}>{!!person.allowedJurisdictionsForDocumentSigning.length ? <Icon color="primary">check</Icon> : null}</div>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    ));
  }

  private handleOnEditPerson = (e: React.MouseEvent<HTMLElement>) => {
    const personIndex = Number(dataAttribute('person-index', e));
    if (this.props.onEditPerson) {
      this.props.onEditPerson!(personIndex);
    }
  };
  private handleOnDeletePerson = (e: React.MouseEvent<HTMLElement>) => {
    const personIndex = Number(dataAttribute('person-index', e));
    if (this.props.onDeletePerson) {
      this.props.onDeletePerson!(personIndex);
    }
  };
}

export default withStyles(styles)(PersonTable);
