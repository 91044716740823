import * as yup from 'yup';

import { isAusMobileNumber } from 'src/containers/onboarding/helper';

import { yupPersonList } from '../../shared/validationSchema';
import { OrganisationTypeEnum } from '../organisation-details/models';

function isSixDigitIfIsFinancialInstitution(this: any, value: string = '') {
  if (this.options.context.organisationType === OrganisationTypeEnum.FinancialInstitution) {
    return Number.isInteger(Number(value)) && value.length === 6;
  }
  return true;
}

function whitespaceShouldNotFollowWithHypenOrPeriod(this: any, value: string = '') {
  return !value.includes(' .') && !value.includes(' -');
}

function whitespaceShouldNotFollowWhitespace(this: any, value: string = '') {
  return !value.includes('  ');
}

function hypenShouldNotFollowWithWhitespace(this: any, value: string = '') {
  return !value.includes('- ');
}

const regPostCode = /^\d*$/;
const regAlphabeticSpace = /^[\sa-zA-Z]*$/;
const regAlphanumericSpecial = /^[\sa-zA-Z0-9!#$%&'()*,-./:;=?@]*$/;
const regUnitNumber = /^[\s0-9a-zA-z/\\]*$/; // alphanumeric + \ + / + spaces
const regStreetNumber = /^[\s0-9a-zA-z/\\]*$/; // alphanumeric + \ + / + spaces

export const yupPhysicalAddress = yup.object({
  propertyName: yup //
    .string()
    .max(35, 'Max length 35')
    .trim()
    .matches(regAlphanumericSpecial, 'Please enter a valid property name.'),
  floorType: yup.string().when('floorNumber', {
    is: val => !!val,
    then: yup.string().required(),
    otherwise: yup.string()
  }),
  floorNumber: yup //
    .string()
    .max(5, 'Max length 5')
    .trim()
    .matches(regAlphanumericSpecial, 'Please enter a valid floor number.'),
  unitType: yup.string().when('unitNumber', {
    is: val => !!val,
    then: yup.string().required(),
    otherwise: yup.string()
  }),
  unitNumber: yup //
    .string()
    .max(5, 'Max length 5')
    .trim()
    .matches(regUnitNumber, 'Please enter a valid unit number.'),
  streetNumber: yup //
    .string()
    .max(12, 'Max length 12')
    .trim()
    .matches(regStreetNumber, 'Please enter a valid street number.'),
  street: yup //
    .string()
    .max(30, 'Max length 30')
    .trim()
    .matches(regAlphabeticSpace, 'Please enter a valid street name.'),
  streetType: yup.string(),
  suffix: yup.string(),
  postcode: yup //
    .string()
    .trim()
    .max(4, 'Max length 4')
    .matches(regPostCode, 'Please enter a valid postcode.')
    .required('Please enter a postcode.'),
  suburb: yup //
    .string()
    .max(35, 'Max length 35')
    .trim()
    .matches(regAlphanumericSpecial, 'Please enter a valid suburb.')
    .required('Please enter a suburb.'),
  state: yup.string().required('Please select a state.')
});

const regPoboxCode = /^\d+$/;

export const yupPostalServiceAddress = yup.object({
  deliveryType: yup.string().required('Required'),
  poBoxNumber: yup //
    .string()
    .trim()
    .matches(regPoboxCode, 'Number only')
    .required('Required'),
  suburb: yup //
    .string()
    .trim()
    .matches(regAlphabeticSpace, 'Please enter valid suburb')
    .required('Required'),
  state: yup //
    .string()
    .trim()
    .required('Required'),
  postcode: yup //
    .string()
    .trim()
    .max(4, 'Max length 4')
    .matches(regPostCode, 'Please enter valid postcode')
    .required('Required')
});

const validationObject = {
  company: yup.object({
    abn: yup
      .string()
      .default('')
      .matches(/^\d{11}$/, 'Abn must be 11 digits')
  }),
  tradingName: yup
    .string()
    .trim()
    .required('Required')
    .max(255, 'Must be 255 characters or less')
    .matches(/^[a-zA-Z0-9!"#$%&'()*,-./:;=?@ ]+$/, 'Only letters, numbers and these special characters are allowed !"#$%&\'()*,-./:;=?@')
    .test('tradingName', 'Must not be a space before hyphen or full stop', whitespaceShouldNotFollowWithHypenOrPeriod)
    .test('tradingName', 'Must not be a space after hyphen', hypenShouldNotFollowWithWhitespace)
    .test('tradingName', 'Must be without consecutive spaces', whitespaceShouldNotFollowWhitespace),
  registeredOffice: yup.object({
    addressId: yup.string().required('Please add an address')
  }),
  principalPlaceOfBusiness: yup.object({
    addressId: yup.string().required('Please add an address')
  }),
  contacts: yup.object({
    mobileNumber: yup //
      .string()
      .required('Required')
      .test('Australian phone number test', 'Please enter valid mobile number', isAusMobileNumber)
  }),
  organisationType: yup //
    .number()
    .min(1, 'Please choose your organisation type')
    .required('Please choose your organisation type'),
  acln: yup //
    .string()
    .test('acln', 'Your 6 digit ACLN is required.', isSixDigitIfIsFinancialInstitution),
  participationAgreement: yup.object({
    executionMethod: yup //
      .number()
      .min(1, 'Must select a method of execution')
      .required('Must select a method of execution'),
    agreementSigners: yupPersonList
  })
};

export const validationSchema = yup.object(validationObject);
