import * as React from 'react';
import * as yup from 'yup';

import { Action, Dispatch } from 'redux';

import Formik from '@sympli/ui-framework/components/formik';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import Logger from '@sympli/ui-logger';
import PhoneNumber from 'awesome-phonenumber';
import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { pushGlobalError } from 'src/actions/globalErrors';
import DisclaimerFooter from 'src/components/layout/disclaimer-footer';
import { yupPersonObject } from 'src/containers/onboarding/views/onboarding-detail/shared/validationSchema';
import { ApplicationStatusEnum, PersonModel } from 'src/models';
import { JOB_TITLE_OPTIONS, SYMPLI_PRIVACY_POLICY_LINK } from 'src/models/values';
import { ErrorMessageRequestModel } from 'src/reducers/globalErrors';
import Api from 'src/utils/http';

import { IntroPageModel } from './models';
import styles, { ClassKeys } from './styles';

interface IntroStepProps {
  dispatch: Dispatch<Action>;
  onGetStarted: (values: any) => void;
  initialValues: IntroPageModel;
}

type Props = IntroStepProps & WithStyles<ClassKeys>;

class IntroPage extends React.PureComponent<Props> {
  private getInitialValues = (): IntroPageModel => {
    return this.props.initialValues;
  };

  private getValidationSchema() {
    return yup.object({
      registeredPerson: yup.object(yupPersonObject)
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <FlexLayout flexDirection="column" className={classes.root}>
        <div className={classes.container} data-test="intro">
          <Formik
            getInitialValues={this.getInitialValues} //
            validationSchema={this.getValidationSchema} // initialize Yup validation schema
            onPreSubmit={this.handleOnPreSubmit}
            onSubmit={this.handleOnSubmit}
          >
            {(formikProps: FormikProps<IntroPageModel>) => this.renderForm(formikProps)}
          </Formik>
        </div>
        <DisclaimerFooter />
      </FlexLayout>
    );
  }

  private handleOnPreSubmit = (values: IntroPageModel, formikProps: FormikProps<IntroPageModel>) => {
    const mobile = values.registeredPerson.mobileNumber;
    const nb = new PhoneNumber(mobile, 'AU').getNumber(); // get mobile number in format +614XXXXXXXX
    values.registeredPerson.mobileNumber = nb;
    return values.registeredPerson;
  };

  private handleOnSubmit = (introData: PersonModel, formikProps: FormikProps<any>) => {
    return Api.register(introData)
      .then(() => {
        formikProps.setSubmitting(false);
        this.props.onGetStarted({ details: { intro: { registeredPerson: introData } }, applicationStatus: ApplicationStatusEnum.Pending });
      })
      .catch(err => {
        Logger.captureException(err);
        // General network error or something
        let globalError: ErrorMessageRequestModel = {
          id: _uniqueId('error'),
          type: 'warning'
        };

        if (err && err.response && err.response.status === 403) {
          globalError = {
            ...globalError,
            title: 'Could not submit application',
            message: 'Application could not be submitted at this time (limit exceeded). Please try again soon.'
          };
        } else {
          const text: string = err.response.data || err.message;
          globalError = {
            ...globalError,
            title: 'Failed to save data to the server',
            message: text + ' Please try again.'
          };
        }

        this.props.dispatch(pushGlobalError(globalError));
        formikProps.setSubmitting(false);
      });
  };

  private renderForm(formikProps: FormikProps<IntroPageModel>) {
    const { classes } = this.props;
    let name = 'form';
    return (
      <Form data-name={name} className={classes.form}>
        <Typography variant="h2" className={classes.header}>
          Let's get started
        </Typography>
        <Field label="Email" name="registeredPerson.email" component={InputField} className={classNames(classes.fullWidth, classes.inputContainer)} />
        <Field label="First name" name="registeredPerson.firstName" component={InputField} className={classNames(classes.fullWidth, classes.inputContainer)} />
        <Field
          label="Middle name (if applicable)"
          name="registeredPerson.middleName"
          component={InputField}
          className={classNames(classes.fullWidth, classes.inputContainer)}
        />
        <Field label="Last name" name="registeredPerson.lastName" component={InputField} className={classNames(classes.fullWidth, classes.inputContainer)} />
        <FlexLayout justifyContent="space-between">
          <Field
            label="Job title"
            name="registeredPerson.jobTitle"
            options={JOB_TITLE_OPTIONS}
            placeholder="Please select"
            component={SelectField}
            className={classes.inputContainer}
          />
          <Field label="Mobile" placeholder="04XXXXXXXX" name="registeredPerson.mobileNumber" component={InputField} className={classes.inputContainer} />
        </FlexLayout>
        <WizardStepper nextLabel="Submit" disabled={formikProps.isSubmitting} className={classes.stepper}>
          <span className={classes.disclaimer}>
            Your email and mobile number is required for verification purposes and will not be shared with anyone. Please ensure they are correct. View our{' '}
            <a href={SYMPLI_PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
              <b>privacy policy</b>
            </a>
            .
          </span>
        </WizardStepper>
      </Form>
    );
  }
}
export default withStyles(styles)(IntroPage);
