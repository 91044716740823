import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 'unset',
      boxShadow: 'none',
      maxWidth: 360
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      padding: '36px 45px 20px',
      textAlign: 'left',
      '& >h2': {
        fontSize: 18
      }
    },
    titleIcon: {
      width: 26,
      height: 'unset',
      verticalAlign: 'middle',
      fontSize: 18,
      lineHeight: '18px',
      fontWeight: 'normal',
      paddingRight: 8,
      '&.information': {
        color: 'black'
      },
      '&.success': {
        color: 'green'
      },
      '&.warning': {
        color: 'orange'
      },
      '&.error': {
        color: 'red'
      }
    },
    description: {
      padding: '0 45px 28px',
      fontSize: 14
    },
    actions: {
      justifyContent: 'space-between',
      padding: '0 45px 40px',
      margin: '0px 0px'
    },
    closeButton: {
      position: 'absolute',
      right: 2,
      top: 2,
      width: 24,
      height: 24
    },
    closeIcon: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    secondaryButton: {
      margin: '0px 0px',
      textDecoration: 'underlined'
    },
    primaryButton: {
      color: theme.palette.common.white,
      margin: 'auto',
      '&.information': {
        backgroundColor: 'black'
      },
      '&.success': {
        backgroundColor: 'green'
      },
      '&.warning': {
        backgroundColor: 'orange'
      },
      '&.error': {
        backgroundColor: 'red'
      }
    },
    defaultErrorMessage: {
      marginBottom: 20
    }
  });

export type ClassKeys = typeof styles;

export default styles;
