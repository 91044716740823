import { colors } from '@sympli/ui-framework/theme';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    panelSubTitle: {
      paddingBottom: 16,
      borderBottom: '1px solid #D0D0D0'
    },
    formStepper: {
      marginTop: 8
    },
    stepIcon: {
      fill: colors.GREY_40
    },
    stepButtonBold: {
      '& > $stepIcon': {
        fill: theme.palette.common.black
      }
    },
    stepButtonEnable: {
      textDecoration: 'none',
      '& > $stepIcon': {
        fill: theme.palette.primary.main
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
