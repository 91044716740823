import * as React from 'react';

import { MessageNotification } from '@sympli/ui-framework/components/message-notification';
import MessageList from '@sympli/ui-framework/components/message-notification/components/message-list';

interface OwnProps {
  open: boolean;
  title: string | JSX.Element;
  messages?: Array<string | JSX.Element>;
}

type Props = OwnProps;

class ApplicationError extends React.PureComponent<Props> {
  render() {
    const { title, messages = [], open } = this.props;
    return <MessageNotification variant="warning" open={open} primary={title} expandableContent={<MessageList messages={messages} />} />;
  }
}

export default ApplicationError;
