import { colors } from '@sympli/ui-framework/theme';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import formLayoutStyles from '../onboarding-detail/styles';

const styles = (theme: Theme) =>
  createStyles({
    ...formLayoutStyles(theme),
    // * Style for step in progress screen
    // override
    progressPanel: {
      backgroundColor: theme.palette.grey[100],
      padding: 16
    },
    progressStepper: {
      padding: '16px 0px 16px 0px'
    },
    progressStep: {
      display: 'flex',
      alignItems: 'normal',
      height: 41
    },
    progressStepVerticalConnector: {
      marginTop: -14,
      marginBottom: -4,
      height: 55
    },

    progressStepButton: {
      alignItems: 'normal',
      height: 72
    },
    progressStepButtonEnable: {
      textDecoration: 'none',
      '& > $stepIcon': {
        fill: theme.palette.primary.main
      }
    },
    progressVerticalLine: {
      height: 60,
      marginTop: -42,
      marginBottom: -5
    },
    completeFinalStepButton: {},
    stepButtonBold: {
      '& > $stepIcon': {
        fill: theme.palette.common.black
      }
    },
    stepIcon: {
      fill: colors.GREY_40
    }
  });

export type ClassKeys = typeof styles;

export default styles;
