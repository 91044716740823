import { handleActions } from 'redux-actions';

import { PUSH_GLOBAL_ERROR, REMOVE_FIRST_GLOBAL_ERROR, REMOVE_GLOBAL_ERROR_BY_ID } from 'src/actions/globalErrors';

export type ErrorType = 'information' | 'success' | 'warning' | 'error';
export interface ErrorMessageRequestModel {
  id?: string;
  title?: string;
  message?: string;
  type?: ErrorType;
}

export interface GlobalErrorsState {
  queue: Array<ErrorMessageRequestModel>;
}

const initialState: GlobalErrorsState = {
  queue: []
};

const reducer = handleActions<GlobalErrorsState, any>(
  {
    [PUSH_GLOBAL_ERROR.ACTION]: (state, action) => {
      const error: ErrorMessageRequestModel = action.payload;
      if (!error) {
        return state;
      }
      return { ...state, queue: state.queue.concat(error) };
    },
    [REMOVE_FIRST_GLOBAL_ERROR]: (state, action) => {
      return { ...state, queue: state.queue.slice(1) };
    },
    [REMOVE_GLOBAL_ERROR_BY_ID.ACTION]: (state, action) => {
      const id: string = action.payload.id;
      return { ...state, queue: state.queue.filter(item => item.id !== id) };
    }
  },
  initialState
);

export default reducer;
