import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    subTitle: {
      paddingBottom: 13,
      borderBottom: '1px solid #D0D0D0',
      marginBottom: 20,
      position: 'relative',
      fontWeight: 'bold'
    },
    divider: {
      width: '100%',
      marginTop: 16,
      marginBottom: 16
    },
    alignStart: {
      alignItems: 'start'
    },
    alignCenter: {
      alignItems: 'center'
    },
    bold: {
      fontWeight: 'bold'
    },
    fullWidth: {
      width: '100%'
    },
    halfWidth: {
      width: '50%'
    },
    fieldBottom: {
      paddingBottom: 25,
      borderBottom: 'none'
    },
    marginBottom: {
      marginBottom: 30
    },
    lrsJurisdictionContainer: {
      backgroundColor: theme.palette.grey[100],
      padding: 20,
      marginBottom: 30
    },
    lrsJurisdictionFormInfo: {
      width: 248,
      paddingRight: 22
    },
    lrsJurisdictionFormTitle: {
      fontSize: 16,
      verticalAlign: 'middle'
    },
    lrsJurisdictionFormDescription: {
      color: theme.palette.grey[500],
      fontSize: 13,
      paddingTop: 16,
      paddingRight: 16
    },
    lrsJurisdictionFormField: {
      flexGrow: 1
    },
    noMargin: {
      marginBottom: 0
    },

    // the style is copied from tickle-ui, we can lift this up later to the framework level
    lrsContentContainer: {
      border: `1px solid  ${theme.palette.divider}`,
      padding: '10px 16px',
      display: 'flex',
      position: 'relative',
      backgroundColor: theme.palette.grey[100],
      marginTop: 11,
      flexDirection: 'column',
      '&:before, &:after': {
        bottom: '100%',
        left: 28,
        border: 'solid transparent',
        content: '" "',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none'
      },
      '&:before': {
        borderColor: `rgba(208, 208, 208, 0)`,
        borderBottomColor: theme.palette.grey[100],
        borderWidth: 11,
        marginLeft: -11
      },
      '&:after': {
        borderColor: `rgba(255, 255, 255, 0)`,
        borderBottomColor: theme.palette.grey[100],
        borderWidth: 10,
        marginLeft: -10
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;
