import * as React from 'react';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import { AddressStoreModel, AddressTypeEnum } from '@sympli/ui-framework/components/formik/address-field/models';
import { IconPencil } from '@sympli/ui-framework/icons';
import { getAddressLine } from '@sympli/ui-framework/utils/formatters';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  fieldName: string;
  formikProps: FormikProps<any>;
  dialogTitle: string;
  addressStore: AddressStoreModel;
  fixedAddressType?: AddressTypeEnum;
  onAddClick: (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => void;
  onEditClick: (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => void;
}
type Props = OwnProps & WithStyles<ClassKeys>;
class SingleAddressField extends React.PureComponent<Props> {
  get error() {
    const {
      formikProps: { errors },
      fieldName
    } = this.props;
    return _get(errors, fieldName);
  }

  get touch() {
    const {
      formikProps: { touched },
      fieldName
    } = this.props;
    return _get(touched, fieldName);
  }

  get addressId() {
    const {
      formikProps: { values },
      fieldName
    } = this.props;
    return _get(values, fieldName, '');
  }

  get address() {
    const { addressStore } = this.props;
    return addressStore[this.addressId];
  }

  render() {
    const { fieldName, classes } = this.props;
    return (
      <div data-name={fieldName} className={classes.addressFieldContainer}>
        {this.renderContent()}
        {this.touch && (
          <FormHelperText className={classes.errorMessage} error>
            {this.error}
          </FormHelperText>
        )}
      </div>
    );
  }

  private renderContent = () => {
    const { classes } = this.props;
    if (!this.address) {
      return (
        <ButtonLink icon="add" onClick={this.handleOnAddClick} color="inherit">
          Add address
        </ButtonLink>
      );
    }
    return (
      <Typography className={classes.addressLine}>
        {getAddressLine(this.address.detail)}
        <ButtonLink className={classes.marginLeft} icon={<IconPencil />} onClick={this.handleOnEditClick} color="inherit">
          Edit
        </ButtonLink>
      </Typography>
    );
  };

  private handleOnAddClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { fieldName, dialogTitle, fixedAddressType } = this.props;
    this.props.onAddClick(fieldName, this.addressId, dialogTitle, fixedAddressType);
  };

  private handleOnEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { fieldName, dialogTitle, fixedAddressType } = this.props;
    this.props.onEditClick(fieldName, this.addressId, dialogTitle, fixedAddressType);
  };
}

export default withStyles(styles)(SingleAddressField);
