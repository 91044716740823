import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { AccountDetailsModel, BLANK_PERSON_ITEM, DEFAULT_ACCOUNT_DETAILS_ITEM, DEFAULT_PERSON_ITEM, PersonModel } from 'src/models';

import { getGuid } from '../../../../helper';

export interface AccountHolderEntityModel extends PersonModel {}
export const DEFAULT_ACCOUNT_HOLDER_ITEM: AccountHolderEntityModel = Object.assign({}, DEFAULT_PERSON_ITEM);

export interface DirectDebitAccountEntityModel {
  id: string;
  accountDetails: AccountDetailsModel;
  accountHolders: Array<AccountHolderEntityModel>;
}
export interface LoanAccountEntityModel {
  id: string;
  accountDetails: AccountDetailsModel;
}
export interface DirectDebitAccountModel {
  accounts: Array<DirectDebitAccountEntityModel>;
}

export function getDefaultDirectDebitAccountItem(): DirectDebitAccountEntityModel {
  return {
    id: getGuid(),
    accountDetails: Object.assign({}, DEFAULT_ACCOUNT_DETAILS_ITEM),
    accountHolders: [Object.assign({}, BLANK_PERSON_ITEM)]
  };
}

export function getDefaultLoanAccountItem(): LoanAccountEntityModel {
  return {
    id: getGuid(),
    accountDetails: Object.assign({}, DEFAULT_ACCOUNT_DETAILS_ITEM)
  };
}

export const DEFAULT_DIRECT_DEBIT_ACCOUNT_VALUES: DirectDebitAccountModel = {
  accounts: [getDefaultDirectDebitAccountItem()]
};

export const MOCK_UP_DIRECT_DEBIT_ACCOUNT_VALUES: DirectDebitAccountModel = {
  accounts: [
    {
      id: 'e3dea0f5-37f2-4d79-ae58-490af3228069',
      accountDetails: {
        bsbNumber: '062-693',
        accountNumber: '999888777',
        bankName: 'Bank Test',
        accountName: '0.COMPANY TEST PTY.LTD'
      },
      accountHolders: [
        {
          existingOrNew: ADD_NEW,
          firstName: '0.0Peter',
          lastName: 'Kelemen',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        },
        {
          existingOrNew: 'organisationDetails.participationAgreement.agreementSigners[1]',
          firstName: '',
          lastName: '',
          jobTitle: '',
          email: '',
          mobileNumber: ''
        }
      ]
    },
    {
      id: '3e8d1c2b-f9f9-4a74-a8af-a4e8bebea438',
      accountDetails: {
        bsbNumber: '062-693',
        accountNumber: '999888777',
        bankName: 'Bank Test',
        accountName: '1.COMPANY TEST PTY.LTD'
      },
      accountHolders: [
        {
          existingOrNew: ADD_NEW,
          firstName: '1.0directdebit',
          lastName: 'directDebit',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        },
        {
          existingOrNew: ADD_NEW,
          firstName: '1.2directdebit',
          lastName: 'directDebit',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        }
      ]
    },
    {
      id: 'eefbada8-efee-439a-af83-a80dde423dd6',
      accountDetails: {
        bsbNumber: '062-693',
        accountNumber: '999888777',
        bankName: 'Bank Test',
        accountName: '2.COMPANY TEST PTY.LTD'
      },
      accountHolders: [
        {
          existingOrNew: ADD_NEW,
          firstName: '2.0directdebit',
          lastName: 'Kelemen',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        },
        {
          existingOrNew: ADD_NEW,
          firstName: '2.1directdebit',
          lastName: 'directDebit',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        },
        {
          existingOrNew: ADD_NEW,
          firstName: '2.2directdebit',
          lastName: 'directDebit',
          jobTitle: 'Secretary',
          email: 'peter@kelemen.com',
          mobileNumber: '0416252735'
        }
      ]
    }
  ]
};
