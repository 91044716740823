export enum ErrorVariantEnum {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info'
}

export interface UserInfo {
  givenName: string;
  middleName: string;
  surname: string;
  abn: string;
  email: string;
}

export interface GatekeepersResponse {
  accreditedGatekeepers: string[];
}
