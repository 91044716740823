import { createAction } from 'redux-actions';
import { defineAction } from 'redux-define';

import { ErrorMessageRequestModel } from 'src/reducers/globalErrors';

export const PUSH_GLOBAL_ERROR = defineAction('PUSH_GLOBAL_ERROR');
export const pushGlobalError = createAction<ErrorMessageRequestModel>(PUSH_GLOBAL_ERROR.ACTION);

export const REMOVE_FIRST_GLOBAL_ERROR = defineAction('REMOVE_FIRST_GLOBAL_ERROR');
export const removeFirstGlobalError = createAction(REMOVE_FIRST_GLOBAL_ERROR.ACTION);

export const REMOVE_GLOBAL_ERROR_BY_ID = defineAction('REMOVE_GLOBAL_ERROR_BY_ID');
export const removeGlobalErrorById = createAction(REMOVE_GLOBAL_ERROR_BY_ID.ACTION);
