import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';

import { DEFAULT_COMPANY_VALUE, DEFAULT_PERSON_ITEM, OrganisationNameModel, PersonModel, WithAddressStore } from 'src/models';

export interface AgreementSignerEntityModel extends PersonModel {}
export const DEFAULT_AGREEMENT_SIGNER_ITEM: AgreementSignerEntityModel = Object.assign({}, DEFAULT_PERSON_ITEM);

export enum OrganisationTypeEnum {
  Empty = 0,
  LawPractice = 1,
  ConveyancingPractice = 2,
  SettlementAgent = 3,
  FinancialInstitution = 4,
  PropertyDeveloper = 5,
  GovernmentBody = 6,
  Other = 7
}

export const ORGANISATION_TYPE_OPTIONS = [
  { name: 'Please select', id: OrganisationTypeEnum.Empty },
  { name: 'Law Practice', id: OrganisationTypeEnum.LawPractice },
  { name: 'Conveyancing Practice', id: OrganisationTypeEnum.ConveyancingPractice }
  // { name: 'Settlement Agent', id: OrganisationTypeEnum.SettlementAgent },
  // { name: 'Financial Institution', id: OrganisationTypeEnum.FinancialInstitution },
  // { name: 'Property Developer', id: OrganisationTypeEnum.PropertyDeveloper },
  // { name: 'Government Body', id: OrganisationTypeEnum.GovernmentBody },
  // { name: 'Other', id: OrganisationTypeEnum.Other }
];

export enum ExecutionMethodEnum {
  Empty = 0,
  // 1-2 Obsolete
  SoleTrader = 3,
  // 4-5 Obsolete
  Other = 6,
  Partnership = 7,
  Corporation = 8
}

export const EXECUTION_METHOD_OPTIONS = [
  { name: 'Please select', id: ExecutionMethodEnum.Empty },
  { name: 'Sole Trader', id: ExecutionMethodEnum.SoleTrader },
  { name: 'Partnership', id: ExecutionMethodEnum.Partnership },
  { name: 'Corporation', id: ExecutionMethodEnum.Corporation },
  { name: 'Other', id: ExecutionMethodEnum.Other }
];

export interface OrganisationDetailsModel {
  company: OrganisationNameModel;
  tradingName: string;
  registeredOffice: {
    addressId: string;
  };
  principalPlaceOfBusiness: {
    isSameAsRegisteredOffice: boolean;
    addressId: string;
  };
  jurisdictions: Array<string>;
  contacts: {
    mobileNumber: string;
    workNumber: string;
  };
  organisationType: OrganisationTypeEnum;
  acln: string;
  participationAgreement: {
    executionMethod: ExecutionMethodEnum;
    moreInformation: string;
    agreementSigners: Array<AgreementSignerEntityModel>;
  };
  isTrustee: boolean;
  isSubscriberAgent: boolean;
}
export type OrganisationDetailsFormModel = OrganisationDetailsModel & WithAddressStore;

export const DEFAULT_ORGANISATION_DETAIL_VALUES: OrganisationDetailsModel = {
  company: Object.assign({}, DEFAULT_COMPANY_VALUE),
  tradingName: '',
  registeredOffice: {
    addressId: ''
  },
  principalPlaceOfBusiness: {
    isSameAsRegisteredOffice: true,
    addressId: ''
  },
  jurisdictions: [],
  contacts: {
    mobileNumber: '',
    workNumber: ''
  },
  organisationType: OrganisationTypeEnum.Empty,
  acln: '',
  participationAgreement: {
    executionMethod: ExecutionMethodEnum.Empty,
    moreInformation: '',
    agreementSigners: [] // prepolulated person data should be handled in initialiseValue() in form
  },
  isTrustee: false,
  isSubscriberAgent: false
};

export const MOCK_UP_ORGANISATION_DETAIL_VALUES: OrganisationDetailsModel = {
  company: {
    abn: '36092724251',
    organisationName: 'INFOTRACK PTY LIMITED',
    acn: '092724251',
    entityType: 'Australian Private Company'
  },
  tradingName: 'Best company',
  registeredOffice: {
    addressId: ''
  },
  principalPlaceOfBusiness: {
    isSameAsRegisteredOffice: true,
    addressId: ''
  },
  jurisdictions: ['NSW', 'VIC'],
  contacts: {
    mobileNumber: '0450418550',
    workNumber: ''
  },
  organisationType: OrganisationTypeEnum.FinancialInstitution,
  acln: '12121212',
  participationAgreement: {
    executionMethod: ExecutionMethodEnum.Corporation,
    moreInformation: '',
    agreementSigners: [
      {
        existingOrNew: 'intro.registeredPerson',
        firstName: 'Liren',
        lastName: 'Wang',
        jobTitle: 'Director',
        email: 'liren.wang@projecttickle.com.au',
        mobileNumber: '0416939123'
      },
      {
        existingOrNew: ADD_NEW,
        firstName: 'john',
        middleName: 'middel',
        lastName: 'Smith',
        jobTitle: 'Secretary',
        email: '1stSec@email.com',
        mobileNumber: '2663743'
      }
    ]
  },
  isTrustee: false,
  isSubscriberAgent: false
};
