import * as React from 'react';

import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import WorkflowStepper from '@sympli/ui-framework/components/workflow-stepper';
import dateformat from 'dateformat';
import _get from 'lodash-es/get';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import Body from 'src/components/layout/body';
import { OnboardingFullModel } from 'src/containers/onboarding/models';
import ProgressStepLabel from 'src/containers/onboarding/views/registration-in-progress/components/progress-step-label';
import { ApplicationStatusEnum, DocumentApiItemModel, DocumentStatusEnum, ReviewProgressStatusEnum } from 'src/models';
import Api from 'src/utils/http';

import ReviewApplicantDetails from '../onboarding-detail/steps/review-registration/components/review-applicant-details';
import ReviewDirectDebitAccount from '../onboarding-detail/steps/review-registration/components/review-direct-debit-account';
import ReviewDocuments from '../onboarding-detail/steps/review-registration/components/review-documents';
import ReviewLandRegistry from '../onboarding-detail/steps/review-registration/components/review-land-registry';
import ReviewOrganisationDetail from '../onboarding-detail/steps/review-registration/components/review-organisation-detail';
import Section from '../onboarding-detail/steps/review-registration/components/section';
import VOITable from '../onboarding-detail/steps/review-registration/components/voi-table/VOITable';
import ProgressHeader from './components/progress-header';
import SupportingDocuments from './components/supporting-documents/SupportingDocuments';
import styles, { ClassKeys } from './styles';

interface ProgressDocuments {
  reuploadDocuments: Array<DocumentApiItemModel>;
  completeDocuments: Array<DocumentApiItemModel>;
  additionalDocuments: Array<DocumentApiItemModel>;
}

interface RegistrationInProgressStepProps {
  onEditClick: (e: React.MouseEvent<HTMLButtonElement>, stepValue: number) => void;
  onboardValues: OnboardingFullModel;
}

type Props = RegistrationInProgressStepProps & WithStyles<ClassKeys>;

interface State extends ProgressDocuments {
  isLoadingDocuments: boolean;
}

class RegistrationInProgress extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isLoadingDocuments: true, // not showing anything before documents loads
    reuploadDocuments: [],
    completeDocuments: [],
    additionalDocuments: []
  };

  componentDidMount() {
    this.fetchDocuments();
  }

  private fetchDocuments() {
    return Api.getDocumentsList().then(documents => {
      const progressDocuments = this.initialiseDocuments(documents);
      this.setState({ ...progressDocuments, isLoadingDocuments: false });
    });
  }

  get isApplicationHasIssues() {
    // is there applicationError, or reupload documents
    const hasErrors = !!(this.props.onboardValues.applicationErrors && this.props.onboardValues.applicationErrors.length);
    const hasReuploadDocuments = !!this.state.reuploadDocuments.length;
    return hasErrors || hasReuploadDocuments;
  }

  get isVOIRequired() {
    return _get(this.props.onboardValues, 'reviewProgress.progressStatus') === ReviewProgressStatusEnum.VoiEmailSent;
  }

  get isSupportingDocumentsRequired() {
    return !!this.state.additionalDocuments.length;
  }

  get isApplicantHasNextStep() {
    // if there are issues, or voi, or supporting documents ( applicant action required )
    return this.isApplicationHasIssues || this.isVOIRequired || this.isSupportingDocumentsRequired;
  }

  private initialiseDocuments(documents: Array<DocumentApiItemModel>): ProgressDocuments {
    const progressDocuments: ProgressDocuments = {
      reuploadDocuments: [],
      completeDocuments: [],
      additionalDocuments: []
    };

    documents.forEach(document => {
      // Orders matters
      if (document.status === DocumentStatusEnum.Reviewing) {
        // Reviewing Documents are completed documents
        // being reviewed in internal onboarding and locked
        progressDocuments.completeDocuments.push(document);
      } else if (!!document.error) {
        // Every document with error is reupload document
        // no matter what status/category/groupname it is
        progressDocuments.reuploadDocuments.push(document);
      } else if (document.status === DocumentStatusEnum.Missing || document.status === DocumentStatusEnum.Uploaded) {
        // Additional documents are the rest documents which is
        // - 'Missing' documents required upload
        // - 'Uploaded' documents which have not been submitted
        progressDocuments.additionalDocuments.push(document);
      }
    });

    // Clear the documents bucket when the application is rejected
    // TODO using api filtering in fetchDocument
    if (this.props.onboardValues.applicationStatus === ApplicationStatusEnum.Rejected) {
      progressDocuments.reuploadDocuments = [];
      progressDocuments.additionalDocuments = [];
    }
    return progressDocuments;
  }

  render() {
    return <Body mainBody={this.renderDetails()} panelBody={this.renderPanel()} />;
  }

  renderDetails() {
    const {
      onboardValues: { details }
    } = this.props;

    return (
      <React.Fragment>
        {this.renderFirstSection()}
        <Section label="Applicant" toggleable>
          <ReviewApplicantDetails applicant={details.intro.registeredPerson} />
        </Section>
        <Section label="Your organisation" toggleable>
          <ReviewOrganisationDetail values={details.organisationDetails} applicant={details.intro.registeredPerson} addressStore={details.addressStore} />
        </Section>
        <Section label="Land registry correspondence" toggleable>
          <ReviewLandRegistry landRegistry={details.landRegistry} addressStore={details.addressStore} />
        </Section>
        <Section label="Direct debit account" toggleable>
          <ReviewDirectDebitAccount directdebitAccount={details.directDebitAccount} />
        </Section>
        {/*// ! hide trust account for V1 WEB-2984 */}
        {/* <Section label="Trust account" toggleable>
          <ReviewTrustAccount trustAccount={details.trustAccount} />W
        </Section> */}
        <Section label={`Individuals requiring verification of identity (VOI)`} toggleable>
          <VOITable VOIPeople={details.signers.voiPeople} />
        </Section>
        {/*// ! hide digicert for V1 WEB-2984*/}
        {/* <Section label="Signers" toggleable>
          <PersonTable people={details.signers.signersList} />
          <p>Individuals requiring verification of identity (VOI)</p>
          <VOITable VOIPeople={details.signers.voiPeople} />
        </Section> */}
        <Section label="Uploaded documents" toggleable>
          <ReviewDocuments subTitle="Complete list of documents:" documents={this.state.completeDocuments} />
        </Section>
      </React.Fragment>
    );
  }

  private renderFirstSection() {
    if (this.state.isLoadingDocuments) {
      return (
        <div style={{ height: 200, width: '100%', boxSizing: 'border-box' }}>
          <BlockLoader />
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.renderHeader()}
        {this.renderTodoSection()}
      </React.Fragment>
    );
  }

  private renderHeader() {
    const {
      onboardValues: { applicationStatus, details, reviewProgress }
    } = this.props;
    return (
      <ProgressHeader
        applicationStatus={applicationStatus}
        contactEmail={details.intro.registeredPerson.email}
        isApplicationHasError={this.isApplicationHasIssues}
        isVOIRequired={this.isVOIRequired}
        voiType={details.signers.voiType}
        reviewProgress={reviewProgress}
      />
    );
  }

  private renderTodoSection() {
    let editApplicationPanel;
    let voiPanel;
    let supportingDocuments;
    /*
    ! Remove, because the workflow does not support reupl
    ! https://projects.invisionapp.com/d/main#/console/12764713/310910104/previewoad and edit functionality 
    if (this.isApplicationHasIssues) {
      editApplicationPanel = (
        <EditApplicationPanel
          key="editApplication"
          applicationError={this.props.onboardValues.applicationErrors[0]}
          reuploadDocuments={this.state.reuploadDocuments}
          onUploadFile={this.handleOnUploadFile}
          onDeleteFile={this.handleOnDeleteFile}
          onEditClick={this.props.onEditClick}
          onSubmitSuccess={this.handleSubmitDocumentSuccess}
        />
      );
    }
    if (this.isVOIRequired) {
      // ! hide progress page voi panel for V1 WEB-2984
      // voiPanel = <VOIPanel key="voiPanel" forms={this.props.onboardValues.forms} />;
    }
    */
    if (this.isSupportingDocumentsRequired) {
      supportingDocuments = (
        <SupportingDocuments
          key="supportingDocuments"
          supportingDocuments={this.state.additionalDocuments}
          forms={this.props.onboardValues.forms}
          onUploadFile={this.handleOnUploadFile}
          onDeleteFile={this.handleOnDeleteFile}
          onSubmitSuccess={this.handleSubmitDocumentSuccess}
        />
      );
    }
    return [editApplicationPanel, voiPanel, supportingDocuments];
  }

  private handleSubmitDocumentSuccess = () => {
    this.setState({ isLoadingDocuments: true });
    this.fetchDocuments();
  };

  private handleOnUploadFile = (data: FormData, onUploadProgress: (progressEvent: any) => void) => {
    return Api.uploadFile(data, onUploadProgress);
  };

  private handleOnDeleteFile = (fileName, fileGroup) => {
    const groupName = fileGroup.groupName;
    const subGroupName = fileGroup.subGroupName;
    return Api.deleteDocument(fileName, groupName, subGroupName);
  };

  private renderPanel() {
    const {
      classes,
      onboardValues: { applicationStatus, dateCompleted, dateCreated }
    } = this.props;
    const submitTime = dateCompleted ? dateformat(dateCompleted, `ddd d mmm yyyy, a't' h:MM TT`) : '';
    const approveTime = dateCreated ? dateformat(dateCreated, `ddd d mmm yyyy, a't' h:MM TT`) : '';
    const steps = [
      {
        label: <ProgressStepLabel name="Application received" description={approveTime} />,
        value: ApplicationStatusEnum.Received
      },
      {
        label: <ProgressStepLabel name="Under review" description={this.resolveReviewStepWording()} />,
        value: ApplicationStatusEnum.Reviewing
      },
      {
        label: <ProgressStepLabel name="Application approval" description={submitTime} />,
        value: ApplicationStatusEnum.Approved
      }
    ];

    const overrideClasses = {
      stepButtonBold: classes.stepButtonBold,
      stepButton: classes.progressStepButton,
      verticalLine: classes.progressVerticalLine,
      stepButtonEnable: classes.progressStepButtonEnable,
      stepIcon: classes.stepIcon
    };
    return (
      <div>
        <div className={classes.progressPanel}>
          <Typography variant="subtitle1">Application progress:</Typography>
          <WorkflowStepper
            className={classes.progressStepper}
            classes={overrideClasses}
            orientation="vertical"
            steps={steps}
            selectedStepValue={applicationStatus}
          />
        </div>
      </div>
    );
  }

  // complex conditions for progress wording for different status
  private resolveReviewStepWording() {
    const { onboardValues } = this.props;
    // if (onboardValues.applicationStatus === ApplicationStatusEnum.Approved) {
    //   return '100% completed';
    // }
    if (this.isApplicantHasNextStep) {
      return 'Awaiting applicant on next steps';
    } else {
      if (!onboardValues.reviewProgress) {
        // default if not having reviewProgress prop
        return 'Ready for review';
      }

      if (onboardValues.reviewProgress.progressStatus === ReviewProgressStatusEnum.NotStarted) {
        return 'Ready for review';
      }
      // if there is a progress value
      if (onboardValues.applicationStatus === ApplicationStatusEnum.Approved) {
        return `Reviewed`;
      }
      return `Reviewing`;
      // return `${onboardValues.reviewProgress.progressPercentage} completed`;
    }
  }
}

export default withStyles(styles)(RegistrationInProgress);
