import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import classNames from 'classnames';
import _get from 'lodash-es/get';

import Icon from '@mui/material/Icon';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { VOIPersonModel } from 'src/models';

import styles, { ClassKeys } from './styles';

interface VOITableProps {
  VOIPeople: Array<VOIPersonModel>;
  className?: string;
}

type Props = VOITableProps & WithStyles<ClassKeys>;

class VOITable extends React.PureComponent<Props> {
  render() {
    return (
      <FlexLayout flexDirection="column" className={classNames(this.props.classes.root, this.props.className)}>
        {this.renderHeader()}
        {this.renderVOI()}
      </FlexLayout>
    );
  }

  private renderHeader() {
    const { classes, VOIPeople } = this.props;
    const hasStatus = !!_get(VOIPeople, '[0].status');
    return (
      <React.Fragment>
        <FlexLayout alignItems="center" className={classNames(classes.row, classes.headerRow)}>
          <FlexLayout alignItems="center" className={classNames(classes.firstColumn)}>
            Individuals
          </FlexLayout>
          <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.secondColumn)}>
            Reasons for VOI
          </FlexLayout>
          {hasStatus && (
            <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.thirdColumn)}>
              Status
            </FlexLayout>
          )}
        </FlexLayout>
        <FlexLayout flexDirection="row" className={classNames(classes.row, classes.headerRow)}>
          <FlexLayout alignItems="center" className={classes.firstColumn}>
            Names and job titles
          </FlexLayout>
          <FlexLayout flexDirection="row" className={classes.secondColumn}>
            <FlexLayout flexDirection="column" alignItems="center" className={classNames(classes.checkContainer)}>
              <span className={classes.permissionCell}>Subscriber agreement</span>
            </FlexLayout>
          </FlexLayout>
          {hasStatus && (
            <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.thirdColumn)}>
              <span className={classes.permissionCell}>VOI appointment</span>
            </FlexLayout>
          )}
        </FlexLayout>
      </React.Fragment>
    );
  }

  private renderVOI() {
    const { classes, VOIPeople } = this.props;
    return VOIPeople.map((person, idx) => {
      const status = _get(person, 'status');
      return (
        <FlexLayout key={person.firstName + idx} flexDirection="row" className={classNames(classes.row)}>
          {/* First column */}
          <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.firstColumn}>
            <FlexLayout flexDirection="row" justifyContent="center">
              <FlexLayout flexDirection="column" justifyContent="center" className={classes.personDetail}>
                <span className={classes.personName} data-person-index={idx}>
                  {`${person.firstName} ${person.lastName}`}
                </span>
                <span className={classes.description}>{person.jobTitle}</span>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          {/* Second column */}
          <FlexLayout flexDirection="row" className={classes.secondColumn}>
            <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classNames(classes.checkContainer)}>
              <div className={classes.permissionCell}>
                {person.isParticipationAgreementSigner ? (
                  <span>
                    <Icon color="disabled">check</Icon> Signatory
                  </span>
                ) : null}
              </div>
            </FlexLayout>
          </FlexLayout>
          {/* Third column */}
          {status && (
            <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classes.thirdColumn}>
              <FlexLayout flexDirection="row" justifyContent="center" alignItems="center">
                <div className={classes.permissionCell}>{status}</div>
              </FlexLayout>
            </FlexLayout>
          )}
        </FlexLayout>
      );
    });
  }
}

export default withStyles(styles)(VOITable);
