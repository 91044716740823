import { ApplicationErrorModel, ApplicationStatusEnum, FormModel, ReviewProgressModel } from 'src/models';

import { IntroPageModel } from './views/intro/models';
import { DEFAULT_ONBOARDING_DETAIL_VALUES, OnboardingDetailsModel } from './views/onboarding-detail/models';
import { DirectDebitAccountModel } from './views/onboarding-detail/steps/direct-debit-account/models';
import { OrganisationDetailsModel } from './views/onboarding-detail/steps/organisation-details/models';
import { ReviewModel } from './views/onboarding-detail/steps/review-registration/models';
import { SignersModel } from './views/onboarding-detail/steps/signers/models';
import { TrustAccountModel } from './views/onboarding-detail/steps/trust-account/models';
import { Feature } from 'src/components/feature-toggle/models';

export interface OnboardingFullModel {
  details: OnboardingDetailsModel;
  applicationErrors?: Array<ApplicationErrorModel>;
  applicationStatus: ApplicationStatusEnum;
  forms?: Array<FormModel>;
  reviewProgress?: ReviewProgressModel;
  dateCompleted?: string;
  dateCreated?: string;
  features: Feature;
}

export type OnboardingPageStepsModelType =
  | OnboardingDetailsModel
  | IntroPageModel
  | OrganisationDetailsModel
  | SignersModel
  | DirectDebitAccountModel
  | TrustAccountModel
  | ReviewModel;

export const onboardingDefaultValues: OnboardingFullModel = {
  details: DEFAULT_ONBOARDING_DETAIL_VALUES,
  applicationErrors: [],
  applicationStatus: ApplicationStatusEnum.Intro,
  features: { names: [] }
};
