import * as React from 'react';

import SelectField from '@sympli/ui-framework/components/formik/select-field';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import { Field, FormikProps } from 'formik';
import _debounce from 'lodash-es/debounce';
import _get from 'lodash-es/get';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import PersonEditor from '../person-editor/PersonEditor';
import styles, { ClassKeys } from './styles';

interface PersonPickerProps {
  primaryFieldLabel?: string;

  debounceLimit?: number;
  fieldName?: string; // field name
  mode?: 'plain' | 'detailBox';
  options: Array<LookupEnumModel | LookupItemModel>;
  openDetail: boolean;
  allowSelection: boolean;
  onChange?: (name: string, e: React.ChangeEvent<HTMLInputElement>, resolvedValue: string, formikProps: FormikProps<any>) => void;
  onSelectChange?: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string, formikProps: FormikProps<any>, fieldName: string) => void;

  formikProps: FormikProps<any>; // * Provide walk around for the case when the selected person references is not exist
}

type Props = PersonPickerProps & WithStyles<ClassKeys>;

// this can't be PureComponent otherwise Field component would not get updated due to context vs shouldComponentUpdate functionality
class PersonPicker extends React.Component<Props> {
  private handleOnChange: (name: any) => void;
  private nameWithDot: string;

  public static defaultProps: Partial<Props> = {
    mode: 'detailBox'
  };

  constructor(props: Props) {
    super(props);

    this.nameWithDot = !this.props.fieldName ? '' : this.props.fieldName + '.';
    // whenever the something has changed about the person, we trigger onChange, because we wanna inform parent
    // that there was a change due to the fact that some other person picker might be referencing this person
    if (props.onChange) {
      if (props.debounceLimit) {
        this.handleOnChange = _debounce(props.onChange, props.debounceLimit).bind(this, props.fieldName);
      } else {
        this.handleOnChange = props.onChange.bind(this, props.fieldName);
      }
    }
  }

  // componentWillReceiveProps(nextProps: Props) {
  //   if (nextProps.fieldName !== this.props.fieldName) {
  //     this.nameWithDot = !nextProps.fieldName ? '' : nextProps.fieldName + '.';
  //   }
  // }

  componentDidUpdate(prevProps: Props) {
    const { fieldName } = this.props;
    if (fieldName !== prevProps.fieldName) {
      this.nameWithDot = fieldName ? `${fieldName}.` : '';
    }
  }

  render() {
    const { options, allowSelection, classes } = this.props;
    this.checkPersonReference();
    return (
      <React.Fragment>
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Field
            name={`${this.nameWithDot}existingOrNew`}
            label="Use previously entered name or add new name"
            component={SelectField}
            options={options}
            placeholder="Please select"
            onChange={this.handleOnSelectionChange}
            disabled={!allowSelection}
            className={classes.fullWidth}
          />
        </div>

        {this.renderDetail()}
      </React.Fragment>
    );
  }

  private handleOnSelectionChange = (...args: Array<any>) => {
    // onChange args list
    const event: React.ChangeEvent<HTMLInputElement> = args[0];
    const resolvedValue: string = args[1];
    const formikProps: FormikProps<any> = args[2];

    const { fieldName } = this.props;
    if (this.props.onSelectChange) {
      this.props.onSelectChange(event, resolvedValue, formikProps, fieldName || '');
    }
  };

  private renderDetail = () => {
    const { openDetail, mode, classes } = this.props;
    if (!openDetail) {
      return null;
    }

    return (
      <div className={mode === 'detailBox' ? classes.detailBoxContainer : ''}>
        <PersonEditor prefixWithDot={this.nameWithDot} onChange={this.handleOnChange} />
      </div>
    );
  };

  // * Error proof for worst case, when person reference is invalid
  private checkPersonReference() {
    const {
      formikProps: { values, setFieldValue },
      options
    } = this.props;
    const referencePath = `${this.nameWithDot}existingOrNew`;
    const ref = _get(values, referencePath);
    // if ref is not empty and ref is not in options list, then its an invalid ref
    if (ref !== '' && !options.some(op => op.id === ref)) {
      setFieldValue(referencePath, '');
    }
  }
}
export default withStyles(styles)(PersonPicker);
