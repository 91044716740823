import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import styles, { ClassKeys } from './styles';

interface LineItemProps {
  title: string;
  noBottomLine?: boolean;
}

type Props = LineItemProps & WithStyles<ClassKeys>;

class LineItem extends React.PureComponent<React.PropsWithChildren<Props>> {
  public static defaultProps: Partial<Props> = {
    noBottomLine: false
  };
  render() {
    const { title, classes, children, noBottomLine } = this.props;
    return (
      <>
        <FlexLayout alignItems="baseline" className={classes.sectionContainer}>
          <Typography className={classes.sectionHeader}>{title}</Typography>
          <div className={classes.sectionContent}>{children}</div>
        </FlexLayout>
        {noBottomLine || <Divider />}
      </>
    );
  }
}

export default withStyles(styles)(LineItem);
