import Logger, { SeverityEnum } from '@sympli/ui-logger';

export interface RuntimeEnv {
  RUNTIME_ENV: string;
  TARGET_MACHINE: string;
}

let TARGET_MACHINE = import.meta.env.VITE_TARGET_MACHINE || '';

function isProd(env: string) {
  return ~['blue', 'green', 'prod', ''].indexOf(env);
}

export default function runtimeEnvResolver(): RuntimeEnv {
  const HOSTNAME = window.location.hostname;
  // labs and localhost env
  if (!~HOSTNAME.toLowerCase().indexOf('sympli.com.au')) {
    return {
      RUNTIME_ENV: 'localhost',
      TARGET_MACHINE: TARGET_MACHINE
    };
  }

  const domains = HOSTNAME.split('.');
  const subDomain = domains[1].toLowerCase();
  const ENV = subDomain !== 'sympli' ? subDomain : '';
  Logger.console(SeverityEnum.Info, `Resolved ENV: ${ENV}`);

  const SEARCH = '*';
  const REPLACE = ENV.length ? `.${ENV}.` : '.';

  const RUNTIME_ENV = isProd(ENV) ? 'production' : ENV;
  Logger.console(SeverityEnum.Info, `Resolved RUNTIME_ENV: ${RUNTIME_ENV}`);

  TARGET_MACHINE = TARGET_MACHINE.replace(SEARCH, REPLACE);

  return {
    RUNTIME_ENV,
    TARGET_MACHINE: TARGET_MACHINE
  };
}
