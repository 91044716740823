import * as React from 'react';

import AddressFormDialog from '@sympli/ui-framework/components/dialogs/address-form-dialog';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import Formik from '@sympli/ui-framework/components/formik';
import { AddressFormValueModel, AddressTypeEnum, DEFAULT_PHYSICAL_ADDRESS_ITEM } from '@sympli/ui-framework/components/formik/address-field/models';
import { ADD_NEW, JURISDICTION_STATE_OPTIONS, StateEnum, SUPPORTED_STATES } from '@sympli/ui-framework/components/formik/address-field/values';
import CheckboxGroupField from '@sympli/ui-framework/components/formik/checkbox-group-field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import classNames from 'classnames';
import { Field, Form, FormikProps, setIn } from 'formik';
import _get from 'lodash-es/get';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { Feature } from 'src/components/feature-toggle/models';
import Header from 'src/components/layout/header';
import ScrollToFormikError from 'src/components/scroll-to-formik-error';
import { getGuid } from 'src/containers/onboarding/helper';

import SingleAddressField from '../../components/single-address-field/SingleAddressField';
import { collectAddressFromPreviousSteps, collectPersonsFromPreviousSteps } from '../../helpers';
import { OnboardingDetailsModel, OnboardingFormStepsEnum } from '../../models';
import PersonComponentBase from '../person-component-base';
import PersonPicker from '../signers/components/person-picker/PersonPicker';
import { getDefaultLandRegistryEntity, LandRegistryFormikModel, StateLandRegistryViewModel } from './models';
import styles, { ClassKeys } from './styles';
import { validationSchema } from './validationSchema';

interface LandRegistryProps {
  step: number;
  onSave: (data: { valuesFromCurrentStep: any; allValues?: OnboardingDetailsModel }, formikProps: FormikProps<any>) => Promise<any>;
  onStepChange: (shift: number, values?: object) => void;
  getInitialValues: (step?: number) => any;
  features: Feature;
}
type Props = LandRegistryProps & WithStyles<ClassKeys>;

// TODO JUN move to redux
interface State {
  openAddressDialog: boolean;
  addressFieldName: string;
  dialogTitle: string;
  fixedAddressType?: AddressTypeEnum;
  jurisdictions: Array<string>;
  addressKey: number;
}

class LandRegistry extends PersonComponentBase<Props, State> {
  private pathsForPersonSection: Array<string>;
  private formikProps: FormikProps<LandRegistryFormikModel>;

  // TODO JUN move to redux
  readonly state: State = {
    addressKey: 1,
    openAddressDialog: false,
    addressFieldName: '',
    dialogTitle: '',
    jurisdictions: []
  };

  constructor(props: Props) {
    super(props);

    this.modelFieldName = 'landRegistry';
    this.allValues = this.props.getInitialValues();
    this.initialisePersonReference();
  }

  get supportedStates() {
    return SUPPORTED_STATES;
  }

  get jurisdictionsStateOptions() {
    return JURISDICTION_STATE_OPTIONS;
  }

  private initialisePersonReference() {
    const previousPersonPaths = ['intro.registeredPerson', 'organisationDetails.participationAgreement.agreementSigners'];
    this.previousPersonOptions = collectPersonsFromPreviousSteps(this.allValues, previousPersonPaths);
    this.previousCorrespondenceAddressOption = collectAddressFromPreviousSteps(this.allValues, 'organisationDetails.principalPlaceOfBusiness.addressId');
    this.previousRegisteredOfficeAddressOption = collectAddressFromPreviousSteps(this.allValues, 'organisationDetails.registeredOffice.addressId');
    const pathsForDirectDebitAccountPersonSection = this.allValues.directDebitAccount.accounts.map((item, idx) => {
      return `directDebitAccount.accounts[${idx}].accountHolders`;
    });
    const pathsForTrustAccountPersonSection = this.allValues.trustAccount.accounts.map((item, idx) => {
      return `trustAccount.accounts[${idx}].accountHolders`;
    });
    this.pathsForSectionInFutureSteps = pathsForDirectDebitAccountPersonSection.concat(pathsForTrustAccountPersonSection).concat(['signers.signersList']);
  }

  private getInitialValues = (): LandRegistryFormikModel => {
    //Already pre-filled jurisdictions
    // * delete jurisdiction and the contact person properly
    // * initial the jurisdictions (cleanup dirty LRS) we need to ask for land registry
    const originalValues = this.props.getInitialValues(OnboardingFormStepsEnum.LandRegistry);
    const intersection = this.allValues.organisationDetails.jurisdictions.filter(x => this.supportedStates.includes(x as StateEnum));
    const jurisdictionsSet = new Set(intersection);
    let states: Array<StateLandRegistryViewModel> = originalValues.states.reduce((acc, item, idx) => {
      const selectedJurisiction = item.id;
      if (jurisdictionsSet.has(selectedJurisiction)) {
        item.show = true;
        return acc.concat(item);
      } else {
        return acc;
      }
    }, []);

    // Other (default) jutisdictions
    const stateArray = this.supportedStates.filter(d => !jurisdictionsSet.has(d));
    //  get all supported states first and then show/hide by the checkbox
    const defaultArray = stateArray.map((state: StateEnum) => getDefaultLandRegistryEntity(state));
    states = states.concat(defaultArray);

    // initial person options with cleaned up states values
    this.pathsForPersonSection = states.reduce((acc, item, idx) => {
      return acc.concat([`allStates[${idx}].financialCorrespondence.contactPerson`, `allStates[${idx}].lodgementCorrespondence.contactPerson`]);
    }, [] as Array<string>);

    const correspondenceAddressId = this.previousCorrespondenceAddressOption.name as string;
    const registeredOfficeAddressId = this.previousRegisteredOfficeAddressOption.name as string;
    const previousCorrespondenceAddressType = this.allValues.addressStore[correspondenceAddressId].detail.type;
    const applicantRefId = this.previousPersonOptions[0].id as string;

    states.forEach(lrs => {
      // Set default person as applicant for LRS
      this.applyDefaultLrsContactPerson(lrs, applicantRefId, 'financialCorrespondence');
      this.applyDefaultLrsContactPerson(lrs, applicantRefId, 'lodgementCorrespondence');
      // Default Correspondence address for LRS
      this.applyDefaultLrsCorrespodence(lrs, correspondenceAddressId, registeredOfficeAddressId, previousCorrespondenceAddressType, 'financialCorrespondence');
      this.applyDefaultLrsCorrespodence(lrs, correspondenceAddressId, registeredOfficeAddressId, previousCorrespondenceAddressType, 'lodgementCorrespondence');
    });

    const lrsNames = {
      entityName: originalValues.entityName,
      businessName: originalValues.businessName
    };
    return {
      states: states.filter(d => d.show),
      addressStore: originalValues.addressStore,
      ...lrsNames,
      lrsJurisdictions: Array.from(jurisdictionsSet),
      allStates: states
    };
  };

  private applyDefaultLrsContactPerson(
    lrs: StateLandRegistryViewModel,
    applicantRefId: string,
    correspondence: 'financialCorrespondence' | 'lodgementCorrespondence'
  ) {
    if (this.previousPersonOptions.length >= 1) {
      if (!lrs[correspondence].contactPerson.existingOrNew) {
        lrs[correspondence].contactPerson.existingOrNew = applicantRefId;
      }
    }
  }

  private applyDefaultLrsCorrespodence(
    lrs: StateLandRegistryViewModel,
    correspondenceAddressId: string,
    registeredOfficeAddressId: string,
    previousCorrespondenceAddressType: AddressTypeEnum,
    correspondence: 'financialCorrespondence' | 'lodgementCorrespondence'
  ) {
    // QLD cannot accept PoBox for correspondence, should default to physical address
    if (!lrs[correspondence].addressId) {
      if (lrs.id === StateEnum.QLD && previousCorrespondenceAddressType === AddressTypeEnum.PoBox) {
        lrs[correspondence].addressId = registeredOfficeAddressId;
      } else {
        lrs[correspondence].addressId = correspondenceAddressId;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header>Land registry and state revenue</Header>
        <AddressFormDialog
          initialValues={{
            isEdit: true,
            existingOrNew: ADD_NEW,
            addressDetail: {
              type: AddressTypeEnum.PhysicalAddress,
              physicalAddress: DEFAULT_PHYSICAL_ADDRESS_ITEM
            }
          }}
          open={this.state.openAddressDialog}
          dialogTitle={this.state.dialogTitle}
          addressStore={_get(this.formikProps, 'values.addressStore')}
          onSubmit={this.handleOnUpdateAddress}
          onCloseDialog={this.handleOnCloseDialog}
          fixedAddressType={this.state.fixedAddressType}
          key={this.state.addressKey}
        />
        <Formik
          getInitialValues={this.getInitialValues} //
          validationSchema={validationSchema} // initialize Yup validation schema
          onSubmit={this.props.onSave}
          onPreSubmit={this.handleOnPreSubmit}
        >
          {(formikProps: FormikProps<LandRegistryFormikModel>) => this.renderForm(formikProps)}
        </Formik>
      </React.Fragment>
    );
  }

  private renderForm(formikProps: FormikProps<LandRegistryFormikModel>) {
    this.formikProps = formikProps;
    return (
      <Form>
        <ScrollToFormikError formikProps={formikProps} />
        {this.renderJurisdictionsSection(formikProps)}
        {this.renderCorrespondences(formikProps)}
        <WizardStepper onBack={this.handleOnBackClick} nextLabel="Save and continue" disabled={formikProps.isSubmitting} />
      </Form>
    );
  }

  private onJurisdictionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const { values } = this.formikProps;
    if (checked) {
      values.lrsJurisdictions.push(value);
      this.toggleStatesDisplay(value, true);
    } else {
      values.lrsJurisdictions = values.lrsJurisdictions.filter(d => d !== value);
      this.toggleStatesDisplay(value, false);
    }
  };

  private toggleStatesDisplay = (jurisdiction: string, show: boolean) => {
    const { values } = this.formikProps;
    values.allStates.forEach(d => {
      if (d.id === jurisdiction) {
        d.show = show;
      }
    });
  };

  private handleOnUpdateAddress = (submitAddressValue: AddressFormValueModel, addressFormik: FormikProps<AddressFormValueModel>) => {
    const { setValues, values, setFieldTouched, touched } = this.formikProps;
    const { existingOrNew, isEdit, addressDetail } = submitAddressValue;
    let newValues: LandRegistryFormikModel = values;
    if (existingOrNew === ADD_NEW) {
      // * for ADD_NEW, append addressStore, set new addressId
      const id = getGuid();
      newValues = setIn(newValues, `addressStore[${id}]`, { id, count: 1, detail: addressDetail });
      newValues = setIn(newValues, this.state.addressFieldName, id);
    } else {
      const selectedId = existingOrNew;
      const currentAddressId = _get(values, this.state.addressFieldName);
      const { addressStore } = values;
      if (currentAddressId === selectedId && isEdit) {
        // * for same addressId, update address detail in addressStore
        newValues = setIn(newValues, `addressStore[${selectedId}]`, { ...addressStore[selectedId], detail: addressDetail });
      } else {
        // * for update addressId
        if (!!currentAddressId) {
          // ! decrement counter, for removing address reference for currentAddressId
          newValues = setIn(newValues, `addressStore[${currentAddressId}]`, {
            ...addressStore[currentAddressId],
            count: addressStore[currentAddressId].count - 1
          });
        }
        // ! increment counter, for adding new address reference
        newValues = setIn(newValues, `addressStore[${selectedId}]`, { ...addressStore[selectedId], count: addressStore[selectedId].count + 1 });
        newValues = setIn(newValues, this.state.addressFieldName, selectedId);
      }
    }
    // * setIn, if called, will return a shallow copy of values
    if (newValues !== values) {
      setValues(newValues);
    }
    if (!_get(touched, this.state.addressFieldName)) {
      setFieldTouched(this.state.addressFieldName as any, true);
    }
    this.handleOnCloseDialog();
  };

  private handleOnCloseDialog = () => {
    this.setState({ openAddressDialog: false, dialogTitle: '', addressFieldName: '', fixedAddressType: undefined });
  };

  private renderJurisdictionsSection = (formikProps: FormikProps<LandRegistryFormikModel>) => {
    const { classes } = this.props;

    return (
      <>
        <Typography variant="h2" className={classes.subTitle}>
          Jurisdiction
        </Typography>
        <FlexLayout className={classes.lrsJurisdictionContainer}>
          <div className={classes.lrsJurisdictionFormInfo}>
            <Typography className={classes.lrsJurisdictionFormTitle}>States which your company is licensed to operate in</Typography>
          </div>
          <div className={classes.lrsJurisdictionFormField}>
            <Field
              name="lrsJurisdictions"
              component={CheckboxGroupField}
              options={this.jurisdictionsStateOptions}
              format="string"
              onChange={this.onJurisdictionChange}
            />
          </div>
        </FlexLayout>
      </>
    );
  };

  private renderCorrespondences = (formikProps: FormikProps<LandRegistryFormikModel>) => {
    const { classes } = this.props;
    const {
      values: { allStates, addressStore }
    } = formikProps;

    return allStates.map((item, idx) => {
      if (!item.show) {
        return null;
      }

      const { id: state } = item;
      const fieldPrefix = `allStates[${idx}]`;
      const fixedAddressType = state === StateEnum.QLD ? AddressTypeEnum.PhysicalAddress : undefined;
      return (
        <div key={state} className={classes.marginBottom}>
          <Typography variant="h2" className={classes.subTitle}>
            {state} Details
          </Typography>
          <div className={classes.lrsContentContainer}>
            <FormGroup title="Lodgement correspondence" description={`for ${state} land registry`} className={classes.alignStart}>
              {this.renderPersonPicker(formikProps, `${fieldPrefix}.lodgementCorrespondence.contactPerson`)}
              <Typography className={classNames(classes.bold, classes.fullWidth)}>Address</Typography>
              <SingleAddressField
                fieldName={`${fieldPrefix}.lodgementCorrespondence.addressId`}
                dialogTitle={`${state} lodgement correspondence`}
                formikProps={formikProps}
                addressStore={addressStore}
                onAddClick={this.handleOnAddAddressClick}
                onEditClick={this.handleOnEditAddressClick}
                fixedAddressType={fixedAddressType}
              />
            </FormGroup>
            <FormGroup title="Financial correspondence" description={`for ${state} land registry`} className={classes.alignStart}>
              {this.renderPersonPicker(formikProps, `${fieldPrefix}.financialCorrespondence.contactPerson`)}
              <Typography className={classNames(classes.bold, classes.fullWidth)}>Address</Typography>
              <SingleAddressField
                fieldName={`${fieldPrefix}.financialCorrespondence.addressId`}
                dialogTitle={`${state} financial correspondence`}
                formikProps={formikProps}
                addressStore={addressStore}
                onAddClick={this.handleOnAddAddressClick}
                onEditClick={this.handleOnEditAddressClick}
                fixedAddressType={fixedAddressType}
              />
            </FormGroup>
            <FormGroup title="State revenue" description="" className={classNames(classes.alignCenter, classes.fieldBottom)}>
              <Field
                name={`${fieldPrefix}.stateRevenueCustomerId`}
                component={InputField}
                label="Customer ID (optional)"
                className={classNames(classes.halfWidth)}
                classes={{ marginBottom: classes.noMargin }}
              />
            </FormGroup>
          </div>
        </div>
      );
    });
  };

  private handleOnAddAddressClick = (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => {
    this.setState({
      //
      openAddressDialog: true,
      addressFieldName: fieldName,
      dialogTitle,
      fixedAddressType,
      addressKey: this.state.addressKey + 1 // * We force the address form dialog to re-render in order to reset underlining formik
    });
  };

  private handleOnEditAddressClick = (fieldName: string, addressId: string, dialogTitle: string, fixedAddressType?: AddressTypeEnum) => {
    this.setState({
      openAddressDialog: true,
      addressFieldName: fieldName,
      dialogTitle,
      fixedAddressType,
      addressKey: this.state.addressKey + 1 // * We force the address form dialog to re-render in order to reset underlining formik
    });
  };

  private renderPersonPicker(formikProps: FormikProps<LandRegistryFormikModel>, fieldName: string) {
    const openDetail = _get(formikProps.values, `${fieldName}.existingOrNew`) === ADD_NEW;
    const pathsForSectionToGrabValidNamesFrom = this.pathsForPersonSection.filter(path => path !== fieldName);
    const notUsedPersonOptions = this.getAllAvailableNamesOptions([], formikProps, pathsForSectionToGrabValidNamesFrom);
    // * ADD_NEW is a trick to not remove this person from the options
    const options = this.addCurrentlySelectedOption(notUsedPersonOptions, ADD_NEW, formikProps.values);
    return (
      <PersonPicker
        fieldName={fieldName}
        options={options}
        openDetail={openDetail}
        allowSelection={true}
        onSelectChange={this.handleOnPersonChange}
        formikProps={formikProps}
      />
    );
  }

  private handleOnPersonChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string, form: FormikProps<any>, fieldName: string) => {
    const pathsForSectionToGrabValidNamesFrom = this.pathsForPersonSection.filter(path => path !== fieldName);
    this.onChangeSelectPerson(resolvedValue, form, fieldName, pathsForSectionToGrabValidNamesFrom);
  };

  private handleOnPreSubmit = (values: LandRegistryFormikModel, formikProps: FormikProps<LandRegistryFormikModel>) => {
    values.states = values.allStates.filter(d => d.show);
    return this.touchedGlobalState ? { valuesFromCurrentStep: values, allValues: this.allValues } : { valuesFromCurrentStep: values };
  };

  private handleOnBackClick = () => {
    this.props.onStepChange(-1);
  };
}
export default withStyles(styles)(LandRegistry);
