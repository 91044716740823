import * as React from 'react';

import { Action, Dispatch } from 'redux';

import WorkflowStepper from '@sympli/ui-framework/components/workflow-stepper';
import Logger from '@sympli/ui-logger';
import { FormikHelpers, FormikProps } from 'formik';
import _cloneDeep from 'lodash-es/cloneDeep';
import _get from 'lodash-es/get';
import _uniqueId from 'lodash-es/uniqueId';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { pushGlobalError } from 'src/actions/globalErrors';
import ApplicationError from 'src/components/application-error';
import ApplicationNotificationStack from 'src/components/application-notification-stack';
import ApplicationWarning from 'src/components/application-warning';
import { Feature } from 'src/components/feature-toggle/models';
import { Body, Box } from 'src/components/layout';
import { OnboardingFullModel } from 'src/containers/onboarding/models';
import { ApplicationErrorModel, ApplicationStatusEnum, ShiftEnum } from 'src/models';
import { ErrorType } from 'src/reducers/globalErrors';
import Api from 'src/utils/http';

import { INITIAL_FORM_STEPS, OnboardingDetailsModel, OnboardingFormStepsEnum, OnboardingPageStepsModelType } from './models';
import DirectDebitAccount from './steps/direct-debit-account';
import LandRegistry from './steps/land-registry';
import OrganisationDetails from './steps/organisation-details';
import ReviewRegistration from './steps/review-registration';
import { fillRefValue } from './steps/review-registration/helpers';
import { ReviewModel } from './steps/review-registration/models';
import Signers from './steps/signers';
import UploadDocuments from './steps/upload-documents/UploadDocuments';
import styles, { ClassKeys } from './styles';

interface OnboardingDetailPageProps {
  dispatch: Dispatch<Action>;
  detailValues: OnboardingDetailsModel;
  applicationErrors?: Array<ApplicationErrorModel>;
  initialStep: OnboardingFormStepsEnum;
  onSubmitDetails: (values: {
    details?: OnboardingDetailsModel;
    applicationError?: Array<ApplicationErrorModel>;
    applicationStatus: ApplicationStatusEnum;
  }) => void;
  features: Feature;
}

type Props = OnboardingDetailPageProps & WithStyles<ClassKeys>;

interface State {
  step: number;
}

class OnboardingDetailPage extends React.PureComponent<Props, State> {
  private values: OnboardingDetailsModel;
  public _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.values = props.detailValues;

    this.state = {
      step: this.props.initialStep
    };
  }

  private getInitialValues = (step?: number): OnboardingPageStepsModelType => {
    let initialValues;

    switch (step) {
      case OnboardingFormStepsEnum.OrganisationDetails:
        initialValues = { ...this.values.organisationDetails, addressStore: this.values.addressStore };
        break;
      case OnboardingFormStepsEnum.LandRegistry:
        initialValues = { ...this.values.landRegistry, addressStore: this.values.addressStore, ...this.values.feature };
        break;
      case OnboardingFormStepsEnum.DirectDebitAccount:
        initialValues = this.values.directDebitAccount;
        break;
      // ! hide trust Account for V1 WEB-2984
      // case OnboardingFormStepsEnum.TrustAccount:
      //   initialValues = this.values.trustAccount;
      //   break;
      case OnboardingFormStepsEnum.Signers:
        initialValues = this.values.signers;
        break;
      default:
        initialValues = this.values;
    }
    // ! we use this function because we always want a COPY OF DATA to manipulate in the form
    return _cloneDeep(initialValues);
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    // Scroll window to the top
    if (this.state.step !== prevState.step) {
      window.scrollTo(0, 0);
    }
  }

  public componentDidMount() {
    this._isMounted = true;
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderApplicationNotificationStack()}
        <Body mainBody={this.renderStep()} panelBody={this.renderPanel()} data-test={`detail${this.state.step}`} />
      </React.Fragment>
    );
  }

  private renderApplicationNotificationStack = () => {
    const errors = this.renderApplicationError();
    const warnings = this.renderApplicationWarning();

    return <ApplicationNotificationStack errors={errors} warnings={warnings} />;
  };

  private renderApplicationError = () => {
    const { applicationErrors } = this.props;
    if (applicationErrors != null) {
      return <ApplicationError title="Please review the following:" open={!!applicationErrors.length} messages={applicationErrors} />;
    }
    return null;
  };

  private renderApplicationWarning = () => {
    const title = "Please note that the land registries do not accept 'trustee for' in their account name.";
    const subTitle = 'Sympli Support will be in contact to confirm your preferred account name.';
    const { organisationDetails } = this.values;
    const { step } = this.state;
    const open =
      step === OnboardingFormStepsEnum.LandRegistry &&
      (organisationDetails.company?.organisationName?.toLowerCase().includes('trustee for') ||
        organisationDetails.tradingName?.toLowerCase().includes('trustee for'));
    return (
      <ApplicationWarning open={open}>
        <Typography>
          <b>{title} </b>
          {subTitle}
        </Typography>
      </ApplicationWarning>
    );
  };

  private renderStep() {
    const { features } = this.props;
    const props = {
      getInitialValues: this.getInitialValues,
      onStepChange: this.handleOnStepChange
    };

    switch (this.state.step) {
      case OnboardingFormStepsEnum.OrganisationDetails:
        return <OrganisationDetails step={OnboardingFormStepsEnum.OrganisationDetails} {...props} onSave={this.handleOnUpdate} onAbnLookUp={Api.abnLookUp} />;
      case OnboardingFormStepsEnum.LandRegistry:
        return <LandRegistry step={OnboardingFormStepsEnum.LandRegistry} {...props} features={features} onSave={this.handleOnUpdate} />;
      case OnboardingFormStepsEnum.DirectDebitAccount:
        return (
          <DirectDebitAccount step={OnboardingFormStepsEnum.DirectDebitAccount} {...props} onSave={this.handleOnUpdate} onBsbLookUp={this.handleBsbLookUp} />
        );
      // ! hide trust Account for V1 WEB-2984
      // case OnboardingFormStepsEnum.TrustAccount:
      //   return <TrustAccount step={OnboardingFormStepsEnum.TrustAccount} {...props} onSave={this.handleOnUpdate} onBsbLookUp={this.handleBsbLookUp} />;
      case OnboardingFormStepsEnum.Signers:
        return <Signers step={OnboardingFormStepsEnum.Signers} {...props} onSave={this.handleOnUpdate} />;
      case OnboardingFormStepsEnum.UploadDocuments:
        return (
          <UploadDocuments
            step={OnboardingFormStepsEnum.UploadDocuments}
            {...props}
            getDocuments={this.getDocumentsList}
            onUploadFile={this.handleOnUploadFile}
            onDeleteFile={this.handleOnDeleteFile}
          />
        );
      case OnboardingFormStepsEnum.ReviewRegistration:
        return (
          <ReviewRegistration
            step={OnboardingFormStepsEnum.ReviewRegistration}
            {...props}
            values={this.values}
            onEditClick={this.handleOnNumberStepperClick}
            onSubmit={this.handleOnSubmitDetails}
          />
        );
      default:
        return <div>Please refresh the page.</div>;
    }
  }

  private renderPanel() {
    const { classes } = this.props;
    const { step } = this.state;

    return (
      <React.Fragment>
        <Box className={classes.panelSubTitle}>Join Sympli</Box>
        <WorkflowStepper
          classes={{
            //
            stepButtonBold: classes.stepButtonBold,
            stepButtonEnable: classes.stepButtonEnable,
            stepIcon: classes.stepIcon
          }}
          className={classes.formStepper}
          orientation="vertical"
          steps={INITIAL_FORM_STEPS}
          selectedStepValue={step}
          onStepClick={this.handleOnNumberStepperClick}
        />
      </React.Fragment>
    );
  }

  private handleOnUploadFile = (data: FormData, onUploadProgress: (progressEvent: any) => void) => {
    return Api.uploadFile(data, onUploadProgress);
  };

  private handleOnDeleteFile = (fileName, fileGroup) => {
    const groupName = fileGroup.groupName;
    const subGroupName = fileGroup.subGroupName;
    return Api.deleteDocument(fileName, groupName, subGroupName);
  };

  private getDocumentsList = () => {
    return Api.getDocumentsList();
  };

  private handleBsbLookUp = (bsbNumber: string) => {
    return Api.bsbLookUp(bsbNumber);
  };

  private handleOnUpdate = (data: { valuesFromCurrentStep: any; allValues?: OnboardingDetailsModel }, formikActions: FormikHelpers<any>) => {
    // Update values locally
    this.values = data.allValues || this.values; // Update possible person reference if allValues has been changed
    this.saveStepValues(data.valuesFromCurrentStep);
    // Update values to the server
    return Api.updateDetails(this.values) //
      .then(() => {
        formikActions.setSubmitting(false);
        this.handleOnStepChange(+1);
      })
      .catch(err => {
        Logger.captureException(err);
        this.onError(err, 'Fail to save data to the server');
        formikActions.setSubmitting(false);
      });
  };

  private handleOnSubmitDetails = (values: OnboardingDetailsModel, formikProps: FormikProps<ReviewModel>) => {
    const submitValues = fillRefValue(values);
    // ! update the ref filled value first
    // ! and then hit submit endpoint
    return Api.updateDetails(submitValues)
      .then(() =>
        Api.submitDetails()
          .then((data: OnboardingFullModel) => {
            const onboarding: OnboardingFullModel = data;
            this.props.onSubmitDetails(onboarding);
            formikProps.setSubmitting(false);
          })
          .catch(err => {
            Logger.captureException(err);
            this.onError(err, 'Fail to submit application');
            formikProps.setSubmitting(false);
          })
      )
      .catch(err => {
        Logger.captureException(err);
        this.onError(err, 'Fail to save data to the server');
        formikProps.setSubmitting(false);
      });
  };

  private onError = (err: any, title: string) => {
    const errorData = _get(err, 'response.data', '');
    // General network error or something
    const globalError = {
      id: _uniqueId('error'),
      title: title,
      message: err.message + '  ' + JSON.stringify(errorData),
      type: 'warning' as ErrorType
    };
    this.props.dispatch(pushGlobalError(globalError));
  };

  private handleOnNumberStepperClick = (e: React.MouseEvent<HTMLButtonElement>, stepValue: number) => {
    if (this._isMounted) {
      if (window.confirm('You will lose the unsaved data. Do you want to continue?')) {
        this.setState({ step: stepValue });
      }
    }
  };

  private handleOnStepChange = shift => {
    if (this._isMounted) {
      if (shift === ShiftEnum.Next) {
        this.setState(prevState => ({ step: prevState.step + shift }));
      } else {
        if (window.confirm('You will lose the unsaved data. Do you want to continue?')) {
          this.setState(prevState => ({ step: prevState.step + shift }));
        }
      }
    }
  };

  private saveStepValues = (values: any, step?: OnboardingFormStepsEnum) => {
    const currentStep = step === undefined ? this.state.step : step;
    this.values.step = this.state.step;
    switch (currentStep) {
      case OnboardingFormStepsEnum.OrganisationDetails: {
        const { addressStore, ...organisationDetails } = values;
        this.values.organisationDetails = organisationDetails;
        this.values.addressStore = addressStore;
        break;
      }
      case OnboardingFormStepsEnum.LandRegistry: {
        const { addressStore, lrsJurisdictions, ...landRegistry } = values;
        this.values.landRegistry = landRegistry;
        this.values.addressStore = addressStore;
        this.values.organisationDetails.jurisdictions = lrsJurisdictions;
        break;
      }
      case OnboardingFormStepsEnum.DirectDebitAccount:
        this.values.directDebitAccount = values;
        break;
      case OnboardingFormStepsEnum.TrustAccount:
        this.values.trustAccount = values;
        break;
      case OnboardingFormStepsEnum.Signers:
        this.values.signers = values;
        break;
      case OnboardingFormStepsEnum.ReviewRegistration:
        this.values.review = values;
        break;
      default:
        break;
    }
  };
}

export default withStyles(styles)(OnboardingDetailPage);
