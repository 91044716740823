import * as React from 'react';

import { ICertInfo } from '@sympli/digital-signing/interfaces';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';
import { LookupNodeModel } from '@sympli/ui-framework/models';
import dateFormat from 'dateformat';
import { Field } from 'formik';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { Box } from 'src/components/layout';
import { modelKey } from 'src/utils/formUtils';

import { CertificateListFormModel } from '../../models';
import styles, { ClassKeys } from './styles';

interface CertificateListFormBodyProps {
  certificates: ICertInfo[];
}

type Props = CertificateListFormBodyProps & WithStyles<ClassKeys>;

const fieldName = modelKey<CertificateListFormModel>();

class CertificateListFormBody extends React.PureComponent<Props> {
  render() {
    const { classes, certificates } = this.props;

    const options: LookupNodeModel[] = certificates.map(certificate => {
      const notAfter: string = certificate.notAfter ? `Expires on ${dateFormat(certificate.notAfter, `ddd d mmm yyyy, a't' h:MM TT`)}` : '';

      return {
        id: certificate.id.certSerialNumber,
        name: (
          <div className={classes.certificateBox}>
            <Box className={classes.certificateDetails}>
              <p className={classes.paragraph}>{certificate.issuer.name}</p>
              <p className={classes.paragraph}>{certificate.tokenType} token</p>
              <p className={classes.paragraph}>{notAfter}</p>
            </Box>
          </div>
        )
      };
    });

    return (
      <div>
        <Field
          component={RadioField}
          vertical={true}
          name={fieldName('selectedCertificateSerialNumber')}
          options={options}
          format="string"
          classes={{
            radio: classes.radio,
            // formGroup: classes.formGroupOverride,
            formControl: classes.formControlOverride,
            //formControlLabelVerical: classes.verticalLabelRadioGroup,
            formControlLabelRoot: classes.formControlLabelRoot
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CertificateListFormBody);
