import * as React from 'react';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import SvgCongrats from 'src/components/svg/congrats';
import SvgHouse from 'src/components/svg/house';
import SvgOkGesture from 'src/components/svg/okGesture';
import SvgReview from 'src/components/svg/review';

import styles, { ClassKeys } from './styles';

interface ProgressPanelProps {
  svg: 'congrats' | 'ok' | 'review';
  title: string;
  description: string;
}

type Props = ProgressPanelProps & WithStyles<ClassKeys>;

const ProgressPanel = (props: Props) => {
  const { svg, classes, title, description } = props;
  return (
    <FlexLayout flexDirection="column" alignItems="center" className={classes.root}>
      {resolveSvg(svg)}
      <div>
        <strong>{title}</strong>
      </div>
      <div>{description}</div>
    </FlexLayout>
  );
};

function resolveSvg(svg: 'congrats' | 'ok' | 'review') {
  switch (svg) {
    case 'congrats': {
      return <SvgCongrats />;
    }
    case 'ok': {
      return <SvgOkGesture />;
    }
    case 'review': {
      return <SvgReview />;
    }
    default: {
      return <SvgHouse />;
    }
  }
}

export default withStyles(styles)(ProgressPanel);
