import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const GAP_PANEL_LEFT_48 = 48;
const WIDTH_MAIN_CONTENT_800 = 800;
const WIDTH_PANEL_CONTENT_272 = 272;
export const WIDTH_MIDDLE_CONTAINER_1120 = WIDTH_MAIN_CONTENT_800 + GAP_PANEL_LEFT_48 + WIDTH_PANEL_CONTENT_272;
const PADDING_SIDE_40 = 40;

const styles = (theme: Theme) =>
  createStyles({
    noScroll: {
      overflowY: 'hidden'
    },
    subTitle: {
      paddingBottom: 16,
      borderBottom: '1px solid #D0D0D0'
    },
    body: {
      height: '100%',
      boxSizing: 'border-box'
    },
    contentOverrides: {
      minHeight: '515px'
    },
    bodyOverrides: {
      paddingTop: 0,
      maxWidth: '680px',
      paddingRight: '80px'
    },
    panelOverrides: {
      paddingTop: 2,
      position: 'sticky',
      left: 0
    },
    summary: {
      paddingLeft: 40,
      [`@media (min-width:${PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40}px)`]: {
        marginLeft: `calc(50vw - ${(PADDING_SIDE_40 + WIDTH_MIDDLE_CONTAINER_1120 + PADDING_SIDE_40) / 2}px)`
      },
      paddingBottom: 40
    },
    summaryTitle: {
      marginTop: '10%',
      fontSize: 32
    },
    summarySubTitle: {
      fontWeight: 'normal',
      fontSize: 17,
      paddingBottom: 13
    },
    panelSubTitle: {
      fontWeight: 'bold',
      fontSize: 18,
      paddingBottom: 16,
      borderBottom: '1px solid #D0D0D0',
      maxHeight: 16,
      textAlign: 'center',
      width: '320x',
      alignContent: 'center',
      paddingTop: '20px'
    },
    panelBody: {
      paddingTop: 30,
      paddingBottom: 30,
      color: '#333333;',
      fontSize: 16,
      paddingLeft: '15px'
    },
    panelBackground: {
      backgroundColor: '#FAFAFA',
      height: '259px',
      width: '360px'
    },
    panelLeftRightPadding: {
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    panelButtonPadding: {
      paddingRight: '100px',
      paddingLeft: '100px'
    }
  });

export type ClassKeys = typeof styles;

export default styles;
