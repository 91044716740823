import { getComponentOverrides, palette, typography } from '@sympli/ui-framework/theme';

import { Components, createTheme } from '@mui/material/styles';

const theme = createTheme({ palette, typography });

const themeOverrides = getComponentOverrides(theme);

const projectOverrides: Components = {
  MuiIcon: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 'normal'
      }
    }
  }
};

export default Object.assign(theme, {
  components: {
    ...themeOverrides,
    ...projectOverrides
  }
});
