import { showVerification, showVerificationPopup, updateCertificateValidationInfo } from 'src/actions/application';
import { Action, createReducer } from 'typesafe-actions';

import { ICertInfo } from '@sympli/digital-signing/interfaces';

import { UserInfo } from 'src/containers/certificate-validation/models';

export interface ApplicationState {
  showVerification: boolean;
  showVerificationPopup: boolean;
  certificates: ICertInfo[];
  selectedCertificate: ICertInfo | null;
  userInfo: UserInfo;
  logGroupId: string;
}

const initialState: ApplicationState = {
  showVerification: false,
  showVerificationPopup: false,
  certificates: [],
  selectedCertificate: null,
  userInfo: {
    abn: '',
    email: '',
    givenName: '',
    middleName: '',
    surname: ''
  },
  logGroupId: Date.now().toString()
};

const reducer = createReducer<
  //
  ApplicationState,
  Action
>(initialState) //
  .handleAction(updateCertificateValidationInfo, (state, action): ApplicationState => {
    return {
      ...state,
      ...action.payload
    };
  })
  .handleAction(showVerification, (state, action): ApplicationState => {
    return {
      ...state,
      showVerification: action.payload
    };
  })
  .handleAction(showVerificationPopup, (state, action): ApplicationState => {
    return {
      ...state,
      showVerificationPopup: action.payload
    };
  });

export default reducer;
