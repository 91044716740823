import PhoneNumber from 'awesome-phonenumber';
import uuidv1 from 'uuid/v1';

export function getGuid() {
  return uuidv1();
}

export function camelCaseToSentenceCase(camelCase: string) {
  return (
    camelCase // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str: string) {
        return str.toUpperCase();
      })
  );
}

// matching any invalid letter(english, chinese, japaniese, french)
const regexHasInvalidLetter = /[^(0-9|+|\s)]/;

export const isAusMobileNumber = (value: string = '') => {
  if (!value) {
    return false;
  }
  // manually doing letter check to avoid weird behaviour from PhoneNumber()
  // PhoneNumber('0416727wte','AU') will be transfromed to a valid Mobile Number
  if (value.match(regexHasInvalidLetter)) {
    return false;
  }
  const nb = new PhoneNumber(value, 'AU');
  return nb.isMobile();
};
