import * as React from 'react';

import { ADD_NEW } from '@sympli/ui-framework/components/formik/address-field/values';
import { AddButton } from '@sympli/ui-framework/components/formik/buttons';
import { IconDelete } from '@sympli/ui-framework/icons';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import _get from 'lodash-es/get';

import IconButton from '@mui/material/IconButton';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { PersonModel } from 'src/models';

import PersonPicker from '../../signers/components/person-picker/PersonPicker';
import styles, { ClassKeys } from './styles';

interface PersonListProps {
  formikProps: FormikProps<any>;
  fieldName: string;
  defaultPersonItem: PersonModel;
  pathsForSectionToGrabValidNamesFrom?: Array<string>;
  addPersonLabel: string;

  getAvailableNamesOptions: (
    alreadyUsed: Array<PersonModel>,
    formikProps: FormikProps<any>,
    pathsForSectionToGrabValidNamesFrom: Array<string>
  ) => Array<LookupEnumModel | LookupItemModel>;
  addCurrentlySelectedOption: (availablePersonOptions: Array<LookupEnumModel | LookupItemModel>, existingOrNew: string, fieldValues: any) => void;
  onDelete: (
    fieldName: string,
    fieldIndex: number,
    formikProps: FormikProps<any>,
    pathsForSectionToGrabValidNamesFrom: Array<string>,
    existingOrNew: string
  ) => void;
  onChangeSelectPerson?: (
    resolvedValue: string,
    formikProps: FormikProps<any>,
    fieldReference: string,
    pathsForSectionToGrabValidNamesFrom: Array<string>
  ) => void;
}
type Props = PersonListProps & WithStyles<ClassKeys>;

class PersonList extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    pathsForSectionToGrabValidNamesFrom: []
  };
  render() {
    const {
      classes,
      formikProps,
      fieldName,
      defaultPersonItem,
      pathsForSectionToGrabValidNamesFrom,
      getAvailableNamesOptions,
      addCurrentlySelectedOption,
      onDelete,
      addPersonLabel,
      onChangeSelectPerson
    } = this.props;
    const { values: formikValues } = formikProps;
    const fieldValues: Array<PersonModel> = _get(formikValues, fieldName);
    const allowDelete = fieldValues.length > 1;
    let allowSelection; // whether to allow selecting existing person from the list
    let openDetail;
    let options;

    // pre-calculate which names were not used at all
    // this will also look into neighbourhood section and pick newly created (valid only) items
    // this will also exclude currently selected option
    const notUsedNameOptions = getAvailableNamesOptions(fieldValues, formikProps, pathsForSectionToGrabValidNamesFrom!);

    return (
      <>
        {fieldValues.map((item, idx) => {
          const fieldReference = fieldName + '[' + idx + ']';
          // we will open detail if user picked ADD_NEW or it's not allowed to do selection of an existing one
          openDetail = item.existingOrNew === ADD_NEW;
          // add currently selected option
          options = addCurrentlySelectedOption(notUsedNameOptions, item.existingOrNew, formikValues);
          allowSelection = !(openDetail && options.length === 1);

          return (
            <div key={fieldReference} className={classNames(classes.fullWidth, classes.bottomBorder, classes.lineItem)}>
              <div className={classes.spaceBetween}>
                {allowDelete ? (
                  <IconButton
                    color="primary"
                    className={classes.deleteButton}
                    data-
                    onClick={function onClick() {
                      onDelete(fieldName, idx, formikProps, pathsForSectionToGrabValidNamesFrom!, item.existingOrNew);
                    }}
                    size="large"
                  >
                    <IconDelete className={classes.deleteIcon} />
                  </IconButton>
                ) : null}
                <PersonPicker
                  fieldName={fieldReference}
                  options={options}
                  openDetail={openDetail}
                  allowSelection={allowSelection}
                  onSelectChange={function (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string, form: FormikProps<any>) {
                    // get fieldname fron event
                    onChangeSelectPerson && onChangeSelectPerson(resolvedValue, form, fieldReference, pathsForSectionToGrabValidNamesFrom!);
                  }}
                  formikProps={formikProps}
                />
              </div>
            </div>
          );
        })}
        <AddButton fieldName={fieldName} formikProps={formikProps} defaultValue={defaultPersonItem}>
          {addPersonLabel}
        </AddButton>
      </>
    );
  }

  private handleOnChangeSelectPerson = (fieldReference: string, pathsForSectionToGrabValidNamesFrom: Array<string>) => {
    if (this.props.onChangeSelectPerson) {
      return (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string, form: FormikProps<any>) => {
        this.props.onChangeSelectPerson!(resolvedValue, form, fieldReference, pathsForSectionToGrabValidNamesFrom);
      };
    }
    return (event: React.ChangeEvent<HTMLInputElement>, resolvedValues: Array<string>, form: FormikProps<any>) => {};
  };
}

export default withStyles(styles)(PersonList);
