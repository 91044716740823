import * as React from 'react';

export default (props: React.SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 150 150" width="130" height="130" {...props}>
    <g id="02-//-EXTERNAL-ONBOARDING-v6" stroke="none" fill="none">
      <g id="Onboarding-//-Progress-4---resubmitted-for-review" transform="translate(-415.000000, -283.000000)">
        <g id="illo-workspace-ready" transform="translate(415.000000, 283.000000)">
          <g id="Group" fill="#F2F2F2">
            <g id="SVGID_1_-link">
              <circle id="SVGID_1_" cx="65" cy="65" r="65" />
            </g>
            <circle id="Oval" cx="65" cy="65" r="65" />
            <g id="SVGID_3_-link">
              <circle id="SVGID_3_" cx="65" cy="65" r="65" />
            </g>
          </g>
          <g id="Group" transform="translate(33.000000, 18.000000)">
            <g id="Shape">
              <g transform="translate(40.180328, 0.925620)" fill="#D8B393">
                <polygon points="7.10163934 37.6727273 7.00819672 37.6727273 7.10163934 37.7652893" />
                <path d="M7.00819672,33.785124 L6.9147541,21.1041322 C8.22295082,16.0132231 6.9147541,9.99669421 6.9147541,9.99669421 L6.26065574,2.03636364 C6.16721311,0.647933884 4.76557377,-0.185123967 3.45737705,0.27768595 C-0.280327869,1.75867769 1.2147541,8.60826446 1.2147541,8.60826446 L1.49508197,13.2363636 C2.4295082,15.6429752 4.01803279,20.4561983 3.92459016,24.9917355 L7.00819672,33.785124 Z" />
              </g>
              <g transform="translate(32.704918, 0.000000)" fill="#E5BF9A">
                <path d="M18.2213115,44.2446281 C18.3147541,44.522314 18.3147541,44.707438 18.3147541,44.707438 C18.3147541,44.522314 18.2213115,44.3371901 18.2213115,44.2446281 Z" />
                <path d="M17.1,39.8942149 L16.4459016,37.3024793 C16.7262295,38.1355372 16.9131148,39.061157 17.1,39.9867769 L17.1934426,40.0793388 L13.0819672,25.3619835 C12.9885246,18.7900826 9.43770492,11.7553719 9.43770492,11.7553719 L5.79344262,1.6661157 C5.41967213,0.555371901 4.11147541,3.49720253e-15 2.99016393,0.462809917 C-2.70983607,2.86942149 1.96229508,11.8479339 1.96229508,11.8479339 L3.55081967,16.568595 C5.41967213,18.9752066 9.25081967,24.6214876 10.652459,30.4528926 L17.1,39.8942149 Z" />
                <path d="M17.3803279,40.7272727 L17.4737705,40.9123967 L17.2868852,40.1719008 L17.1934426,40.0793388 C17.1934426,40.1719008 17.2868852,40.4495868 17.3803279,40.7272727 Z" />
                <path d="M18.0344262,43.6892562 C18.1278689,43.8743802 18.1278689,44.0595041 18.2213115,44.2446281 C18.1278689,44.0595041 18.1278689,43.8743802 18.0344262,43.6892562 C18.0344262,43.6892562 18.0344262,43.6892562 18.0344262,43.6892562 Z" />
                <path d="M18.3147541,44.707438 C18.3147541,44.707438 18.3147541,44.522314 18.2213115,44.2446281 C18.2213115,44.0595041 18.1278689,43.8743802 18.0344262,43.6892562 L18.3147541,44.707438 Z" />
                <path d="M17.2868852,40.5421488 L18.0344262,43.5966942 C18.0344262,43.5966942 18.0344262,43.5966942 18.0344262,43.5966942 C17.847541,42.8561983 17.5672131,41.7454545 17.2868852,40.5421488 Z" />
                <path d="M16.4459016,37.3024793 L17.1,39.8942149 L17.1934426,39.9867769 C16.9131148,39.061157 16.7262295,38.1355372 16.4459016,37.3024793 Z" />
                <path d="M17.1934426,39.9867769 L17.1,39.8942149 L17.2868852,40.6347107 L17.3803279,40.7272727 C17.2868852,40.4495868 17.1934426,40.1719008 17.1934426,39.9867769 Z" />
              </g>
              <path
                d="M49.9918033,40.5421488 L49.804918,39.8016529 L43.4508197,30.3603306 C41.9557377,24.5289256 38.1245902,18.8826446 36.3491803,16.4760331 C35.695082,15.6429752 35.3213115,15.1801653 35.3213115,15.1801653 L27.8459016,3.79504132 C27.2852459,2.86942149 26.0704918,2.59173554 25.1360656,3.14710744 C17.847541,7.40495868 26.4442623,17.4016529 26.4442623,17.4016529 L31.5836066,25.6396694 L32.9852459,29.2495868 C36.7229508,31.3785124 42.142623,34.9884298 49.5245902,41.3752066 C49.5245902,41.3752066 50.0852459,41.8380165 50.6459016,43.6892562 C50.6459016,43.6892562 50.6459016,43.6892562 50.6459016,43.6892562 L49.9918033,40.5421488 Z"
                fill="#E8C39A"
              />
              <g transform="translate(24.295082, 38.876033)">
                <g transform="translate(23.360656, 0.000000)" fill="#FBD5B5">
                  <polygon points="0.093442623 0.833057851 0.093442623 0.833057851 0.093442623 0.925619835" />
                </g>
                <path
                  d="M0.747540984,40.8198347 C0.747540984,40.8198347 1.12131148,42.4859504 1.86885246,45.3553719 C2.4295082,47.4842975 25.3229508,46.4661157 25.3229508,46.4661157 C25.3229508,46.4661157 25.5098361,44.0595041 25.3229508,40.8198347 C25.1360656,37.5801653 4.95245902,38.5983471 4.95245902,38.5983471 L0.747540984,40.8198347 Z"
                  fill="#EFCA9B"
                />
              </g>
              <path
                d="M49.804918,93.8578512 L26.3508197,93.8578512 C24.295082,93.8578512 22.6131148,92.1917355 22.6131148,90.1553719 L22.6131148,87.1008264 C22.6131148,85.0644628 24.295082,83.3983471 26.3508197,83.3983471 L49.804918,83.3983471 C51.8606557,83.3983471 53.542623,85.0644628 53.542623,87.1008264 L53.542623,90.1553719 C53.4491803,92.1917355 51.8606557,93.8578512 49.804918,93.8578512 Z"
                fill="#FFFFFF"
              />
              <path
                d="M51.0196721,44.707438 L50.7393443,43.6892562 C50.7393443,43.6892562 50.7393443,43.6892562 50.7393443,43.6892562 C50.1786885,41.9305785 49.6180328,41.3752066 49.6180328,41.3752066 C42.2360656,34.9884298 36.8163934,31.3785124 33.0786885,29.2495868 C29.9016393,27.4909091 27.8459016,26.8429752 26.7245902,26.5652893 C26.1639344,26.4727273 25.6967213,26.2876033 25.1360656,26.1024793 C24.0147541,25.6396694 20.9311475,25.3619835 13.7360656,29.3421488 C12.3344262,30.0826446 11.2131148,30.8231405 10.3721311,31.5636364 C10.3721311,31.5636364 5.79344262,34.6181818 6.07377049,35.3586777 C6.35409836,36.0066116 8.59672131,37.6727273 8.59672131,37.6727273 C9.3442623,38.4132231 10.3721311,38.7834711 11.2131148,38.8760331 C11.7737705,38.968595 12.3344262,38.7834711 12.895082,38.5983471 C13.2688525,38.4132231 13.642623,38.2280992 14.0163934,38.0429752 C16.5393443,36.8396694 22.5196721,34.3404959 26.0704918,34.7107438 C30.742623,35.1735537 38.4983607,51.0016529 26.1639344,54.8892562 C21.6786885,56.277686 18.2213115,54.9818182 15.6983607,52.7603306 L15.0442623,52.2049587 C12.6147541,49.7983471 11.0262295,46.6512397 10.0918033,44.4297521 C9.81147541,43.4115702 9.53114754,42.5785124 9.15737705,41.8380165 C9.15737705,41.8380165 9.15737705,41.8380165 9.15737705,41.8380165 C7.00819672,36.8396694 3.92459016,36.5619835 2.24262295,36.8396694 C1.40163934,37.0247934 0.840983607,37.7652893 0.93442623,38.5983471 L1.96229508,51.0942149 C2.0557377,52.4826446 2.52295082,53.8710744 3.36393443,55.0743802 L13.2688525,69.6991736 C13.9229508,70.6247934 14.7639344,71.4578512 15.6983607,72.1057851 L25.042623,79.6958678 L49.5245902,79.6958678 C49.5245902,79.6958678 48.4967213,76.7338843 50.7393443,60.7206612 C51.8606557,52.2975207 51.5803279,47.4842975 51.0196721,44.707438 Z M19.6229508,57.2033058 L19.9032787,57.2033058 C19.8098361,57.2958678 19.7163934,57.2958678 19.6229508,57.2033058 Z"
                fill="#EFCA9B"
              />
            </g>
            <path
              d="M52.6081967,88.3966942 L23.8278689,90.2479339 C21.2114754,90.2479339 19.0622951,92.5619835 19.0622951,95.338843 L19.0622951,110.61157 C23.4540984,111.53719 27.9393443,112 32.6114754,112 C41.2081967,112 49.4311475,110.333884 56.9065574,107.371901 L56.9065574,93.0247934 C57,90.4330579 55.0377049,88.3966942 52.6081967,88.3966942 Z"
              id="Shape"
              fill="#53BBB3"
            />
            <ellipse id="Oval" fill="#FFFFFF" cx="25.2295082" cy="96.4495868" rx="1.96229508" ry="1.94380165" />
            <ellipse id="Oval" fill="#FFFFFF" cx="25.2295082" cy="101.818182" rx="1.96229508" ry="1.94380165" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
