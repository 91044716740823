import * as React from 'react';

import Typography from '@mui/material/Typography';

import Header from 'src/components/layout/header';
import { ApplicationStatusEnum, ReviewProgressModel, VOITypeEnum } from 'src/models';

import ProgressPanel from '../progress-panel/ProgressPanel';

interface Props {
  applicationStatus: ApplicationStatusEnum;
  contactEmail: string;
  isApplicationHasError: boolean;
  isVOIRequired: boolean;
  voiType: VOITypeEnum;
  reviewProgress?: ReviewProgressModel;
}

export default (props: Props) => {
  const { applicationStatus, contactEmail, isApplicationHasError, isVOIRequired } = props;
  switch (applicationStatus) {
    case ApplicationStatusEnum.Received: {
      return (
        <React.Fragment>
          <Header>Thanks for registering with Sympli</Header>
          <Typography style={{ fontSize: 18, marginBottom: 16 }}>
            You’ll be notified of your next steps via email and on this page.
            <br />
            An email will be sent to {contactEmail}.
          </Typography>
          <ProgressPanel svg="ok" title="All done for now" description="Your updates have been received and will be reviewed soon" />
        </React.Fragment>
      );
    }
    case ApplicationStatusEnum.Reviewing: {
      if (isApplicationHasError) {
        return (
          <React.Fragment>
            <Header>We've found issues with your application</Header>
            <Typography style={{ fontSize: 18, marginBottom: 16 }}>
              To assist us with the review of your application, please resend the following as soon as possible
              <br />
              Need help? <a href="mailto: ">Contact us</a>
            </Typography>
          </React.Fragment>
        );
      } else if (isVOIRequired) {
        return (
          <React.Fragment>
            <Header>Your next steps</Header>
            <Typography style={{ fontSize: 18, marginBottom: 16 }}>
              All signers of the Sympli Subscriber Agreement and certificate managers are required to complete a face-to-face verification of identity (VOI).
            </Typography>
          </React.Fragment>
        );
      } else {
        // const description = reviewProgress ? `${reviewProgress.progressPercentage} complete` : '';
        return (
          <React.Fragment>
            <Header>We are reviewing your application</Header>
            <Typography style={{ fontSize: 18, marginBottom: 16 }}>
              You will be notified of your next steps via email and on this page.
              <br />
              Once approved, an email will be sent to {contactEmail}.
            </Typography>
            <ProgressPanel svg="review" title="Application under review" description="" />
          </React.Fragment>
        );
      }
    }
    case ApplicationStatusEnum.Approved: {
      return (
        <React.Fragment>
          <Header>Your application has been approved.</Header>
          <ProgressPanel
            svg="congrats"
            title="Great news! You are now with Sympli. Please check your email for next steps. "
            description="A welcome pack is on its way to your main office address."
          />
        </React.Fragment>
      );
    }
    case ApplicationStatusEnum.Rejected: {
      // * Temp Rejection header for MVP
      // TODO wait for real rejection process
      return (
        <React.Fragment>
          <Header>Sorry. Your application has been rejected.</Header>
          <Typography style={{ fontSize: 18, marginBottom: 16 }}>
            Please <a href={`https://${window.location.hostname}`}>click here</a> to start a new application.
          </Typography>
        </React.Fragment>
      );
    }
    default:
      return null;
  }
};
