import { axiosClient as axios, AxiosError, AxiosRequestConfig, AxiosResponse, HEADER_APPLICATION_ENVIRONMENT, HEADER_CORRELATION_ID } from '@sympli/http-agent';
import Logger, { PageActionEnum, resolveUrls, SeverityEnum } from '@sympli/ui-logger';

import { showVerification } from 'src/actions/application';
import { globalDispatch } from 'src/store';
import runtimeEnvResolver from 'src/utils/runtimeEnvResolver';

// constants
const TARGET_API = import.meta.env.VITE_TARGET_API;
const { RUNTIME_ENV, TARGET_MACHINE } = runtimeEnvResolver();
export const FAILED_REQUEST_BUFFER: Array<AxiosRequestConfig> = [];

// prefix all requests with base URL
axios.defaults.baseURL = `${TARGET_MACHINE}${TARGET_API}`;

// WEB-21607 inject special header for localhosts
RUNTIME_ENV === 'localhost' && (axios.defaults.headers.common[HEADER_APPLICATION_ENVIRONMENT] = RUNTIME_ENV);

// intercept all requests and to log them
axios.interceptors.request.use(
  //
  config => {
    // WEB-18254 log minimal info about this request
    const httpMethod = config.method?.toUpperCase() as string;
    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;
    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxRequest, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      body: config.data
    });

    return config;
  }
);

// intercept all responses and to log them
axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    const { config, data, status } = response;
    // WEB-18254 log minimal info about this response
    const httpMethod = config.method?.toUpperCase() as string;
    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;
    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxResponse, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      httpResponseCode: status,
      body: data
    });

    // explicitly return data only
    return data;
  },
  (error: AxiosError) => {
    const { config, response } = error;
    // When we don't mock a server response, axios could not send config in its error
    if (!config) {
      Logger.console(SeverityEnum.Warning, 'You might have missed mocking http call', JSON.stringify(error));
      return Promise.reject(error); // explicitly return rejected promise
    }

    // We print error response when it's in our localhost env for easy debugging
    if (config.headers?.[HEADER_APPLICATION_ENVIRONMENT] === 'localhost') {
      Logger.console(SeverityEnum.Warning, '', JSON.stringify(response));
    }

    // WEB-18254 log minimal info about this response
    const httpMethod = config.method?.toUpperCase() as string;

    const correlationId = config.headers?.[HEADER_CORRELATION_ID] as string;

    const [requestUrl, groupedRequestUrl, requestPathUrl, groupedRequestPathUrl] = resolveUrls(`${config.baseURL}${config.url}`);
    Logger.capturePageAction(PageActionEnum.AjaxResponse, {
      correlationId,
      httpMethod,
      requestUrl,
      groupedRequestUrl,
      requestPathUrl,
      groupedRequestPathUrl,
      httpResponseCode: response?.status,
      body: response?.data as any // TODO fix this
    });

    if (error.response && error.response!.status === 401) {
      globalDispatch(showVerification(true));
    }

    throw error;
  }
);
