import * as React from 'react';

import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import NavLink, { IconPosition } from '@sympli/ui-framework/components/nav-link/NavLink';

import styles, { ClassKeys } from './styles';

export interface BoxProps {
  title?: string | JSX.Element | null;
  typographyVariant?: 'h2' | 'subtitle1';
  linkTo?: string;
  linkIconPosition?: IconPosition;
  disabled?: boolean; // allows the action to be disabled;
  action?: string;
  actionIcon?: string;
  onActionClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: object;
}

type Props = BoxProps & WithStyles<ClassKeys>;

class Box extends React.PureComponent<React.PropsWithChildren<Props>> {
  private globalCssName = 'tickle-box';

  static defaultProps: Partial<Props> = {
    linkIconPosition: 'left',
    typographyVariant: 'subtitle1',
    disabled: false,
    actionIcon: 'add',
    onActionClick: () => {}
  };

  render() {
    const { classes, className, style, children } = this.props;
    return (
      <div className={classNames(classes.root, this.globalCssName, className)} style={style}>
        {this.renderHeader()}
        {children}
      </div>
    );
  }

  private renderHeader() {
    const { classes, title, linkTo, action } = this.props;

    if (!(title || linkTo || action)) {
      return null;
    }

    return (
      <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.boxHeader}>
        {this.renderTitle()}
        {this.renderAction()}
      </FlexLayout>
    );
  }

  private renderTitle() {
    const { classes, title, typographyVariant, linkTo, linkIconPosition } = this.props;

    if (linkTo) {
      return (
        <NavLink
          to={linkTo}
          className={classNames(classes.linkRoot, linkIconPosition === 'left' && classes.linkMovedLeft)}
          classes={{
            text: classes.linkText,
            icon: classes.linkIcon
          }}
          iconPosition={linkIconPosition}
          iconColor="primary"
        >
          <Typography component="div" variant={typographyVariant}>
            {title}
          </Typography>
        </NavLink>
      );
    }
    return (
      <Typography component="div" variant={typographyVariant} className={classes.title}>
        {title}
      </Typography>
    );
  }

  private renderAction() {
    const { classes, action, actionIcon, disabled } = this.props;

    if (!action) {
      return null;
    }

    return (
      <ButtonLink
        disabled={disabled}
        icon={actionIcon as string}
        classes={{ root: classNames(classes.actionRoot, disabled && classes.actionRootDisabled), text: classes.actionLabel, icon: classes.actionIcon }}
        onClick={this.props.onActionClick}
      >
        {action}
      </ButtonLink>
    );
  }
}

export default withStyles(styles)(Box);
